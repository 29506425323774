import { Injectable } from '@angular/core';
import Content from '../data/content.json';
import {DatabaseService} from './database.service';
import {Resolve} from '@angular/router';
import {Scenario} from '../models/scenario.class';
import {MODEL} from '../../semaglutida/models/factory';
import {DEFAULT} from '../../semaglutida/database/database.const';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements Resolve<any> {

  scenario: Scenario;
  public app: Calculadora;
  public metaIndex: any;
  content: {
    intro: string,
    goals: Array<string>,
    description: string,
    source: string
  };

  constructor(private db: DatabaseService) {
    this.metaIndex = {
      meta_1: 1,
      meta_2: 2,
      meta_3: 5
    };
    this.content = Content;
  }

  resolve(): Promise<any> {
    return new Promise<any>(resolve => {
      if (this.app) {
        resolve();
      } else {
        this.db
          .getObjectByIndexValue('Scenario', MODEL.Scenario, 'name', DEFAULT.name)
          .then((sc: Scenario) => {
            this.scenario = sc;
            this.app = new Calculadora(sc);
            resolve();
          });
      }
    });
  }

  loadScenario(sc: Scenario) {
    this.scenario = sc;
    this.app = new Calculadora(sc);
  }

  getCopyCurrent(name: string): Scenario {
    const sc = new Scenario({
      data: {
        numero_pacientes: this.app.pacientes,
        metas: this.app.metas,
        costo_por_paciente_mes: this.app.costoPacientesMes,
      }, editable: true, name, id: null
    });

    return this.db.factoryInstance.create(MODEL.Scenario, JSON.parse(JSON.stringify(sc)));
  }
}

export class Calculadora {
  pacientes: number;
  public metas: Array<ITests>;
  costoPacientesMes: ITests;

  constructor(sc: Scenario) {
    this.pacientes = sc.data.numero_pacientes;
    this.costoPacientesMes = sc.data.costo_por_paciente_mes;
    this.metas = sc.data.metas;
  }

  costoPacientesAnual(): ITests {
    return {
      s05: this.costoPacientesMes.s05 * 12,
      s1: this.costoPacientesMes.s1 * 12,
      d15: this.costoPacientesMes.d15 * 12
    };
  }

  costoCohorteMes(): ITests {
    return {
      s05: this.costoPacientesMes.s05 * this.pacientes,
      s1: this.costoPacientesMes.s1 * this.pacientes,
      d15: this.costoPacientesMes.d15 * this.pacientes
    };
  }

  costoCohorteAnual(): ITests {
    return {
      s05: this.costoCohorteMes().s05 * 12,
      s1: this.costoCohorteMes().s1 * 12,
      d15: this.costoCohorteMes().d15 * 12
    };
  }

  getMetaResults(metaIndex: number): IMetaResults {
    const dap: ITests = {
      s05: this.costoCohorteAnual().s05 / (this.metas[metaIndex].s05 * this.pacientes / 100),
      s1: this.costoCohorteAnual().s1 / (this.metas[metaIndex].s1 * this.pacientes / 100),
      d15: this.costoCohorteAnual().d15 / (this.metas[metaIndex].d15 * this.pacientes / 100)
    };
    return {
      personasLograronMeta: {
        s05: (this.metas[metaIndex].s05 / 100) * this.pacientes,
        s1: (this.metas[metaIndex].s1 / 100) * this.pacientes,
        d15: (this.metas[metaIndex].d15 / 100) * this.pacientes
      },
      personasNoLograronMeta: {
        s05: (1 - this.metas[metaIndex].s05 / 100) * this.pacientes,
        s1: (1 - this.metas[metaIndex].s1 / 100) * this.pacientes,
        d15: (1 - this.metas[metaIndex].d15 / 100) * this.pacientes
      },
      costoTratamientoAnual: {
        pacientes: {
          s05: this.costoCohorteAnual().s05,
          s1: this.costoCohorteAnual().s1,
          d15: this.costoCohorteAnual().d15
        },
        diferencia: {
          s05: 0,
          s1: this.costoCohorteAnual().s1 - this.costoCohorteAnual().s05,
          d15: this.costoCohorteAnual().d15 - this.costoCohorteAnual().s05
        }
      },
      DisponibilidadAPagar: {
        dap: {
          s05: dap.s05,
          s1: dap.s1,
          d15: dap.d15,
        },
        diferenciaDap: {
          s05: 0,
          s1: dap.s1 - dap.s05,
          d15: dap.d15 - dap.s05
        },
        dapAdicionalCohorte: {
          s05: 0,
          s1: (dap.s1 - dap.s05) * this.pacientes,
          d15: (dap.d15 - dap.s05) * this.pacientes
        },
        costoRelativo: {
          s05: 1,
          s1: dap.s1 / dap.s05,
          d15: dap.d15 / dap.s05
        }
      }
    };
  }

}

export interface ITests {
  variable?: string;
  s05: number;
  s1: number;
  d15: number;
}

export interface IMetaResults {
  personasLograronMeta: ITests;
  personasNoLograronMeta: ITests;
  costoTratamientoAnual: {
    pacientes: ITests;
    diferencia: ITests;
  };
  DisponibilidadAPagar: {
    dap: ITests;
    diferenciaDap: ITests;
    dapAdicionalCohorte: ITests;
    costoRelativo: ITests;
  };
}
