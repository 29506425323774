import {Component, OnInit} from '@angular/core';
import {Label} from 'ng2-charts';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {DatosService} from '../../services/datos.service';
import {ICostos} from '../../models/scenario.class';
import {showWithComma} from '../../global/globalFunctions';

@Component({
  selector: 'app-tornado-cost',
  templateUrl: './tornado-cost.component.html',
  styleUrls: ['./tornado-cost.component.css']
})
export class TornadoCostComponent implements OnInit {

  chartTab: number;
  // divider: number;

  // ############# TORNADO ###############
  public barChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        title: item => item[0].label.replace(/,/g, ' '),
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += showWithComma(tooltipItem.xLabel);
          return label;
        }
      }
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        scaleLabel: {},
        ticks: {},
        stacked: true
      }], yAxes: [{
        stacked: true
      }]
    },

  };


  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [];
  public prevalencia: number;
  public slugName: string;

  // ################ DOUGHNUT ############
  // Doughnut
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        title: item => item[0].label.replace(/,/g, ' '),
        // @ts-ignore
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartDataSets): string | string[] {
          // @ts-ignore
          let labels = data.labels[tooltipItem.index] || '';
          // @ts-ignore
          const info = data.datasets[0].data[tooltipItem.index] || '';
          if (labels) {
            labels += ': ';
          }
          labels += showWithComma(info) + ' COP';
          return labels;

        }
      }
    }
  };
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: ChartDataSets[] = [];
  public doughnutChartType: ChartType = 'doughnut';


  // ############ Cancer Mujer ###############
  // #########################################
  public barChartOptions2: ChartOptions = {
    responsive: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        title: item => item[0].label.replace(/,/g, ' '),
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += showWithComma(tooltipItem.xLabel);
          return label;
        }
      }
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: '# de casos'
        },
        ticks: {
          beginAtZero: true
        }
      }], yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Tipos de cancer'
        }
      }]
    },

  };

  public barChartLabels2: Label[] = [];
  public barChartType2: ChartType = 'horizontalBar';
  public barChartLegend2 = true;
  public barChartData2: ChartDataSets[] = [];

  // ############ Cancer Hombre ##############
  // #########################################
  public barChartOptions3: ChartOptions = {
    responsive: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        title: item => item[0].label.replace(/,/g, ' '),
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += showWithComma(tooltipItem.xLabel);
          return label;
        }
      }
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: '# de casos'
        },
        ticks: {
          beginAtZero: true
        }
      }], yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Tipos de cancer'
        }
      }]
    },

  };

  public barChartLabels3: Label[] = [];
  public barChartType3: ChartType = 'horizontalBar';
  public barChartLegend3 = true;
  public barChartData3: ChartDataSets[] = [];

  // ############ Cancer Costo ###############
  // #########################################
  public barChartOptions4: ChartOptions = {
    responsive: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        title: item => item[0].label.replace(/,/g, ' '),
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += showWithComma(tooltipItem.xLabel) + ' COP';
          return label;
        }
      }
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'COP - Miles de Millones'
        },
        ticks: {
          beginAtZero: true,
          callback(value: any): string | number {
            if (value > 0 && value <= 1) {
              return ' ';
            } else {
              return '$ ' + (value / 1000000000);
            }
          }
        }
      }], yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Tipos de cancer'
        }
      }]
    },

  };

  public barChartLabels4: Label[] = [];
  public barChartType4: ChartType = 'horizontalBar';
  public barChartLegend4 = true;
  public barChartData4: ChartDataSets[] = [];

  // ###############


  constructor(public data: DatosService) {
    this.prevalencia = this.data.prevalenncia.prevalencia;
    this.slugName = 'eic';
    this.fillDataChart();
    this.fillDoughnut();
    this.fillBarsCancer();
    this.fillBarsCancer2();
    this.calculateCost();
    this.chartTab = 0;
    // this.divider = 1000000000; // Miles de millones
  }


  ngOnInit() {
  }

  public update(): void {
    this.resetData();
    this.fillDataChart();
    this.fillDoughnut();
  }


  // ############# TORNADO ###############
  private fillDataChart() {
    for (const item of this.data.enParams) {
      if (item.slug === this.slugName) {
        if (item.slug !== 'erc') {
          const withDiseaseF = {
            data: [], label: 'mujeres con enfermedad',
            backgroundColor: '#007DF7',
            hoverBackgroundColor: '#005BD5'
          };
          const withDiseaseM = {
            data: [], label: 'hombres con enfermedad',
            backgroundColor: '#39B0C1',
            hoverBackgroundColor: '#1790A1'
          };
          const withDisMorF = {
            data: [], label: 'mujeres con enfermedad por sobrepeso',
            backgroundColor: '#00C3F2',
            hoverBackgroundColor: '#00A1D0'
          };
          const withDisMorM = {
            data: [], label: 'hombres con enfermedad por sobrepeso',
            backgroundColor: '#03DBB1',
            hoverBackgroundColor: '#01B991'
          };
          for (const labels of item.ranges) {
            this.barChartLabels.push(labels.segment);
            // @ts-ignore
            withDiseaseF.data.push(labels.poblacion.mujeres);
            // @ts-ignore
            withDiseaseM.data.push(-labels.poblacion.hombres);
            // @ts-ignore
            withDisMorF.data.push((labels.poblacion.mujeres * (labels.mean / 100) * this.prevalencia).toFixed());
            // @ts-ignore
            withDisMorM.data.push((-labels.poblacion.hombres * (labels.mean / 100) * this.prevalencia).toFixed());

          }
          this.barChartData.push(withDiseaseF);
          this.barChartData.push(withDiseaseM);
          this.barChartData.push(withDisMorF);
          this.barChartData.push(withDisMorM);
        } else {
          const withDisease = {
            data: [], label: 'total con enfermedad',
            backgroundColor: '#007DF7',
            hoverBackgroundColor: '#005BD5'
          };
          const withDisMor = {
            data: [], label: 'total con enfermedad por sobrepeso',
            backgroundColor: '#00C3F2',
            hoverBackgroundColor: '#00A1D0'
          };
          for (const labels of item.ranges) {
            this.barChartLabels.push(labels.segment);
            // @ts-ignore
            withDisease.data.push(labels.poblacion.total);
            // @ts-ignore
            withDisMor.data.push((labels.poblacion.total * (labels.mean / 100) * this.prevalencia).toFixed());

          }
          this.barChartData.push(withDisease);
          this.barChartData.push(withDisMor);
        }

      }
    }

    for (const item of this.data.caParams) {
      if ('cancer' === this.slugName) {
        if (item.slug === 'cm') {
          const withDisease = {
            data: [], label: 'mujeres con enfermedad',
            backgroundColor: '#007DF7',
            hoverBackgroundColor: '#005BD5'
          };
          const withDisMor = {
            data: [], label: 'mujeres con enfermedad por sobrepeso',
            backgroundColor: '#00C3F2',
            hoverBackgroundColor: '#00A1D0'
          };
          for (const labels of item.ranges) {
            this.barChartLabels.push(labels.segment);
            // @ts-ignore
            withDisease.data.push(labels.poblacion.total);
            // @ts-ignore
            withDisMor.data.push((labels.poblacion.total * (labels.mean / 100) * this.prevalencia).toFixed());
          }
          this.barChartData.push(withDisease);
          this.barChartData.push(withDisMor);
        } else {
          const withDiseaseM = {
            data: [], label: 'hombres con enfermedad',
            backgroundColor: '#39B0C1',
            hoverBackgroundColor: '#1790A1'
          };
          const withDisMorM = {
            data: [], label: 'hombres con enfermedad por sobrepeso',
            backgroundColor: '#03DBB1',
            hoverBackgroundColor: '#01B991'
          };
          for (const labels of item.ranges) {
            // @ts-ignore
            withDiseaseM.data.push(-labels.poblacion.total);
            // @ts-ignore
            withDisMorM.data.push((-labels.poblacion.total * (labels.mean / 100) * this.prevalencia).toFixed());
          }
          this.barChartData.push(withDiseaseM);
          this.barChartData.push(withDisMorM);
        }
      }
    }


  }

  // ################ DOUGHNUT ############
  public fillDoughnut() {

    const withDiseaseF = {
      data: [], label: 'Costo total',
      backgroundColor: ['#CBF7EE',
        '#009FDA',
        '#B0EBFA',
        '#A2BBE0',
        '#FAD8D4',
        '#81EBD6',
        '#AEDEE5',
        '#98C9FC']
    };
    for (const item of this.data.enParams) {
      // console.log(item.name);
      this.doughnutChartLabels.push(item.name);
      let total = 0;
      // let array = [];
      let femPob = 0;
      let malPob = 0;
      let totalCost = 0;
      for (const labels of item.ranges) {
        // @ts-ignore
        if (item.slug !== 'erc') {
          // @ts-ignore
          femPob = labels.poblacion.mujeres * (labels.mean / 100) * this.data.prevalenncia.prevalencia;
          malPob = labels.poblacion.hombres * (labels.mean / 100) * this.data.prevalenncia.prevalencia;
          total += femPob + malPob;
        } else {
          // @ts-ignore
          total += labels.poblacion.total * (labels.mean / 100) * this.data.prevalenncia.prevalencia;
        }
      }
      for (const cost of this.data.costos) {
        if (cost.name.toLowerCase().includes(item.name.toLowerCase())) {
          totalCost = total * cost.value;
        }
      }
      // array.push(total)
      withDiseaseF.data.push(totalCost.toFixed());
      // console.log(withDiseaseF);

    }
    this.doughnutChartData.push(withDiseaseF);

  }

  public getDataCost(name: string): string {
    const cost = +this.doughnutChartData[0].data[this.doughnutChartLabels.indexOf(name)];
    const divideCost = cost;
    // @ts-ignore
    return divideCost;
    // @ts-ignore
  }

  // ############ Cancer Mujer ###############
  // #########################################
  public fillBarsCancer() {
    for (const item of this.data.caParams) {
      if (item.slug === 'cm') {

        const withDisease = {
          data: [], label: 'Total con enfermedad por sobrepeso',
          backgroundColor: '#39B0C1',
          borderColor: '#39B0C1',
          hoverBackgroundColor: '#1790A0'
        };
        for (const labels of item.ranges) {
          this.barChartLabels2.push(labels.segment);
          // @ts-ignore
          withDisease.data.push((labels.poblacion.total * (labels.mean / 100) * this.data.prevalenncia.prevalencia).toFixed());
          // @ts-ignore
        }
        this.barChartData2.push(withDisease);
      }
    }
  }

  // ############ Cancer Hombre ##############
  // #########################################
  public fillBarsCancer2() {
    for (const item of this.data.caParams) {
      if (item.slug === 'ch') {

        const withDisMor = {
          data: [], label: 'Total con enfermedad por sobrepeso',
          backgroundColor: '#39B0C1',
          borderColor: '#39B0C1',
          hoverBackgroundColor: '#1790A0'
        };
        for (const labels of item.ranges) {
          this.barChartLabels3.push(labels.segment);
          // @ts-ignore
          withDisMor.data.push((labels.poblacion.total * (labels.mean / 100) * this.data.prevalenncia.prevalencia).toFixed());
        }
        this.barChartData3.push(withDisMor);
      }
    }
  }

  // ############ Cancer Costo ###############
  // #########################################
  public calculateCost() {

    const cm = [];
    const ch = [];
    const ct = [];
    const cost = [];
    for (const item of this.data.caParams) {
      for (const range of item.ranges) {
        if (item.slug === 'ch') {
          ch.push([range.segment, range.poblacion.total * (range.mean / 100) * this.data.prevalenncia.prevalencia]);
        } else {
          cm.push([range.segment, range.poblacion.total * (range.mean / 100) * this.data.prevalenncia.prevalencia]);
        }
      }
    }
    for (const cach of ch) {
      for (const cacm of cm) {
        if (cach[0] === cacm[0]) {
          ct.push([cach[0], cach[1] + cacm[1]]);
        }
      }
    }
    // console.log(ct);

    for (const enf of ct) {
      const cName = enf[0].toString();
      // console.log(this.costCa(cName));
      const enCost = this.costCa(cName);
      cost.push([cName, enf[1] * enCost]);
    }

    // console.log(cost);
    const withDis = {
      data: [], label: 'Costo total',
      backgroundColor: '#39B0C1',
      borderColor: '#39B0C1',
      hoverBackgroundColor: '#1790A0'
    };
    for (const item of cost) {
      this.barChartLabels4.push(item[0]);
      withDis.data.push(item[1].toFixed());
    }
    this.barChartData4.push(withDis);

  }

  public costCa(name: string) {
    const cost = this.data.costos.find((item: ICostos) => {
      switch (name) {
        case 'Seno ( <50 años )':
          return item.name.toLowerCase().includes('seno');
        case 'Seno ( >50 años )':
          return item.name.toLowerCase().includes('seno');
        case 'Colón y recto':
          return item.name.toLowerCase().includes('colon');
        case 'Vesícula y vias biliares':
          return item.name.toLowerCase().includes('vesícula');
        default:
          return item.name.toLowerCase().includes(name.toLowerCase());
      }

    });

    if (cost) {
      return cost.value;
    } else {
      return 0;
    }
  }

  public get name(): string {
    const find = this.data.enParams.find((item: any) => {
      return this.slugName && item.slug === this.slugName;
    });
    return find ? find.name : '';
  }

  public get nameCa(): string {
    const find = this.data.caParams.find((item: any) => {
      return this.slugName && item.slug === this.slugName;
    });
    return find ? find.name : '';
  }

  public resetData() {
    this.barChartLabels = [];
    this.barChartData = [];
    this.doughnutChartLabels = [];
    this.doughnutChartData = [];
  }
}
