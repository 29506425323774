import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getByIndex'
})
export class GetByIndexPipe implements PipeTransform {

  transform(value: any[], index: number): any {
    return value[index];
  }

}
