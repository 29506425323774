import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculations-clinical',
  templateUrl: './calculations-clinical.component.html',
  styleUrls: ['./calculations-clinical.component.css']
})
export class CalculationsClinicalComponent implements OnInit {

  window_ref = window as any;

  distribution_map = {
    1: "Determinista", 2: "Normal", 3: "Lognormal", 4: "Gamma", 5: "Beta"
  };

  constructor() { }

  ngOnInit() { }

}
