import { Component, OnInit } from '@angular/core';
import {DatosService} from '../../services/datos.service';

@Component({
  selector: 'app-cancer-cost',
  templateUrl: './cancer-cost.component.html',
  styleUrls: ['./cancer-cost.component.css']
})
export class CancerCostComponent implements OnInit {

  constructor(public data: DatosService) { }

  ngOnInit() {
  }

  public ShowCancerData(item: object){
    let cancerCost = 0;
    // @ts-ignore
    for ( const info of item){
      if (info.name.toLowerCase().includes('cáncer' ) || info.name.toLowerCase().includes('leucemia' )){
        // console.log(info.name);
        cancerCost += info.value;
      }

    }
    return cancerCost;
    // console.log(item);
  }

}
