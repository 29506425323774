import {Component, OnInit, ViewChild} from '@angular/core';
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {

  key = {
    patient_age: 'patient_age',
    patient_weight: 'patient_weight',
    patient_avg_abr: 'patient_avg_abr',
    patient_share_surgery_joint: 'patient_share_surgery_joint',
    life_table: 'life_table'
  };

  patient: any = {};

  life_table_choices: any[] = [
    //{label: 'UK life tables (males)', table_id: 'life_uk'},
    //{label: 'CDC US life tables (males)', table_id: 'life_us'},
    //{label: 'Darby et al. 2007', table_id: 'life_darby'},
    {label: 'Tabla de vida DANE (2017)', table_id: 'life_co'}
  ];

  constructor(private ws: WindowService) {

  }

  ngOnInit() {
    this.reloadData();
  }

  reloadData() {

    let js_window =  this.ws.nativeWindow;

    this.patient.patient_age = js_window.patient_age;
    this.patient.patient_weight = js_window.patient_weight;
    this.patient.patient_share_surgery_joint = js_window.patient_share_surgery_joint;
    this.patient.patient_avg_abr = js_window.patient_avg_abr;
    this.patient.life_table = js_window.life_table;
  }

  setValue(key, value) {
    let js_window =  this.ws.nativeWindow;
    js_window[key] = value;
    if (key === 'patient_avg_abr'){
      js_window['avg_abr_after_surgery'] = value;
    }
    this.patient[key] = value;
  }

}
