import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-db-costs-breakdown]',
  templateUrl: './db-costs-breakdown.component.html',
  styleUrls: ['./db-costs-breakdown.component.css']
})
export class DbCostsBreakdownComponent implements OnInit {

  @Input() main_object: any;
  @Input() show_breakdown: boolean;

  /* main_object[years_map[index]][a_scenario_without_degludec | b_scenario_with_degludec | c_difference][items_map[index]] */

  years_map: Array<string> = [
    'a_cost_year_one',
    'b_cost_year_two',
    'c_cost_year_three',
    'd_cost_year_four',
    'e_cost_year_five'
  ];

  items_map: Array<string> = [
    'a_non_severe_hypo_costs',
    'b_severe_hypo_costs',
    'c_insulin_costs',
    'd_needle_costs',
    'e_smbg_costs',
    'f_total_cost_gbp'
    /*  'g_total_discounted_primary',   NOT USED
     'h_total_discounted_secondary',
     'f_total_patient_expenses',
     'g_total_other' */
  ];

  scenarios_map: Array<string> = [
    'a_scenario_without_degludec',
    'b_scenario_with_degludec',
    'c_difference'
  ];

  scenarios_titles: Array<string> = [
    'Scenario without degludec',
    'Scenario with degludec',
    'Difference'
  ];

  scenarios_percent_map: Array<string> = [
    'd_scenario_without_degludec_percent',
    'e_scenario_with_degludec_percent',
    'f_difference_percent'
  ];

  item_titles: Array<string> = [
    'Non-severe hypo costs',
    'Severe hypo costs',
    'Insulin costs',
    'Needle costs',
    'SMBG costs',
    'Total costs'
  ];

  payer_map: Array<string> = [
    'a_primary_care_cost',
    'b_secondary_care_cost',
    'c_patient_expenses',
    'd_other',
  ];

  payer_titles: Array<string> = [
    'Costos atención primaria ',
    'Costos atención secundaria ',
    'Gastos del paciente ',
    'Otros'
  ];

  years: Array<string> = [];

  constructor() {
    for (let i = 0; i < window['time_horizon']; i++) {
      this.years.push(window['starting_year'] + i);
    }
  }

  ngOnInit() {
  }
}
