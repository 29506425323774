import { Component } from '@angular/core';
import Content from '../../data/content.json';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['../../theme.scss']
})
export class HomeComponent {
  content: any;

  constructor() {
    this.content = Content;
  }
}
