import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-debie-clinical',
  templateUrl: './debie-clinical.component.html',
  styleUrls: ['./debie-clinical.component.css']
})
export class DebieClinicalComponent implements OnInit {

  clinical_type_one = window['clinical_type_one'];
  clinical_type_two_basal_oral = window['clinical_type_two_basal_oral'];
  clinical_type_two_basal_bolus = window['clinical_type_two_basal_bolus'];

  tabs = {
    type_1: ['block', true],
    type_2_bo: ['none', false],
    type_2_bb: ['none', false],
  };

  constructor() {
  }

  ngOnInit() {
  }

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      } else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

}
