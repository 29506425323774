import {Serializable} from '../../semaglutida/models/serializable.interface';

export class Scenario implements Serializable {
  private _id: number;
  private _data: XultophyData;
  private _name: string;
  private _editable: boolean;

  constructor(params: IXultophyScenario) {
    this._id = params.id;
    this._name = params.name;
    this._data = params.data;
    this._editable = params.editable || false;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get data(): XultophyData {
    return this._data;
  }

  set data(value: XultophyData) {
    this._data = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get editable(): boolean {
    return this._editable;
  }

  set editable(value: boolean) {
    this._editable = value;
  }

  getKey(): number | string {
    return this._id;
  }

  toJSON(): IXultophyScenario {
    return {
      name: this._name,
      data: this._data,
      editable: this._editable
    };
  }
}
export interface XultophyData {
  scenario1: XultophySc1;
  scenario2: XultophySc2;
  scenario3: XultophySc3;
}

export interface XultophySc1 {
  personas: number;
  dosisIdegLira: number;
  dosisIglarLixi: number;
  costoIdeglira: number;
  costoIglarLixi: number;
}

export interface XultophySc2 {
  personas: number;
  dosisIdegLira: number;
  dosisIglarLixi: number;
  costoIdeglira: number;
  costoIglarLixi: number;
}

export interface XultophySc3 {
  personas: number;
  dosisIdegLira: number;
  dosisIglarAspartBasal: number;
  dosisIglarAspartBolo: number;
  costoIdeglira: number;
  costoLantus: number;
  costoAspart: number;
}

export interface IXultophyScenario {
  id?: number;
  data?: XultophyData;
  name?: string;
  editable?: boolean;
}
