import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../servicio/api.service';

@Component({
  selector: 'app-meta-parametros',
  templateUrl: './meta-parametros.component.html',
  styleUrls: ['../../../styles/theme.scss']
})
export class MetaParametrosComponent implements OnInit {

  open = false;

  constructor(public api: ApiService) {
  }

  ngOnInit() {
  }

}
