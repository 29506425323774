import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { ApiService } from '../../service/api.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['../../theme.scss']
})
export class TabsComponent implements OnInit, OnDestroy {

  @Output() tap = new EventEmitter<boolean>();
  @Input() scenarioIndex: number;

  private sub: Subscription;

  constructor(public api: ApiService) {

  }

  ngOnInit() {
    this.sub = this.api.dbLoaded.asObservable().subscribe(() => {
      this.tap.emit(true);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onTap(index) {
    this.api.activeTab[this.scenarioIndex] = index;
    this.tap.emit(true);
  }
}
