import {Serializable} from '../../semaglutida/models/serializable.interface';
import {ITests} from '../servicio/api.service';

export class Scenario implements Serializable {
  private _id: number;
  private _data: IODData;
  private _name: string;
  private _editable: boolean;

  constructor(params: IODScenario) {
    this._id = params.id;
    this._name = params.name;
    this._data = params.data;
    this._editable = params.editable || false;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get data(): IODData {
    return this._data;
  }

  set data(value: IODData) {
    this._data = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get editable(): boolean {
    return this._editable;
  }

  set editable(value: boolean) {
    this._editable = value;
  }

  getKey(): number | string {
    return this._id;
  }

  toJSON(): IODScenario {
    return {
      name: this._name,
      data: this._data,
      editable: this._editable
    };
  }
}
export interface IODData {
  numero_pacientes: number;
  metas: Array<ITests>;
  costo_por_paciente_mes: ITests;
}

export interface IODScenario {
  id?: number;
  data?: IODData;
  name?: string;
  editable?: boolean;
}
