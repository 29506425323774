import {APP_INITIALIZER, NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {HomeComponent} from './views/home/home.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {ChartsModule} from 'ng2-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import { Scenario1Component } from './views/scenario1/scenario1.component';
import { Scenario2Component } from './views/scenario2/scenario2.component';
import { Scenario3Component } from './views/scenario3/scenario3.component';
import { AsideComponent } from './components/aside/aside.component';
import { HeaderComponent } from './components/header/header.component';
import { BaseComponent } from './components/base/base.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { XultophyParams1Component } from './views/scenario1/xultophy-params1/xultophy-params1.component';
import { XultophyParams2Component } from './views/scenario2/xultophy-params2/xultophy-params2.component';
import { XultophyParams3Component } from './views/scenario3/xultophy-params3/xultophy-params3.component';
import { CostsTableComponent } from './components/costs-table/costs-table.component';
import { XultophySuministrosComponent } from './views/scenario3/xultophy-suministros/xultophy-suministros.component';
import { CostoTratamientoComponent } from './components/costo-tratamiento/costo-tratamiento.component';
import { MetaXultophyComponent } from './components/meta-xultophy/meta-xultophy.component';
import {AppChartsModule} from '../calculator-globals/charts/app-charts.module';
import {DatabaseService} from './service/database.service';

export const xultophyRoutes: Array<Route> = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {path: 'inicio', component: HomeComponent},
  {path: 'escenario-1', component: Scenario1Component},
  {path: 'escenario-2', component: Scenario2Component},
  {path: 'escenario-3', component: Scenario3Component},
];

export function loadDatabase(database: DatabaseService): () => Promise<any> {
  return () => {
    return new Promise((resolve, reject) => {
      database.init().then(() => {
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [
    HomeComponent,
    Scenario1Component,
    Scenario2Component,
    Scenario3Component,
    AsideComponent,
    HeaderComponent,
    BaseComponent,
    TabsComponent,
    XultophyParams1Component,
    XultophyParams2Component,
    XultophyParams3Component,
    CostsTableComponent,
    XultophySuministrosComponent,
    CostoTratamientoComponent,
    MetaXultophyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ChartsModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    RouterModule,
    AppChartsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadDatabase,
      multi: true,
      deps: [DatabaseService]
    }
  ],
  exports: [],
})
export class XultophyModule {
}
