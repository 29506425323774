import { Component, EventEmitter, Input, Output} from '@angular/core';
import * as Big from 'big.js'
import {CalculatorStatus} from "../../classes/status";

@Component({
  selector: 'app-percent-input',
  templateUrl: './percent-input.component.html',
  styleUrls: ['./percent-input.component.css']
})
export class PercentInputComponent {

  @Input() init_value: number;
  @Input() init_step: number = 0.01;
  @Input() scale: number = 1;
  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  statusInstance: CalculatorStatus = CalculatorStatus.getInstance();

  constructor() { }

  changeValue(negative: boolean){
    let big_value;
    if (negative) {
      big_value = Big(this.init_value).minus(Big(this.init_step * this.scale));
    }
    else {
      big_value = Big(this.init_value).plus(Big(this.init_step * this.scale));
    }
    this.emitChange(parseFloat(big_value));
  }

  percentToFloat(percent){
    let parsed = parseFloat(percent);
    if (!isNaN(parsed)) {
      let fl = Big(parsed);
      this.emitChange(parseFloat((fl.div(Big(100 * this.scale))).toFixed(8)));
    }
    else {
      this.init_value = 0;
      this.emitChange(0);
    }
  }

  emitChange(event) {
    this.statusInstance.modified = true;
    this.valueChanged.emit(event);
  }
}
