import { getMeta, ICostoTratamiento, IFormulaciones, IMeta, runCosts, XulthophyCosts, XulthophyParams, XulthophyScenario } from './models';

export interface ParamsScenario1 extends XulthophyParams {
  cambiohb1ac: IFormulaciones;
  dosisInsulinaPrevio: IFormulaciones;
  dosisInsulinaFinalizar: IFormulaciones;
  dosisDiferencialInicioFinal: IFormulaciones;
  tasasHipoglicemiaConfirmadas: IFormulaciones;
  nauseasParticipantes: IFormulaciones;
  cambioPeso: IFormulaciones;
  metaSimple7: IFormulaciones;
  meta7SinHipoglucemia: IFormulaciones;
  meta7SinHipoglucemiaSinAumentoPeso: IFormulaciones;
}

export class Scenario1 extends XulthophyScenario {
  params: ParamsScenario1;
  costoTratamiento: ICostoTratamiento;

  constructor(params: ParamsScenario1, title: string, costs: XulthophyCosts, personas: number) {
    super(title, costs, personas);
    this.params = params;
    this.costoTratamiento = {
      costoTratamientoAnual: {
        iDegLira: 0,
        iGlarLixi: 0
      },
      deltaCostoTratamientoAnual: 0
    };
  }

  getCosts(): XulthophyCosts {
    this.costs.dosisDiaria = this.params.dosisInsulinaFinalizar;
    this.costs = runCosts(this.costs);
    return this.costs;
  }

  getCostoTratamiento(): ICostoTratamiento {
    this.costoTratamiento.costoTratamientoAnual = {
      iDegLira: this.getCosts().gastoAnual.iDegLira * this.personas,
      iGlarLixi: this.getCosts().gastoAnual.iGlarLixi * this.personas
    };
    this.costoTratamiento.deltaCostoTratamientoAnual = this.costoTratamiento.costoTratamientoAnual.iDegLira - this.costoTratamiento.costoTratamientoAnual.iGlarLixi;
    return this.costoTratamiento;
  }

  getMetaSimple(): IMeta {
    return getMeta(this.personas, this.params.metaSimple7, this.getCostoTratamiento().costoTratamientoAnual, 1, 'DAP Meta simple <7% HbA1c)');
  }

  getMetaDoble(): IMeta {
    return getMeta(this.personas, this.params.meta7SinHipoglucemia, this.getCostoTratamiento().costoTratamientoAnual, 1, 'DAP Meta doble (<7% Hb1Ac + sin hipoglicemia)');
  }

  getMetaTriple(): IMeta {
    return getMeta(this.personas, this.params.meta7SinHipoglucemiaSinAumentoPeso, this.getCostoTratamiento().costoTratamientoAnual, 1, 'Meta triple (<7% Hb1Ac + sin hipoglicemia + sin ganancia de peso)');
  }

}
