import {AppSettings} from "../app.settings";

export class DataArray {
  name: string;
  data: any[];
  data_type: string;
  id: string;
  step: number;

  constructor(name: string, data: any[], data_type = AppSettings.NUMERIC, id: string, step?: number) {
    this.name = name;
    this.data = data;
    this.data_type = data_type;
    this.id = id;
    this.step = step;
  }

  appendData(obj){
    this.data.push(obj)
  }
}
