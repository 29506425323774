export class AppSettings {
  public static get PERCENT() {
    return 'percent';
  }

  public static get NUMERIC() {
    return 'numeric';
  }

  public static get TEXT() {
    return 'text';
  }

  public static get LIST() {
    return 'list';
  }

  public static get LABEL() {
    return 'label';
  }

  public static get CHART_BAR_NUMBERS_FONT_SIZE(): number {
    return 12;
  }

  public static get DECTOOL_ID(): string {
    return 'decision-tool';
  }

  public static DECTOOL_URL(path = '') {
    return this.getPath(AppSettings.DECTOOL_ID, path);
  }

  public static get ON_DEMAND_ID(): string {
    return 'on-demand';
  }

  public static ON_DEMAND_URL(path = ''): string {
    return this.getPath(AppSettings.ON_DEMAND_ID, path);
  }

  public static get PROFILAXIS_ID(): string {
    return 'profilaxis';
  }

  public static PROFILAXIS_URL(path = ''): string {
    return this.getPath(AppSettings.PROFILAXIS_ID, path);
  }

  public static get DOSESCOSTS_ID(): string {
    return 'doses-costs';
  }

  public static DOSESCOSTS_URL(path = ''): string {
    return this.getPath(AppSettings.DOSESCOSTS_ID, path);
  }

  public static get DEBIE_BUDGET_ID(): string {
    return 'debie-budget';
  }

  public static DEBIE_BUDGET_URL(path = ''): string {
    return this.getPath(AppSettings.DEBIE_BUDGET_ID, path);
  }

  public static get DOSE_MODEL_ID(): string {
    return 'dose-model';
  }

  public static DOSE_MODEL_URL(path = ''): string {
    return this.getPath(AppSettings.DOSE_MODEL_ID, path);
  }

  public static get VICTOZA_ID(): string {
    return 'victoza';
  }

  public static VICTOZA_URL(path = ''): string {
    return this.getPath(AppSettings.VICTOZA_ID, path);
  }

  public static get OBESITY_ID(): string {
    return 'obesidad';
  }

  public static OBESITY_URL(path = ''): string {
    return this.getPath(AppSettings.OBESITY_ID, path);
  }

  public static get SEMAGLUTIDA_ID(): string {
    return 'semaglutida';
  }

  public static SEMAGLUTIDA_URL(path = ''): string {
    return this.getPath(AppSettings.SEMAGLUTIDA_ID, path);
  }

  public static get OZEMPIC_DAB_ID(): string {
    return 'ozempic-dab';
  }

  public static OZEMPIC_DAB_URL(path = ''): string {
    return this.getPath(AppSettings.OZEMPIC_DAB_ID, path);
  }

  public static get OZEMPIC_CCR_ID(): string {
    return 'ozempic-ccr';
  }

  public static OZEMPIC_CCR_URL(path = ''): string {
    return this.getPath(AppSettings.OZEMPIC_CCR_ID, path);
  }

  public static get XULTOPHY_ID(): string {
    return 'xultophy';
  }

  public static XULTOPHY_URL(path = ''): string {
    return this.getPath(AppSettings.XULTOPHY_ID, path);
  }

  public static get CALCULATOR_DATA(): Array<ICalculatorCategory> {
    return [
      {
        category: 'Obesidad',
        calculators: [{
          name: this.OBESITY_ID,
          route: this.OBESITY_URL(),
          icon: 'assets/icons/logo.png',
          title: 'Calculadora de<br>obesidad',
          noScenarios: true
        }]
      },
      {
        category: 'Xultophy',
        calculators: [{
          name: this.XULTOPHY_ID,
          route: this.XULTOPHY_URL(),
          icon: 'assets/pix/xultophy.png',
          title: 'Calculadora Xultophy',
          noScenarios: true
        }]
      },
      {
        category: 'Semaglutida',
        calculators: [{
          name: this.SEMAGLUTIDA_ID,
          route: this.SEMAGLUTIDA_URL(),
          icon: 'assets/icons/logo-ozempic.png',
          title: 'Modelo Costo<br>del Control',
          noScenarios: true
        }, {
          name: this.OZEMPIC_DAB_ID,
          route: this.OZEMPIC_DAB_URL(),
          icon: 'assets/icons/logo-ozempic.png',
          title: 'Ozempic DAP',
          noScenarios: true
        },
          {
            name: this.OZEMPIC_CCR_ID,
            route: this.OZEMPIC_CCR_URL(),
            icon: 'assets/icons/logo-ozempic.png',
            title: 'Ozempic CCR',
            noScenarios: true
          }
        ]
      },
      /*{
          category: 'Diabetes',
          calculators: [
              {
                  name: this.DEBIE_BUDGET_ID,
                  route: this.DEBIE_BUDGET_URL(),
                  icon: 'assets/img/image_8.png',
                  title: 'Modelo de Impacto Presupuestal de<br>Tresiba'
              },
              {
                  name: this.DOSE_MODEL_ID,
                  route: this.DOSE_MODEL_URL(),
                  icon: 'assets/img/image_8.png',
                  title: 'Costo-efectividad<br>insulina Degludec vs insulina Glargina®'
              },
              {
                  name: this.VICTOZA_ID,
                  route: this.VICTOZA_URL(),
                  icon: 'assets/img/image_7.png',
                  title: 'Calculadora modelo<br>Victoza®'
              }
          ]
      },*/
      {
        category: 'Hemofilia',
        calculators: [
          {
            name: this.PROFILAXIS_ID,
            route: this.PROFILAXIS_URL(),
            icon: 'assets/img/image_6.png',
            title: 'Costo de tratamiento<br>en profiláxis CHWI'
          },
          {
            name: this.DOSESCOSTS_ID,
            route: this.DOSESCOSTS_URL(),
            icon: 'assets/img/image_6.png',
            title: 'Uso a demanda<br>rFVIIa vs pd-APCC'
          },
          {
            name: this.DECTOOL_ID,
            route: this.DECTOOL_URL(),
            icon: 'assets/img/image_5.png',
            title: 'Herramienta para toma de decisiones en<br>cirugía ortopédica de NovoSeven®'
          },
          {
            name: this.ON_DEMAND_ID,
            route: this.ON_DEMAND_URL(),
            icon: 'assets/img/image_6.png',
            title: 'Modelo de Impacto Presupuestal de<br>NovoSeven®'
          }
        ]
      }
    ];
  }

  private static getPath(base: string, path: string): string {
    path = (path === '') ? '' : '/' + path;
    return base + path;
  }
}

interface ICalculatorData {
  name: string;
  route: string;
  icon: string;
  title: string;
  noScenarios?: boolean;
}

interface ICalculatorCategory {
  category: string;
  calculators: Array<ICalculatorData>;
}
