import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { IFormulaciones, XulthophyCosts } from '../../models/models';

@Component({
  selector: 'app-costs-table',
  templateUrl: './costs-table.component.html',
  styleUrls: ['../../theme.scss']
})
export class CostsTableComponent implements OnInit {

  @Input() data: XulthophyCosts;
  @Input() case3: boolean;
  @Output() valores = new EventEmitter<IFormulaciones>();

  localData: XulthophyCosts;

  constructor() {
  }

  ngOnInit() {
    this.localData = this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localData = changes.data.currentValue;
  }

  sendData() {
    this.valores.emit(this.localData.valorLapicero);
  }

}
