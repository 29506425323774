import {Component, OnInit} from '@angular/core';
import {DatabaseService} from '../../database/database.service';
import {Scenario} from '../../models/scenario.class';
import {MODEL} from '../../models/factory';
import {ModalService} from '../../modals/modal.service';
import {DatosService} from '../../services/datos.service';
import {Router} from '@angular/router';
import {ISaveScenario} from '../../modals/save-scenario/save-scenario.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private database: DatabaseService,
              private modal: ModalService,
              private data: DatosService,
              private router: Router) {
  }

  ngOnInit() {
  }

  public loadScenario(): void {
    this._loadScenario().then((sc: Scenario) => {
      if (sc) {
        this.data.setScenario(sc);
      }
    });
  }

  public saveScenario(): void {
    this._saveScenario().then((sc: Scenario) => {
      if (sc) {
        this.data.setScenario(sc);
      }
    });
  }

  private async _loadScenario(): Promise<Scenario> {
    const scenarios = await this.database.getAllObjects<Scenario>('Scenario', MODEL.Scenario);
    return await this.modal.loadScenario(scenarios);
  }

  private async _saveScenario(): Promise<Scenario> {
    const scenarios = await this.database.getAllObjects<Scenario>('Scenario', MODEL.Scenario);
    const data: ISaveScenario = await this.modal.saveScenario(scenarios);
    let scenario: Scenario;
    if (data) {
      if (data.create) {
        scenario = this.data.getCopyCurrent(data.name);
        scenario.setEditable(true);
        const response = await this.database.addObject('Scenario', scenario);
        scenario.setKey(response.key);
      } else {
        scenario = this.data.getScenario();
        if (scenario.editable) {
          await this.database.updateObject('Scenario', scenario);
        }
      }
      return scenario;
    } else {
      return undefined;
    }
  }
}
