import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCheckboxModule, MatDialogModule, MatListModule, MatRadioModule} from '@angular/material';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [
    MatDialogModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule
  ]
})
export class MaterialModule {
}
