import {Component, OnInit} from '@angular/core';
import {DatosService} from '../../services/datos.service';
import {ICostos} from '../../models/scenario.class';

@Component({
  selector: 'app-input-costos',
  templateUrl: './input-costos.component.html',
  styleUrls: ['./input-costos.component.css']
})
export class InputCostosComponent implements OnInit {

  constructor(protected data: DatosService) {
  }

  ngOnInit() {
  }

  currencyInputChanged(value) {
    const num = value.replace(/[$,]/g, '');
    return Number(num);
  }

  get costos(): Array<ICostos> {
    return this.data ? this.data.costos : [];
  }

}
