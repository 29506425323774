import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { ISuministros, XulthophySuministros } from '../../../models/models';

@Component({
  selector: 'app-xultophy-suministros',
  templateUrl: './xultophy-suministros.component.html',
  styleUrls: ['../../../theme.scss']
})
export class XultophySuministrosComponent implements OnInit {

  @Input() data: XulthophySuministros;
  @Input() totals: ISuministros;
  localData: XulthophySuministros;
  localTotal: ISuministros;

  constructor(public api: ApiService) {
  }

  ngOnInit() {
    this.localData = this.data;
    this.localTotal = this.totals;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localData = changes.data.currentValue;
    this.localTotal = changes.totals.currentValue;
  }

}
