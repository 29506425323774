import {Component, OnInit} from '@angular/core';
import {SemaDataService} from '../../services/sema-data.service';
import {IMedsData, IResources} from '../../models/scenario.class';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['../../theme.scss']
})
export class ResourcesComponent implements OnInit {

  resourcesMap: Array<IEffectivenessMap>;
  resources: IResources;
  consumableMap: Array<IEffectivenessMap>;
  additionalMap: Array<IEffectivenessMap>;

  showConsumables = false;
  showAdditional = false;

  constructor(protected data: SemaDataService) {
    this.resources = data.inputs.resources;
    this.resourcesMap = [
      {
        label: 'Semaglutida dosis semanal (mg)',
        data: this.resources.semaglutidaDoses
      },
      {
        label: 'Dulaglutida dosis semanal (mg)',
        data: this.resources.dulaglutidaDoses
      },
    ];
    this.consumableMap = [
      {
        label: 'Agujas por semana',
        data: this.resources.needles
      },
      {
        label: 'Pruebas SMBG por semana',
        data: this.resources.smbg
      }
    ];
    this.additionalMap = [
      {
        label: 'Costos adicionales por semana',
        data: this.resources.additionalCosts
      }
    ];

    this.showConsumables = data.params.includeCostConsumable;
    this.showAdditional = data.params.includeCostAdditional;
  }

  ngOnInit() {
  }
}

interface IEffectivenessMap {
  label: string;
  data: IMedsData;
}
