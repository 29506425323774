import { getMeta, ICostoTratamiento, IFormulaciones, IMeta, runCosts, XulthophyCosts, XulthophyParams, XulthophyScenario } from './models';

export interface ParamsScenario2 extends XulthophyParams {
  metaHb1ac7: IFormulaciones;
  metaHb1ac65: IFormulaciones;
  dosisInsulinaPromedio: IFormulaciones;
}

export class Scenario2 extends XulthophyScenario {
  params: ParamsScenario2;
  costoTratamiento: ICostoTratamiento;

  constructor(params: ParamsScenario2, title: string, costs: XulthophyCosts, personas: number) {
    super(title, costs, personas);
    this.params = params;
    this.costoTratamiento = {
      costoTratamientoAnual: {
        iDegLira: 0,
        iGlarLixi: 0
      },
      deltaCostoTratamientoAnual: 0
    };
  }

  getCosts(): XulthophyCosts {
    this.costs.dosisDiaria = this.params.dosisInsulinaPromedio;
    this.costs = runCosts(this.costs);
    return this.costs;
  }

  getCostoTratamiento(): ICostoTratamiento {
    this.costoTratamiento.costoTratamientoAnual = {
      iDegLira: this.getCosts().gastoAnual.iDegLira * this.personas,
      iGlarLixi: this.getCosts().gastoAnual.iGlarLixi * this.personas
    };
    this.costoTratamiento.deltaCostoTratamientoAnual = this.costoTratamiento.costoTratamientoAnual.iDegLira - this.costoTratamiento.costoTratamientoAnual.iGlarLixi;
    return this.costoTratamiento;
  }

  getMetaConvencional(): IMeta {
    return getMeta(this.personas, this.params.metaHb1ac7, this.getCostoTratamiento().costoTratamientoAnual, 1, 'Meta <7% (Meta convencional)');
  }

  getMetaEstricta(): IMeta {
    return getMeta(this.personas, this.params.metaHb1ac65, this.getCostoTratamiento().costoTratamientoAnual, 1, 'Meta <6,5% (Meta Estrícta)');
  }

}
