import { Component, OnInit } from '@angular/core';
import { WindowService } from "../../../services/window.service";
import { AppSettings } from "../../../app.settings";

declare const Chart: any;

@Component({
  selector: 'app-bleeds',
  templateUrl: './bleeds.component.html',
  styleUrls: ['./bleeds.component.css']
})
export class BleedsComponent implements OnInit {

  public bChartType: string = 'bar';
  public bDataSets: Array<any> = [
    {data: [], label: 'Con cirugía'},
    {data: [], label: 'Sin cirugía'}
  ];

  public bLabels: Array<any> = ['Sangrados'];
  public bLegend: boolean = true;
  public bOptions: any = {
    showDatapoints: true,
    responsive: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 50,
          suggestedMax: 350
        }
      }]
    }
  };

  messageMapping:
    {[k: string]: string} = {'=1': '# Año', 'other': '# Años'};

  avoided_bleeds: number = 0;
  years: number = 0;

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;
    js_window.runSurgery();

    this.years = js_window["years"];
    let yrIndex = js_window["years"] - 1;
    this.avoided_bleeds = js_window["nsBleeds"][yrIndex]["cbleeds"] - js_window["sBleeds"][yrIndex]["cbleeds"];

    this.bDataSets[0].data.push(js_window["sBleeds"][yrIndex]["cbleeds"]);
    this.bDataSets[1].data.push(js_window["nsBleeds"][yrIndex]["cbleeds"]);

  }

  ngOnInit() {
  }

}
