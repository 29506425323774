import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataArray } from "../../classes/data-array";
import {CalculatorStatus} from "../../classes/status";

@Component({
  selector: 'app-table-input',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.css']
})
export class TableInputComponent implements OnInit {

  @Input() button_name: string;
  @Input() data_arrays: DataArray[];
  @Input() rows_count: number;

  @Output() rowChanged = new EventEmitter();

  statusInstance: CalculatorStatus = CalculatorStatus.getInstance();

  vals: number[];

  display_table: string;

  constructor() {
    this.display_table = 'none'
  }

  ngOnInit() {
    this.vals = Array(this.rows_count).fill(1)
  }

  toggle() {
    if (this.display_table == 'none'){
      this.display_table = 'block';
    }
    else {
      this.display_table = 'none';
    }
  }

  logValues() {
    console.log(this.data_arrays);
  }

  getValues() {
    return this.data_arrays;
  }

  updateRow(data_array: DataArray, row: number, event: any) {
    data_array.data[row] = event;
    this.statusInstance.modified = true;
    this.rowChanged.emit({data_array_id: data_array.id, row: row});
  }
}
