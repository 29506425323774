import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-victoza-inputs',
  templateUrl: './victoza-inputs.component.html',
  styleUrls: ['./victoza-inputs.component.css']
})
export class VictozaInputsComponent {

  window_ref = window as any;
  victoza_poblacion: any;

  pop_participation_array = [
    {title: 'Liraglutide', data: window['victoza_liraglutide']},
    {title: 'Exanentide', data: window['victoza_exanentide']},
    {title: 'Sitagliptina', data: window['victoza_sitagliptina']},
    {title: 'Glimepiride', data: window['victoza_glimepiride']}
  ];

  clinic_subkeys = ['a_iam', 'b_acv', 'c_microvascular'];

  clinic_data_array = [
    {
      title: 'Población con exanentida',
      subtitles: ['IAM', 'ACV', 'Microvascular\n\r(Nefropatia)'],
      subkeys: this.clinic_subkeys,
      data: window['poblacion_con_exanentida']
    },
    {
      title: 'Población con liraglutide',
      subtitles: ['IAM', 'ACV', 'Microvascular\n\r(Retinopatia)'],
      subkeys: this.clinic_subkeys,
      data: window['poblacion_con_liraglutide']
    },
    {
      title: 'Población con sitagliptina',
      subtitles: ['IAM', 'ACV', 'Microvascular\n\r(Retinopatia)'],
      subkeys: this.clinic_subkeys,
      data: window['poblacion_con_sitagliptina']
    },
    {
      title: 'Población con glimepiride',
      subtitles: ['IAM', 'ACV', 'Microvascular\n\r(Retinopatia)'],
      subkeys: this.clinic_subkeys,
      data: window['poblacion_con_glimepiride']
    },
    {
      title: '',
      subtitles: ['Mortalidad por\n\revento macrovascular'],
      subkeys: ['mortalidad_por_evento'],
      data: window['mortalidad_por_evento_macrovascular']
    }
  ];

  constructor() {
    this.window_ref.run_victoza();
    this.victoza_poblacion = this.window_ref.victoza_poblacion;
  }

  updateValue(object, key, value) {
    if (object.hasOwnProperty('data')) {
      object.data[key] = value;
    } else if (object.hasOwnProperty(key)) {
      object[key] = value;
    }
    this.window_ref.run_victoza();
  }

  stringify(value) {
    return JSON.stringify(value);
  }

}
