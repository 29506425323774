import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputs-doses-costs',
  templateUrl: './inputs-doses-costs.component.html',
  styleUrls: ['./inputs-doses-costs.component.css']
})
export class InputsDosesCostsComponent implements OnInit {

  inputs: any = {
    escenario: 0,
    Peso_paciente_promedio: 0,
    Dosis_rFVIIa: 0,
    Dosis_pdAPCC: 0,
    Presentacion_rFVIIa: 0,
    Presentacion_pdAPCC: 0,
    Costo_por_ampolla_rFVIIa: 0,
    Costo_por_ampolla_pdAPCC: 0,
    Dosis_requeridas_rFVIIa: 0,
    Dosis_requeridas_pd_APCC: 0,
    Eficacia_rFVIIa_cese_sangrado: 0,
    Eficacia_pdAPCC_cese_sangrado: 0,
  };

  constructor() {
    for (let i in this.inputs) {
      if (this.inputs.hasOwnProperty(i)) {
        this.inputs[i] = window[i];
      }
    }
    (window as any).tomarbackup();
  }

  ngOnInit() {
  }

  updateValue(key, value) {
    this.inputs[key] = value;
    window[key] = value;
    (window as any).tomarbackup();
  }
}
