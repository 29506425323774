import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { IMeta } from '../../models/models';

@Component({
  selector: 'app-meta-xultophy',
  templateUrl: './meta-xultophy.component.html',
  styleUrls: ['../../theme.scss']
})
export class MetaXultophyComponent implements OnInit, OnChanges {

  @Input() data: IMeta;
  @Input() case3: boolean;
  localData: IMeta;

  constructor() {
  }

  ngOnInit() {
    this.localData = this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localData = changes.data.currentValue;
  }

}
