import { Component, OnInit } from '@angular/core';
import { AppSettings } from "../../../app.settings";
import { CurrencyPipe } from '@angular/common';

declare const Chart: any;

@Component({
  selector: 'app-outputs-profilaxis',
  templateUrl: './outputs-profilaxis.component.html',
  styleUrls: ['./outputs-profilaxis.component.css']
})
export class OutputsProfilaxisComponent implements OnInit {

  public profChartLegend: boolean = true;
  public profChartType: string = 'bar';
  public profDataSets: Array<any> = [
    {data: [], label: 'Eptacog alfa'},
    {data: [], label: 'pd-aPCC'},
  ];

  public profChartLabels: Array<string> = ['Costo por trimestre'];
  public profChartOptions:any = {
    showDatapointsCurrency: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
        }
      }]
    }
  };


  public color:Array<any> = [
    {
      backgroundColor: '#001965'
    },
    {
      backgroundColor: '#e1e1da'
    }
  ];

  outputs: any = {
    eptacog_alfa: 0,
    pd_apcc_profilaxis: 0,
    delta: 0
  };

  constructor() {
    (window as any).calc_costcompare();

    for (let i in this.outputs){
      if (this.outputs.hasOwnProperty(i)){
        this.outputs[i] = window[i];
      }
    }

    this.profDataSets[0].data.push(this.outputs.eptacog_alfa['Costtrimestre']);
    this.profDataSets[1].data.push(this.outputs.pd_apcc_profilaxis['Costtrimestre']);

  }

  ngOnInit() {
  }

}
