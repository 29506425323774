import {Serializable} from './serializable.interface';
import {DEFAULT} from '../database/database.const';

export class Scenario implements Serializable {
  private _id: number;
  private _costos: Array<ICostos>;
  private _enParams: Array<IEParametros>;
  private _caParams: Array<IEParametros>;
  private _prevalencia: IMain;
  private _pae: Array<IPAE>;
  private _name: string;
  private _editable: boolean;

  constructor(params: IScenario) {
    this._id = params.id;
    this._name = !!params.name ? params.name : DEFAULT.name;
    this._costos = params.costos;
    this._enParams = params.enParams;
    this._caParams = params.caParams;
    this._prevalencia = params.prevalencia;
    this._pae = params.pae;
    this._editable = params.hasOwnProperty('editable') ? params.editable : false;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get id(): number {
    return this._id;
  }

  public setKey(id: number): void {
    this._id = id;
  }

  get costos(): Array<ICostos> {
    return this._costos;
  }

  get enParams(): Array<IEParametros> {
    return this._enParams;
  }

  get caParams(): Array<IEParametros> {
    return this._caParams;
  }

  get prevalencia(): IMain {
    return this._prevalencia;
  }

  get pae(): Array<IPAE> {
    return this._pae;
  }

  get editable(): boolean {
    return this._editable;
  }

  public setEditable(status: boolean): void {
    this._editable = status;
  }

  getKey(): number {
    return this._id;
  }


  toJSON(): IScenario {
    return {
      name: this._name,
      costos: this._costos,
      enParams: this._enParams,
      caParams: this._caParams,
      prevalencia: this._prevalencia,
      pae: this._pae,
      editable: this._editable
    };
  }
}

export interface ICostos {
  name: string;
  value: number;
  ref: number;
}

export interface IEParametros {
  name: string;
  ranges: Array<IRange>;
  poblacion: Array<string>;
  slug: string;
}

export interface IRange {
  segment: string;
  mean: number;
  min: number;
  max: number;
  poblacion: any;
}

export interface IMain {
  prevalencia: number;
}

export interface IPAE {
  name: string;
  slug: string;
  references?: number[];
  ranges?: Array<IPAER>;
  type?: Array<IPAET>;
}

export interface IPAER {
  segment: string;
  references?: number[];
  mean: number;
}

export interface IPAET {
  segment: string;
  references: number[];
  pm: number;
  ph: number;
}

export interface IRef {
  id: number;
  reference: string;

}

export interface IScenario {
  id?: number;
  name?: string;
  costos: Array<ICostos>;
  enParams: Array<IEParametros>;
  caParams: Array<IEParametros>;
  prevalencia: IMain;
  pae: Array<IPAE>;
  editable?: boolean;
}
