import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ICostoTratamiento } from '../../models/models';

@Component({
  selector: 'app-costo-tratamiento',
  templateUrl: './costo-tratamiento.component.html',
  styleUrls: ['../../theme.scss']
})
export class CostoTratamientoComponent implements OnInit {

  @Input() data: ICostoTratamiento;
  @Input() case3: boolean;
  localData: ICostoTratamiento;

  constructor() {
  }

  ngOnInit() {
    this.localData = this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.localData = changes.data.currentValue;
  }

}
