import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './views/components/base/base.component';
import { AsideComponent } from './views/components/aside/aside.component';
import { HeaderComponent } from './views/components/header/header.component';
import { HomeComponent } from './views/home/home.component';
import { ParametrosComponent } from './views/parametros/parametros.component';
import { MetaComponent } from './views/meta/meta.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { Route, RouterModule } from '@angular/router';
import { ApiService } from './servicio/api.service';
import { DatabaseService } from './servicio/database.service';
import { MetaParametrosComponent } from './views/meta/meta-parametros/meta-parametros.component';


export const ozempicCcrRoutes: Array<Route> = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  { path: 'inicio', component: HomeComponent },
  { path: 'parametros', component: ParametrosComponent, resolve: {data: ApiService}},
  { path: 'meta/:id', component: MetaComponent, resolve: {data: ApiService}}
];


export function loadDatabase(database: DatabaseService): () => Promise<any> {
  return () => {
    return new Promise((resolve, reject) => {
      database.init().then(() => {
        resolve();
      });
    });
  };
}


@NgModule({
  declarations: [
    BaseComponent,
    AsideComponent,
    HeaderComponent,
    HomeComponent,
    ParametrosComponent,
    MetaComponent,
    MetaParametrosComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ChartsModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    RouterModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadDatabase,
      multi: true,
      deps: [DatabaseService]
    }
  ],
  exports: [
    HomeComponent
  ]
})
export class OzempicCcrModule {
}
