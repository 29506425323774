import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {

  analisis_sensibilidad: any = {
    as_Dosis_requeridas_rFVIIa : 0,
    as_Dosis_requeridas_pd_APCC : 0,
    as_Eficacia_rFVIIa_cese_sangrado : 0,
    as_Eficacia_pdAPCC_cese_sangrado : 0,
    as_Costo_por_tratamiento_exitoso_rFVIIa : 0,
    as_Costo_por_tratamiento_exitoso_pdAPCC : 0,
    as_Ahorro_por_usar_Novoseven : 0,
    as_Porcentaje_de_ahorro_de_costo_total : 0,
    as_Costo_total_de_rFVIIa_incluye_2a_linea : 0,
    as_Costo_total_de_pdAPCC_incluye_2a_linea : 0,
    as_Costo_total_ahorrado_por_usar_rFVIIa : 0,
    as_porcentaje_de_ahorro_por_usar_rFVIIa : 0
  };

  episodio_sangrado: any = {
    Dosis_requeridas_rFVIIa : 0,
    Dosis_requeridas_pd_APCC : 0,
    Eficacia_rFVIIa_cese_sangrado : 0,
    Eficacia_pdAPCC_cese_sangrado : 0,
    Costo_por_tratamiento_exitoso_rFVIIa : 0,
    Costo_por_tratamiento_exitoso_pdAPCC : 0,
    Ahorro_por_usar_Novoseven : 0,
    Porcentaje_de_ahorro_de_costo_total : 0,
    Costo_total_de_rFVIIa_incluye_2a_linea : 0,
    Costo_total_de_pdAPCC_incluye_2a_linea : 0,
    Costo_total_ahorrado_por_usar_rFVIIa : 0,
    porcentaje_de_ahorro_por_usar_rFVIIa : 0
  };

  public ChartType: string = 'bar';
  public DataSets: Array<any> = [
    {data: [], label: 'Escenario actual'},
    {data: [], label: 'Análisis de sensibilidad'},
    //{data: [], label: 'Sin cirugía'}
  ];

  public Labels: Array<any> = ['Costo total ahorrado por usar rFVIIa'];
  public Legend: boolean = true;
  public chartOptions: any = {
    showDatapointsCurrency: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  public color:Array<any> = [
    {
      backgroundColor: '#001965'
    },
    {
      backgroundColor: '#e1e1da'
    }
  ];

  constructor() {
    (window as any).tomarbackup();
    (window as any).calc_costydoses();
    this.loadDataToComponent();
  }

  switchSAScenario(val: string) {
    window['escenario'] = val;
    (window as any).calc_costydoses();

    this.loadDataToComponent();
  }

  loadDataToComponent() {
    for (let key in this.analisis_sensibilidad) {
      if (this.analisis_sensibilidad.hasOwnProperty(key)) {
        this.analisis_sensibilidad[key] = window[key];
      }
    }

    for (let key in this.episodio_sangrado) {
      if (this.episodio_sangrado.hasOwnProperty(key)) {
        this.episodio_sangrado[key] = window[key];
      }
    }
    this.updateChartData();
  }

  updateChartData(){
    this.DataSets[0].data = [];
    this.DataSets[1].data = [];
    this.Labels = [];

    this.DataSets[0].data.push(this.analisis_sensibilidad.as_Costo_por_tratamiento_exitoso_rFVIIa);
    this.DataSets[1].data.push(this.analisis_sensibilidad.as_Costo_por_tratamiento_exitoso_pdAPCC);
    this.Labels.push('Costo total ahorrado por usar rFVIIa');
  }

  ngOnInit() {
    this.switchSAScenario("default");
  }

}
