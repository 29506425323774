import { Component, OnInit } from '@angular/core';
import { WindowService } from "../../../services/window.service";

@Component({
  selector: 'app-total-bi',
  templateUrl: './total-bi.component.html',
  styleUrls: ['./total-bi.component.css']
})
export class TotalBiComponent implements OnInit {

  public total_bi: any;

  public tbChartLegend: boolean = true;
  public tbChartType: string = 'bar';
  public tbDataSets: Array<any> = [
    {data: [], label: 'Escenario Actual'},
    {data: [], label: 'Escenario Alternativo'},
  ];

  public tbChartLabels: Array<string> = ['Costo total en el tiempo'];
  public tbChartOptions:any = {
    showDatapoints: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
        }
      }]
    }
  };

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    js_window.runOnDemand();

    this.total_bi = js_window['total_bi']['total_bi'];

    this.tbDataSets[0].data.push(this.total_bi['per_year_current_scenario']['Total_Cost']);
    this.tbDataSets[1].data.push(this.total_bi['per_year_alternative_scenario']['Total_Cost']);
  }

  ngOnInit() {
  }

}
