import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";
import {AppSettings} from "../../../app.settings";

declare const Chart: any;

@Component({
  selector: 'app-dsa',
  templateUrl: './dsa.component.html',
  styleUrls: ['./dsa.component.css']
})
export class DsaComponent implements OnInit {

  public dsaChartType: string = 'horizontalBar';
  public dsaDataSets: Array<any> = [
    {data: [], label: 'Valor máximo'},
    {data: [], label: 'Valor mínimo'}
  ];

  public dsaLabels: Array<string> = [];
  public dsaLegend: boolean = true;
  public dsaOptions: any = {
    showDatapointsTornado: true,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        ticks: {
          callback: function(value, index, values) {
            return (value + window['tornado_center']).toFixed(2);
          }
        }
      }],
      yAxes: [{
        barThickness: 20,
        stacked: true
      }]
    }
  };

  public break_event: number;

  keys = [
    'variation'
  ];
  dsa: any = {};

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    for (let key of this.keys) {
      this.dsa[key] = js_window[key];
    }

    js_window.runSurgery();
    js_window.calcMinMax();

    this.break_event = js_window['tornado_center'];
    this.setChartData();
  }

  ngOnInit() {
  }

  updateValue(key, value) {
    let js_window = this.ws.nativeWindow;

    js_window[key] = value;
    this.dsa[key] = value;

    js_window.runSurgery();
    js_window.calcMinMax();
    this.setChartData();
  }

  private setChartData() {
    let js_window = this.ws.nativeWindow;

    this.dsaDataSets[0].data = [];
    this.dsaDataSets[1].data = [];
    this.dsaLabels = [];

    for(let item of js_window['ordered_tornado_keys_array']) {
      this.dsaDataSets[0].data.push(js_window['breakevent_times_tornado'][item.key]['min'] - js_window['tornado_center']);
      this.dsaDataSets[1].data.push(js_window['breakevent_times_tornado'][item.key]['max'] - js_window['tornado_center']);
      this.dsaLabels.push(js_window['breakevent_times_tornado'][item.key]['name']);
    }
  }
}
