import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: IModalData,
                private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.dialogRef.disableClose = true;
    }

    confirm() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}

export interface IModalData {
    title: string;
    message: string;
}
