import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.css']
})
export class CostsBIComponent implements OnInit {

  keys: string[] = [
    'UnitCost_NovoSeven',
    'UnitCost_pdAPCC',

    'CostPerDayInHospital',
    'CostPerOutpatientVisit',

    'othercostcurrentline1',
    'othercostcurrentline2',
    'othercostcurrentline3',

    'othercostalternativeline1',
    'othercostalternativeline2',
    'othercostalternativeline3',

    'CostPerVial',
    'NumberOfUnits'
  ];

  inputs: any = {};

  constructor() {
    for (let key of this.keys) {
      this.inputs[key] = window[key];
    }
  }

  updateValue(key, value) {
    this.inputs[key] = value;
    window[key] = value;

    if (key === this.keys[10] || key === this.keys[11]) {
      this.inputs[this.keys[0]] = this.inputs[this.keys[10]] / this.inputs[this.keys[11]];
      window[this.keys[0]] = this.inputs[this.keys[0]];
    }

    (window as any).recalcArrayTreatmentCost();
  }

  ngOnInit() {
  }

}
