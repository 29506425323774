import { Component, OnInit } from '@angular/core';
import {DataArray} from "../../../classes/data-array";
import {AppSettings} from "../../../app.settings";
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-clinical-inputs',
  templateUrl: './clinical-inputs.component.html',
  styleUrls: ['./clinical-inputs.component.css']
})
export class ClinicalInputsComponent implements OnInit {
  private years = [
    'Año 1',
    'Año 2',
    'Año 3',
    'Año 4',
    'Año 5',
    'Año 6',
    'Año 7',
    'Año 8',
    'Año 9',
    'Año 10',
    'Año 11',
    'Año 12',
    'Año 13',
    'Año 14',
    'Año 15'
  ];

  riskAS_labels: DataArray = new DataArray('Año', this.years, AppSettings.LABEL, 'riskAS_labels');
  riskAS: DataArray = new DataArray('Riesgo', [], AppSettings.PERCENT, 'riskAS');

  riskDI_labels: DataArray = new DataArray('Año', this.years, AppSettings.LABEL, 'riskDI_labels');
  riskDI: DataArray = new DataArray('Riesgo', [], AppSettings.PERCENT, 'riskDI');

  keys = {
    clinical_decrease_annual_bleeds: 'clinical_decrease_annual_bleeds',
    clinical_decrease_joint_bleeds_surgery: 'clinical_decrease_joint_bleeds_surgery',
    bleeds_per_surgery: 'bleeds_per_surgery',
    clinical_LOS_uncomplicated: 'clinical_LOS_uncomplicated',
    clinical_LOS_tkr: 'clinical_LOS_tkr',
    clinical_LOS_debridement: 'clinical_LOS_debridement',
    clinical_LOS_replacement: 'clinical_LOS_replacement',

    PS_TKR: 'PS_TKR',
    LOS_TKR: 'LOS_TKR',

    PS_RS: 'PS_RS',
    LOS_RS: 'LOS_RS',

    PS_D: 'PS_D',
    LOS_D: 'LOS_D',

    PS_TSRS: 'PS_TSRS',
    LOS_TSRS: 'LOS_TSRS',

    IA_Dose: 'IA_Dose',
    OA_Dose: 'OA_Dose',
    OA_Dose2: 'OA_Dose2',

    IA_Days: 'IA_Days',
    OA_Days: 'OA_Days',
    OA_Days2: 'OA_Days2',

    clinical_LOS_replacement2_1: 'clinical_LOS_replacement2_1',
    clinical_LOS_replacement2_2: 'clinical_LOS_replacement2_2',
  };

  clinical_decrease_annual_bleeds: number;
  clinical_decrease_joint_bleeds_surgery: number;
  bleeds_per_surgery: number;

  clinical_LOS_uncomplicated: number;
  clinical_LOS_tkr: number;

  clinical_LOS_debridement: number;
  clinical_LOS_replacement: number;

  PS_TKR: number;
  LOS_TKR: number;

  PS_RS: number;
  LOS_RS: number;

  PS_D: number;
  LOS_D: number;

  PS_TSRS: number;
  LOS_TSRS: number;

  IA_Dose: number;
  OA_Dose: number;
  OA_Dose2: number;

  IA_Days: number;
  OA_Days: number;
  OA_Days2: number;

  clinical_LOS_replacement2_1: number;
  clinical_LOS_replacement2_2: number;

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    for (let item of js_window['riskAS']) {
      this.riskAS.appendData(item['AS']);
    }
    for (let item of js_window['riskDI']) {
      this.riskDI.appendData(item['DI']);
    }

    for (let key in this.keys) {
      this[key] = js_window[key];
    }
  }

  ngOnInit() {
  }

  /* event_data = {data_array_id: DataArray object, row: int} */
  updateRow(event_data) {
    let js_window = this.ws.nativeWindow;

    if (event_data.data_array_id == 'riskAS') {
      js_window['riskAS'][event_data.row]['AS'] = this.riskAS.data[event_data.row];
    }
    else if (event_data.data_array_id == 'riskDI') {
      js_window['riskDI'][event_data.row]['DI'] = this.riskDI.data[event_data.row];
    }
  }

  updateValue(key, value) {
    let js_window = this.ws.nativeWindow;
    this[key] = value;
    js_window[key] = this[key];
  }
}
