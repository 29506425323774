export interface IFormulaciones {
  iDegLira: any;
  iGlarLixi?: any;
  iglarAspart?: any;
  lantus?: any;
  aspart?: any;
}

export interface XulthophyParams {
  estudio?: IFormulaciones;
  poblacion: IFormulaciones;
  tiempo: IFormulaciones;
  mujeres?: IFormulaciones;
  hombres?: IFormulaciones;
  edad: IFormulaciones;
  imc: IFormulaciones;
  duracionDMT2: IFormulaciones;
  hb1acPrevio: IFormulaciones;
  hb1acFinalizar: IFormulaciones;
}

export interface XulthophyCosts {
  dosisDiaria: IFormulaciones;
  dosisMensual: IFormulaciones;
  valorLapicero: IFormulaciones;
  unidadesUILapicero: IFormulaciones;
  valorInsulina: IFormulaciones;
  gastoMensual: IFormulaciones;
  gastoAnual: IFormulaciones;
}

export function runCosts(costs: XulthophyCosts): XulthophyCosts {
  costs.dosisMensual = {
    iDegLira: costs.dosisDiaria.iDegLira * 30,
    iGlarLixi: costs.dosisDiaria.iGlarLixi ? costs.dosisDiaria.iGlarLixi * 30 : 0,
    iglarAspart: costs.dosisDiaria.iglarAspart ? costs.dosisDiaria.iglarAspart * 30 : 0,
    lantus: costs.dosisDiaria.lantus ? costs.dosisDiaria.lantus * 30 : 0,
    aspart: costs.dosisDiaria.aspart ? costs.dosisDiaria.aspart * 30 : 0
  };
  costs.valorInsulina = {
    iDegLira: costs.valorLapicero.iDegLira / costs.unidadesUILapicero.iDegLira,
    iGlarLixi: costs.valorLapicero.iGlarLixi ? costs.valorLapicero.iGlarLixi / costs.unidadesUILapicero.iGlarLixi : 0,
    iglarAspart: costs.valorLapicero.iglarAspart ? costs.valorLapicero.iglarAspart / costs.unidadesUILapicero.iglarAspart : 0,
    lantus: costs.valorLapicero.lantus ? costs.valorLapicero.lantus / costs.unidadesUILapicero.lantus : 0,
    aspart: costs.valorLapicero.aspart ? costs.valorLapicero.aspart / costs.unidadesUILapicero.aspart : 0,
  };
  costs.gastoMensual = {
    iDegLira: costs.dosisMensual.iDegLira * costs.valorInsulina.iDegLira,
    iGlarLixi: costs.dosisMensual.iGlarLixi * costs.valorInsulina.iGlarLixi,
    iglarAspart: costs.dosisMensual.iglarAspart * costs.valorInsulina.iglarAspart,
    lantus: costs.dosisMensual.lantus * costs.valorInsulina.lantus,
    aspart: costs.dosisMensual.aspart * costs.valorInsulina.aspart,
  };
  costs.gastoAnual = {
    iDegLira: costs.gastoMensual.iDegLira * 12,
    iGlarLixi: costs.gastoMensual.iGlarLixi * 12,
    iglarAspart: costs.gastoMensual.iglarAspart * 12,
    lantus: costs.gastoMensual.lantus * 12,
    aspart: costs.gastoMensual.aspart * 12,
  };
  return costs;
}

export class XulthophyScenario {
  title: string;
  personas: number;
  costs: XulthophyCosts;

  constructor(title: string, costs: XulthophyCosts, personas: number) {
    this.title = title;
    this.costs = costs;
    this.personas = personas;
  }
}

export interface IMeta {
  title?: string;
  personasLograronMeta: IFormulaciones;
  personasNoLograronMeta: IFormulaciones;
  dap: IFormulaciones;
  deltaDap: number;
  costoRelativo: number;
}

export interface ICostoTratamiento {
  costoTratamientoAnual: IFormulaciones;
  deltaCostoTratamientoAnual: number;
}


// tslint:disable-next-line:max-line-length
export function getMeta(personas: number, tasaExito: IFormulaciones, costoTratamientoAnual: IFormulaciones, scenario: number, titulo: string): IMeta {

  const lograronMeta: IFormulaciones = {
    iDegLira: personas * tasaExito.iDegLira / 100,
    iGlarLixi: tasaExito.iGlarLixi ? personas * tasaExito.iGlarLixi / 100 : null,
    iglarAspart: tasaExito.iglarAspart ? personas * tasaExito.iglarAspart / 100 : null
  };

  const localDap: IFormulaciones = {
    iDegLira: costoTratamientoAnual.iDegLira / lograronMeta.iDegLira * personas,
    iGlarLixi: lograronMeta.iGlarLixi ? costoTratamientoAnual.iGlarLixi / lograronMeta.iGlarLixi * personas : null,
    iglarAspart: lograronMeta.iglarAspart ? costoTratamientoAnual.iglarAspart / lograronMeta.iglarAspart * personas : null
  };

  return {
    title: titulo,
    personasLograronMeta: lograronMeta,
    personasNoLograronMeta: {
      iDegLira: personas * (100 - tasaExito.iDegLira) / 100,
      iGlarLixi: tasaExito.iGlarLixi ? personas * (100 - tasaExito.iGlarLixi) / 100 : null,
      iglarAspart: tasaExito.iglarAspart ? personas * (100 - tasaExito.iglarAspart) / 100 : null
    },
    dap: localDap,
    deltaDap: (scenario === 1 || scenario === 2) ? localDap.iDegLira - localDap.iGlarLixi : localDap.iDegLira - localDap.iglarAspart,
    costoRelativo: (scenario === 1 || scenario === 2) ? localDap.iGlarLixi / localDap.iDegLira : localDap.iglarAspart / localDap.iDegLira
  };
}


// SUMINISTROS
export interface ISuministros {
  iDegLira: number;
  esquemaConvencional: number;
  esquemaAjustado: number;
}

export interface ISuministrosGroup {
  tirillas: ISuministros;
  lancetas: ISuministros;
  agujas: ISuministros;
}

export interface XulthophySuministros {
  uso: ISuministrosGroup;
  adicionales: ISuministrosGroup;
  costoAnual: ISuministrosGroup;
}
