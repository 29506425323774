import {Component, OnDestroy, OnInit} from '@angular/core';
import {WindowService} from "../../../services/window.service";
import {AppSettings} from "../../../app.settings";

declare const Chart: any;

@Component({
  selector: 'app-tornado',
  templateUrl: './tornado.component.html',
  styleUrls: ['./tornado.component.css']
})
export class TornadoComponent implements OnInit, OnDestroy {

  public tornadoChartType: string = 'horizontalBar';
  public tornadoDataSets: Array<any> = [
    {data: [], label: 'Min value'},
    {data: [], label: 'Max value'}
  ];

  public tornadoLabels: Array<string> = [];
  public tornadoLegend: boolean = true;
  public tornadoOptions: any;

  public tornado_center: number;
  public array_tornado: any;

  private chart_inst: any;

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    js_window.runOnDemand();

    this.array_tornado = js_window['arraytornado'];

    this.tornado_center = js_window['total_bi']['total_bi']['over_duration_budget_impact']['Total_Cost'];

    for (let item of this.array_tornado) {
      this.tornadoDataSets[0].data.push(item.minBi - this.tornado_center);
      this.tornadoDataSets[1].data.push(item.maxBi - this.tornado_center);
      this.tornadoLabels.push(item.name);
    }

    let context = this;

    this.tornadoOptions = {
      showDatapointsTornadoBI: true,
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            min: -194000000,
            max: 194000000,
            callback: function(value, index, values) {
              return (value + context.tornado_center).toFixed();
            }
          }
        }],
        yAxes: [{
          barThickness: 20,
          stacked: true
        }]
      }
    };
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
