import { Injectable } from '@angular/core';
import Params from './../data/params.json';
import { Scenario1 } from '../models/scenario1';
import { Scenario2 } from '../models/scenario2';
import { Scenario3 } from '../models/scenario3';
import {Scenario, XultophyData} from '../models/scenario.class';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public activeTab = [1, 1, 1];
  public scenario1: Scenario1;
  public scenario2: Scenario2;
  public scenario3: Scenario3;

  public dbLoaded: Subject<void> = new Subject<void>();

  private dbScenario: Scenario;

  constructor() {
    this.scenario1 = new Scenario1(Params.escenario1.params, Params.escenario1.title, Params.escenario1.costs, Params.escenario1.personas);
    this.scenario2 = new Scenario2(Params.escenario2.params, Params.escenario2.title, Params.escenario2.costs, Params.escenario2.personas);
    this.scenario3 = new Scenario3(Params.escenario3.params, Params.escenario3.title, Params.escenario3.costs, Params.escenario3.personas, Params.escenario3.suministros);

    this.dbScenario = this.saveScenario('Valores por defecto');
  }

  loadScenario(sc: Scenario) {
    this.dbScenario = sc;

    this.scenario1.personas = sc.data.scenario1.personas;
    this.scenario1.params.dosisInsulinaFinalizar.iDegLira = sc.data.scenario1.dosisIdegLira;
    this.scenario1.params.dosisInsulinaFinalizar.iGlarLixi = sc.data.scenario1.dosisIglarLixi;
    this.scenario1.costs.valorLapicero.iDegLira = sc.data.scenario1.costoIdeglira;
    this.scenario1.costs.valorLapicero.iGlarLixi = sc.data.scenario1.costoIglarLixi;

    this.scenario2.personas = sc.data.scenario1.personas;
    this.scenario2.params.dosisInsulinaPromedio.iDegLira = sc.data.scenario2.dosisIdegLira;
    this.scenario2.params.dosisInsulinaPromedio.iGlarLixi = sc.data.scenario2.dosisIglarLixi;
    this.scenario2.costs.valorLapicero.iDegLira = sc.data.scenario2.costoIdeglira;
    this.scenario2.costs.valorLapicero.iGlarLixi = sc.data.scenario2.costoIglarLixi;

    this.scenario3.personas = sc.data.scenario1.personas;
    this.scenario3.params.dosisInsulinaFinalPromedio.iDegLira = sc.data.scenario3.dosisIdegLira;
    this.scenario3.params.dosisInsulinaFinalPromedio.iglarAspart.basal = sc.data.scenario3.dosisIglarAspartBolo;
    this.scenario3.params.dosisInsulinaFinalPromedio.iglarAspart.bolo = sc.data.scenario3.dosisIglarAspartBasal;
    this.scenario3.costs.valorLapicero.iDegLira = sc.data.scenario3.costoIdeglira;
    this.scenario3.costs.valorLapicero.lantus = sc.data.scenario3.costoLantus;

    this.dbLoaded.next();
  }

  saveScenario(name: string) {
    const data: XultophyData = {
      scenario1: {
        personas: this.scenario1.personas,
        dosisIdegLira: this.scenario1.params.dosisInsulinaFinalizar.iDegLira,
        dosisIglarLixi: this.scenario1.params.dosisInsulinaFinalizar.iGlarLixi,
        costoIdeglira: this.scenario1.costs.valorLapicero.iDegLira,
        costoIglarLixi: this.scenario1.costs.valorLapicero.iGlarLixi
      },
      scenario2: {
        personas: this.scenario2.personas,
        dosisIdegLira: this.scenario2.params.dosisInsulinaPromedio.iDegLira,
        dosisIglarLixi: this.scenario2.params.dosisInsulinaPromedio.iGlarLixi,
        costoIdeglira: this.scenario2.costs.valorLapicero.iDegLira,
        costoIglarLixi: this.scenario2.costs.valorLapicero.iGlarLixi
      },
      scenario3: {
        personas: this.scenario3.personas,
        dosisIdegLira: this.scenario3.params.dosisInsulinaFinalPromedio.iDegLira,
        dosisIglarAspartBasal: this.scenario3.params.dosisInsulinaFinalPromedio.iglarAspart.basal,
        dosisIglarAspartBolo: this.scenario3.params.dosisInsulinaFinalPromedio.iglarAspart.bolo,
        costoIdeglira: this.scenario3.costs.valorLapicero.iDegLira,
        costoLantus: this.scenario3.costs.valorLapicero.lantus,
        costoAspart: this.scenario3.costs.valorLapicero.aspart
      }
    };

    this.dbScenario = new Scenario({
      id: this.dbScenario ? this.dbScenario.id : null,
      name: name || this.dbScenario.name,
      editable: !!this.dbScenario,
      data
    });
    return this.dbScenario;
  }
}
