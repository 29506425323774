import { Component, OnInit } from '@angular/core';
import {AppSettings} from "../../app.settings";

@Component({
  selector: 'app-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrls: ['./on-demand.component.css']
})
export class OnDemandComponent implements OnInit {

  calculator_id = AppSettings.ON_DEMAND_ID;

  constructor() { }

  ngOnInit() {
  }

}
