import {Chart, ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {formatNumber} from '@angular/common';

export abstract class BaseChartClass {
  public options: ChartOptions;
  public datasets: ChartDataSets[];
  public values: number[];
  public labels: Label[];
  public plugins: any[];
  public colors: Color[];
  public abstract chartType: ChartType;

  protected constructor() {
    this.datasets = [];
    this.values = [];
    this.labels = [];
    this.plugins = [];
    this.colors = [];

    this.options = {
      responsive: true,
      legend: {
        position: 'bottom',
      }
    };
  }
}

export class PieChart extends BaseChartClass {
  chartType: ChartType = 'pie';

  constructor(params: PieChartDataParams) {
    super();
    this.values = params.values || [];
    this.labels = params.labels || [];
    this.colors = params.colors || [];
    this.options.legend = {
      display: false,
      position: 'right'
    };
    this.options.scales = {
      xAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    };
    /*this.options.scales = {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    };*/
  }
}

export class BarChart extends BaseChartClass {
  chartType: ChartType = 'bar';
  options: ChartOptions;

  constructor(params: BarChartDataParams) {
    super();
    this.datasets = params.datasets;
    this.labels = params.labels;
    this.colors = params.colors || [];

    let formatter = params.formatter;
    if (!formatter) {
      formatter = (value) => {
        return Math.round(value).toString();
      };
    }

    if (!params.horizontal) {
      for (const dataset of params.datasets) {
        (dataset as any).barPercentage = 0.4;
      }
    }

    if (params.horizontal) {
      this.chartType = 'horizontalBar';
    }
    this.plugins.push({
      afterDatasetsDraw(chart, options) {
        const chartInstance = chart;
        const ctx = chartInstance.ctx;
        ctx.font = Chart.helpers.fontString(
          16,
          Chart.defaults.global.defaultFontStyle,
          Chart.defaults.global.defaultFontFamily,
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = 'black';

        const xLimit = chartInstance.width - 200;

        chart.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = Math.round(dataset.data[index]);
            if (params.horizontal) {
              const x = Math.min(bar._model.x + 60, xLimit);
              if (x === xLimit || data > 0) {
                ctx.fillStyle = '#000000';
                ctx.strokeStyle = '#000000';
              } else {
                ctx.fillStyle = '#ffffff';
                ctx.strokeStyle = '#ffffff';
              }
              ctx.fillText(formatter(data), x, bar._model.y + 9);
            } else {
              ctx.fillText(formatter(data), bar._model.x, bar._model.y + 20);
            }
          });
        });
      }
    });

    this.options.maintainAspectRatio = false;
    this.options.tooltips = {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          return `${label}${formatter(+tooltipItem.value)}`;
        }
      }
    };
    this.options.scales = {
      xAxes: [{
        stacked: true,
        display: true,
        ticks: {
          beginAtZero: true,
        }
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
        }
      }]
    };
    if (params.horizontal) {
      this.options.scales.xAxes[0].ticks.callback = formatter;
    } else {
      this.options.scales.yAxes[0].ticks.callback = formatter;
    }
  }
}

export class TornadoChart extends BaseChartClass {
  chartType: ChartType = 'horizontalBar';

  constructor(params: BarChartDataParams) {
    super();
    this.datasets = params.datasets;
    this.labels = params.labels;
    this.colors = params.colors || [];
    this.datasets.forEach((dataset, index) => {
      if (index % 2 === 1) {
        for (let i = 0; i < dataset.data.length; i++) {
          dataset.data[i] = +dataset.data[i] * -1;
        }
      }
    });

    this.options.scales = {
      yAxes: [{
        stacked: true
      }],
      xAxes: [{
        ticks: {
          beginAtZero: true,
          callback(value: number): string {
            return formatNumber(Math.abs(value), 'en', '0.0-0');
          },
        }
      }]
    };
  }
}

export class StackedBarChart extends BaseChartClass {
  chartType: ChartType = 'bar';

  constructor(params: StackedBarChartParams) {
    super();
    this.datasets = params.datasets || [];
    this.labels = params.labels || [];
    if (params.horizontal) {
      this.chartType = 'horizontalBar';
    }
    this.options.scales = {
      xAxes: [{
        stacked: true,
        display: true,
        ticks: {
          beginAtZero: true,
          callback: (value: number) => `${Math.round(value * 100)} %`,
        }
      }],
      yAxes: [{
        stacked: true
      }]
    };
  }
}

export interface PieChartDataParams {
  values: number[];
  labels: Label[];
  colors?: Color[];
}

export interface BarChartDataParams {
  datasets: ChartDataSets[];
  labels: Label[];
  colors?: Color[];
  horizontal?: boolean;

  formatter?(value): string;
}

export interface StackedBarChartParams {
  labels: Label[];
  datasets: ChartDataSets[];
  horizontal?: boolean;
}
