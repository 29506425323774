import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-cumulative-costs',
  templateUrl: './cumulative-costs.component.html',
  styleUrls: ['./cumulative-costs.component.css']
})
export class CumulativeCostsComponent implements OnInit {

  public lineChartData:Array<any> = [
    {data: [], label: 'Con cirugía'},
    {data: [], label: 'Sin cirugía'},
  ];
  public lineChartLabels:Array<any> = [];
  public lineChartOptions:any = {
    responsive: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Año'
        }
      }]
    }
  };
  public lineChartColors:Array<any> = [
    {
      backgroundColor: 'rgba(10,20,185,0.2)',
      borderColor: 'rgba(10,20,185,1)',
      pointBackgroundColor: 'rgba(10,20,185,1)',
      pointBorderColor: '#bbf',
      pointHoverBackgroundColor: '#bbf',
      pointHoverBorderColor: 'rgba(10,20,185,1)'
    },
    {
      backgroundColor: 'rgba(185,20,10,0.2)',
      borderColor: 'rgba(185,20,10,1)',
      pointBackgroundColor: 'rgba(185,20,10,1)',
      pointBorderColor: '#fbb',
      pointHoverBackgroundColor: '#fbb',
      pointHoverBorderColor: 'rgba(185,20,10,1)'
    }
  ];
  public lineChartLegend:boolean = true;
  public lineChartType:string = 'line';

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    js_window.runSurgery();

    for (let i = 0, year = js_window['base_year']; i < js_window['sCosts'].length; i++, year++) {
      this.lineChartData[0].data.push(js_window['sCosts'][i]['cCosts']);
      this.lineChartData[1].data.push(js_window['nsCosts'][i]['cCosts']);
      this.lineChartLabels.push(year);
    }
  }

  ngOnInit() {
  }
}
