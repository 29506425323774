import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { Resolve } from '@angular/router';
import { Scenario } from '../models/scenario.class';
import { MODEL } from '../../semaglutida/models/factory';
import { DEFAULT } from '../../semaglutida/database/database.const';
import Content from '../../ozempic-ccr/data/content.json';

@Injectable({
  providedIn: 'root'
})


export class ApiService implements Resolve<any> {

  scenario: Scenario;
  public app: Calculadora;
  public metaIndex: any;
  content: {
    intro: string,
    goals: Array<string>,
    description: string,
    source: string
  };

  constructor(private db: DatabaseService) {
    this.metaIndex = {
      meta_1: 1,
      meta_2: 2,
      meta_3: 3
    };
    this.content = Content;
  }

  resolve(): Promise<any> {
    return new Promise<any>(resolve => {
      if (this.app) {
        resolve();
      } else {
        this.db
          .getObjectByIndexValue('Scenario', MODEL.Scenario, 'name', DEFAULT.name)
          .then((sc: Scenario) => {
            this.scenario = sc;
            this.app = new Calculadora(sc);
            resolve();
          });
      }
    });
  }

  loadScenario(sc: Scenario) {
    this.scenario = sc;
    this.app = new Calculadora(sc);
  }

  getCopyCurrent(name: string): Scenario {
    const sc = new Scenario({
      data: {
        numero_pacientes: this.app.pacientes,
        metas: this.app.metas,
        costo_por_paciente_mes: this.app.costoPacientesMes,
        annual_base: this.app.annualBase,
      }, editable: true, name, id: null
    });

    return this.db.factoryInstance.create(MODEL.Scenario, JSON.parse(JSON.stringify(sc)));
  }
}

export class Calculadora {
  pacientes: number;
  public metas: Array<ITests>;
  costoPacientesMes: ITests;
  annualBase: ITests;

  constructor(sc: Scenario) {
    this.pacientes = sc.data.numero_pacientes;
    this.costoPacientesMes = sc.data.costo_por_paciente_mes;
    this.metas = sc.data.metas;
    this.annualBase = sc.data.annual_base;
  }

  costoPacientesAnual(): ITests {
    return {
      s05: this.costoPacientesMes.s05 * 12 + this.annualBase.s05,
      s1: this.costoPacientesMes.s1 * 12 + this.annualBase.s1,
      l18: this.costoPacientesMes.l18 * 12 + this.annualBase.l18
    };
  }

  costoCohorteMes(): ITests {
    return {
      s05: 0,
      s1: 0,
      l18: 0
    };
  }

  costoCohorteAnual(): ITests {
    return {
      s05: this.costoPacientesAnual().s05 * this.pacientes,
      s1: this.costoPacientesAnual().s1 * this.pacientes,
      l18: this.costoPacientesAnual().l18 * this.pacientes
    };
  }

  getMetaResults(metaIndex: number): IMetaResults {
    const ccr: ITests = {
      s05: this.costoCohorteAnual().s05 / (this.metas[metaIndex].s05 * this.pacientes / 100),
      s1: this.costoCohorteAnual().s1 / (this.metas[metaIndex].s1 * this.pacientes / 100),
      l18: this.costoCohorteAnual().l18 / (this.metas[metaIndex].l18 * this.pacientes / 100)
    };
    return {
      personasLograronMeta: {
        s05: (this.metas[metaIndex].s05 / 100) * this.pacientes,
        s1: (this.metas[metaIndex].s1 / 100) * this.pacientes,
        l18: (this.metas[metaIndex].l18 / 100) * this.pacientes
      },
      personasNoLograronMeta: {
        s05: (1 - this.metas[metaIndex].s05 / 100) * this.pacientes,
        s1: (1 - this.metas[metaIndex].s1 / 100) * this.pacientes,
        l18: (1 - this.metas[metaIndex].l18 / 100) * this.pacientes
      },
      costoTratamientoAnual: {
        pacientes: {
          s05: this.costoCohorteAnual().s05,
          s1: this.costoCohorteAnual().s1,
          l18: this.costoCohorteAnual().l18
        },
        diferencia: {
          s05: 0,
          s1: this.costoCohorteAnual().s1 - this.costoCohorteAnual().s05,
          l18: this.costoCohorteAnual().l18 - this.costoCohorteAnual().s05
        }
      },
      CostoControlRelativo: {
        ccr: {
          s05: ccr.s05,
          s1: ccr.s1,
          l18: ccr.l18,
        },
        diferenciaCcr: {
          s05: 0,
          s1: ccr.s1 - ccr.s05,
          l18: ccr.l18 - ccr.s05
        },
        ccrAdicionalCohorte: {
          s05: 0,
          s1: (ccr.s1 - ccr.s05) * this.pacientes,
          l18: (ccr.l18 - ccr.s05) * this.pacientes
        },
        costoRelativo: {
          s05: 1,
          s1: ccr.s1 / ccr.s05,
          l18: ccr.l18 / ccr.s05
        }
      }
    };
  }

}

export interface ITests {
  variable?: string;
  s05: number;
  s1: number;
  l18: number;
}

export interface IMetaResults {
  personasLograronMeta: ITests;
  personasNoLograronMeta: ITests;
  costoTratamientoAnual: {
    pacientes: ITests;
    diferencia: ITests;
  };
  CostoControlRelativo: {
    ccr: ITests;
    diferenciaCcr: ITests;
    ccrAdicionalCohorte: ITests;
    costoRelativo: ITests;
  };
}
