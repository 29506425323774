import {Injectable} from '@angular/core';
import {SemaDataService} from './sema-data.service';
import {getYearlyCosts, variableValuePSA} from '../global/globalFunctions';
import {IMedsData} from '../models/scenario.class';

@Injectable({
    providedIn: 'root'
})
export class RunPsaService {
    running = false;
    calc = false;

    psaArrayData: PSADataArray = {
        HbA1c_lte_6_5: {
            semaglutida_1: {
                avg: 0, std: 0
            },
            semaglutida_0_5: {
                avg: 0, std: 0
            },
            dulaglutida_1_5: {
                avg: 0, std: 0
            }
        },
        HbA1c_lte_7: {
            semaglutida_1: {
                avg: 0, std: 0
            },
            semaglutida_0_5: {
                avg: 0, std: 0
            },
            dulaglutida_1_5: {
                avg: 0, std: 0
            }
        },
        HbA1c_lte_7_no_hipoglicemia: {
            semaglutida_1: {
                avg: 0, std: 0
            },
            semaglutida_0_5: {
                avg: 0, std: 0
            },
            dulaglutida_1_5: {
                avg: 0, std: 0
            }
        },
        weight_loss_gte_5: {
            semaglutida_1: {
                avg: 0, std: 0
            },
            semaglutida_0_5: {
                avg: 0, std: 0
            },
            dulaglutida_1_5: {
                avg: 0, std: 0
            }
        },
        weight_loss_gte_10: {
            semaglutida_1: {
                avg: 0, std: 0
            },
            semaglutida_0_5: {
                avg: 0, std: 0
            },
            dulaglutida_1_5: {
                avg: 0, std: 0
            }
        },
        HbA1c_reduction: {
            semaglutida_1: {
                avg: 0, std: 0
            },
            semaglutida_0_5: {
                avg: 0, std: 0
            },
            dulaglutida_1_5: {
                avg: 0, std: 0
            }
        },
    };

    constructor(private data: SemaDataService) {}

    runPSA() {
        this.running = true;
        const effectiveness = this.data.inputs.effectiveness;
        const costs = getYearlyCosts(this.data.inputs, this.data.params);

        for (const key of Object.keys(this.psaArrayData)) {
            const percent: IMedsData = effectiveness[key];

            const sema05Values = [];
            const sema1Values = [];
            const dula15Values = [];

            const dta: IMedsData = this.psaArrayData[key];

            dta.semaglutida_0_5.avg = this.getPSAValues(
                percent.semaglutida_0_5,
                this.data.inputs.n.semaglutida_0_5,
                sema05Values,
                costs.totals[0]
            );
            dta.semaglutida_1.avg = this.getPSAValues(
                percent.semaglutida_1,
                this.data.inputs.n.semaglutida_1,
                sema1Values,
                costs.totals[1]
            );
            dta.dulaglutida_1_5.avg = this.getPSAValues(
                percent.dulaglutida_1_5,
                this.data.inputs.n.dulaglutida_1_5,
                dula15Values,
                costs.totals[2]
            );

            dta.semaglutida_0_5.std = this.getStdev(dta.semaglutida_0_5.avg, sema05Values);
            dta.semaglutida_1.std = this.getStdev(dta.semaglutida_1.avg, sema1Values);
            dta.dulaglutida_1_5.std = this.getStdev(dta.dulaglutida_1_5.avg, dula15Values);
        }

        this.running = false;
        this.calc = true;
    }

    private getPSAValues(effectiveness, n, arrayToFill, baseValue) {
        let avg = 0;

        for (let i = 0; i < this.data.params.psa; i++) {
            const num = variableValuePSA(effectiveness, n);
            avg += baseValue / num;
            arrayToFill.push(baseValue / num);
        }

        return avg / arrayToFill.length;
    }

    private getStdev(avg, array: Array<any>) {
        let total = 0;

        for (const num of array) {
            total += Math.pow(num - avg, 2);
        }
        return Math.sqrt(total / (array.length - 1));
    }

    getConfidenceIntervals(avg, std) {
        const z = 1.960; // 95% Confianza;
        const n = this.data.params.psa - 1;
        const f = z * (std / Math.sqrt(n));

        return [avg - f, avg + f];
    }
}


interface PSADataArray {
    HbA1c_lte_6_5: IMedsData;
    HbA1c_lte_7: IMedsData;
    HbA1c_lte_7_no_hipoglicemia: IMedsData;
    weight_loss_gte_5: IMedsData;
    weight_loss_gte_10: IMedsData;
    HbA1c_reduction: IMedsData;
}
