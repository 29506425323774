import {Component} from '@angular/core';
import {SemaDataService} from '../../services/sema-data.service';
import {IEffectiveness} from '../../models/scenario.class';
import {RunPsaService} from '../../services/run-psa.service';

@Component({
    selector: 'app-probabilistic',
    templateUrl: 'probabilistic.component.html',
    styleUrls: ['../../theme.scss']
})
export class ProbabilisticComponent {
    effectiveness: IEffectiveness;
    private map: any;
    labels = [];

    tableData = [
        {
            title: 'Semaglutida 0.5 mg una vez a la semana',
            data: []
        },
        {
            title: 'Semaglutida 1 mg una vez a la semana',
            data: []
        },
        {
            title: 'Dulaglutida 1.5mg',
            data: []
        },
    ];

    variation = .45;

    constructor(private data: SemaDataService, public psa: RunPsaService) {
        this.effectiveness = data.inputs.effectiveness;
        this.map = data.getEffectivenessMap();

        if (!psa.calc) {
            psa.runPSA();
        }

        this.calcProbabilistic();
    }

    isNumber(n) {
        return typeof n === 'number';
    }

    public calcPSA() {
        this.psa.runPSA();
        this.calcProbabilistic();
    }

    private calcProbabilistic() {
        this.labels = [];
        for (const td of this.tableData) {
            td.data = [];
        }

        for (const item of this.map) {
            this.labels.push(item.label);

            const avgSem05 = this.psa.psaArrayData[item.key].semaglutida_0_5.avg;
            const avgSem1 = this.psa.psaArrayData[item.key].semaglutida_1.avg;
            const avgDul15 = this.psa.psaArrayData[item.key].dulaglutida_1_5.avg;

            const stdevSem05 = this.psa.psaArrayData[item.key].semaglutida_0_5.std;
            const stdevSem1 = this.psa.psaArrayData[item.key].semaglutida_1.std;
            const stdevDul15 = this.psa.psaArrayData[item.key].dulaglutida_1_5.std;

            const intervalSem05 = this.psa.getConfidenceIntervals(avgSem05, stdevSem05);
            const intervalSem1 = this.psa.getConfidenceIntervals(avgSem1, stdevSem1);
            const intervalDul15 = this.psa.getConfidenceIntervals(avgDul15, stdevDul15);

            this.tableData[0].data.push([avgSem05, stdevSem05, intervalSem05]);
            this.tableData[1].data.push([avgSem1, stdevSem1, intervalSem1]);
            this.tableData[2].data.push([avgDul15, stdevDul15, intervalDul15]);
        }
    }
}
