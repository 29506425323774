import {Injectable} from '@angular/core';
import {DatabaseManager} from '../../calculator-globals/database/database-manager';
import {DATABASE, DEFAULT} from '../database/database.const';
import {MODEL, XultophyFactory} from '../database/factory.class';
import {IXultophyScenario} from '../models/scenario.class';
import Params from './../data/params.json';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends DatabaseManager {
  protected dbData = DATABASE;
  protected dbDefaults = DEFAULT;
  protected factory = new XultophyFactory();
  protected scenarioDefaultModel = MODEL.Scenario;
  protected scenarioDefaultParams: IXultophyScenario = {
    name: DEFAULT.name,
    data: {
      scenario1: {
        personas: Params.escenario1.personas,
        dosisIdegLira: Params.escenario1.params.dosisInsulinaFinalizar.iDegLira,
        dosisIglarLixi: Params.escenario1.params.dosisInsulinaFinalizar.iGlarLixi,
        costoIdeglira: Params.escenario1.costs.valorLapicero.iDegLira,
        costoIglarLixi: Params.escenario1.costs.valorLapicero.iGlarLixi
      },
      scenario2: {
        personas: Params.escenario2.personas,
        dosisIdegLira: Params.escenario2.params.dosisInsulinaPromedio.iDegLira,
        dosisIglarLixi: Params.escenario2.params.dosisInsulinaPromedio.iGlarLixi,
        costoIdeglira: Params.escenario2.costs.valorLapicero.iDegLira,
        costoIglarLixi: Params.escenario2.costs.valorLapicero.iGlarLixi
      },
      scenario3: {
        personas: Params.escenario3.personas,
        dosisIdegLira: Params.escenario3.params.dosisInsulinaFinalPromedio.iDegLira,
        dosisIglarAspartBasal: Params.escenario3.params.dosisInsulinaFinalPromedio.iglarAspart.basal,
        dosisIglarAspartBolo: Params.escenario3.params.dosisInsulinaFinalPromedio.iglarAspart.bolo,
        costoIdeglira: Params.escenario3.costs.valorLapicero.iDegLira,
        costoAspart: Params.escenario3.costs.valorLapicero.aspart,
        costoLantus: Params.escenario3.costs.valorLapicero.lantus
      }
    }
  };
}
