import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debie-population',
  templateUrl: './debie-population.component.html',
  styleUrls: ['./debie-population.component.css']
})
export class DebiePopulationComponent implements OnInit {

  map: Array<string> = [
    'type_one_diabetes_epidemiology',
    'type_two_diabetes_epidemiology'
  ];

  /*
  type_one_diabetes_epidemiology = [
     "baseline_prevalence"
     "annual_incidence"
     "annual_mortality"
     "basal_bolus_therapy"
   ];

   type_two_diabetes_epidemiology = [
     "baseline_prevalence"
     "annual_incidence"
     "annual_mortality"
     "basal_oral_therapy"
     "basal_bolus_therapy"
   ];
   */

  model: any = {};

  constructor() {
    for (let key of this.map) {
      this.model[key] = window[key];
    }
  }

  ngOnInit() {
  }

  updateValue(key, value) {

  }

}
