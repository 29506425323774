import { Component, OnInit } from '@angular/core';
import {AppSettings} from "../../app.settings";

@Component({
  selector: 'app-doses-costs',
  templateUrl: './doses-costs.component.html',
  styleUrls: ['./doses-costs.component.css']
})
export class DosesCostsComponent implements OnInit {

  calculator_id = AppSettings.DOSESCOSTS_ID;

  constructor() { }

  ngOnInit() {
  }

}
