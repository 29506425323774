import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { Scenario2 } from '../../models/scenario2';
import {BarChart} from '../../../calculator-globals/charts/models/charts';
import {fmtCop} from '../../../calculator-globals/utils';

@Component({
  selector: 'app-scenario2',
  templateUrl: './scenario2.component.html',
  styleUrls: ['../../theme.scss']
})
export class Scenario2Component implements OnInit {

  title = 'Escenario 2: Pacientes con análogos GLP-1 que requieren insulinización (Comparación indirecta)';

  chartDap: BarChart;
  chartIdegLira: BarChart;
  chartIglarLixi: BarChart;

  constructor(public api: ApiService) {

  }

  ngOnInit() {
    this.loadCharts();
  }

  loadCharts() {
    const metaConvencional = this.api.scenario2.getMetaConvencional();
    const metaEstricta = this.api.scenario2.getMetaEstricta();
    const metaLabels = [
      metaConvencional.title,
      metaEstricta.title,
    ];
    this.chartDap = new BarChart({
      horizontal: true,
      labels: metaLabels,
      datasets: [
        {
          data: [metaConvencional.deltaDap, metaEstricta.deltaDap],
          label: 'Δ DAP - Ahorro',
          backgroundColor: '#007DF7',
          hoverBackgroundColor: '#005BD5'
        }
      ],
      formatter(value): string {
        return fmtCop(value);
      }
    });

    this.chartIdegLira = new BarChart({
      labels: metaLabels,
      datasets: [
        {
          data: [
            metaConvencional.personasNoLograronMeta.iDegLira,
            metaEstricta.personasNoLograronMeta.iDegLira,
          ],
          label: 'IdegLira: Personas que no lograron la meta',
          backgroundColor: '#39B0C1',
          hoverBackgroundColor: '#1790A1'
        },
        {
          data: [
            metaConvencional.personasLograronMeta.iDegLira,
            metaEstricta.personasLograronMeta.iDegLira,
          ],
          label: 'IdegLira: Personas que lograron la meta',
          backgroundColor: '#03DBB1',
          hoverBackgroundColor: '#01B991'
        },
      ],
    });

    this.chartIglarLixi = new BarChart({
      labels: metaLabels,
      datasets: [
        {
          data: [
            metaConvencional.personasNoLograronMeta.iGlarLixi,
            metaEstricta.personasNoLograronMeta.iGlarLixi,
          ],
          label: 'IglarLixi: Personas que no lograron la meta',
          backgroundColor: '#39B0C1',
          hoverBackgroundColor: '#1790A1'
        },
        {
          data: [
            metaConvencional.personasLograronMeta.iGlarLixi,
            metaEstricta.personasLograronMeta.iGlarLixi,
          ],
          label: 'IglarLixi: Personas que lograron la meta',
          backgroundColor: '#03DBB1',
          hoverBackgroundColor: '#01B991'
        },
      ],
    });
  }

}
