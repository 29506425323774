import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {SemaDataService} from '../../services/sema-data.service';
import {showWithComma} from '../../global/globalFunctions';
import {IEffectiveness} from '../../models/scenario.class';

@Component({
    selector: 'app-costo-tratamiento',
    templateUrl: './numero-tratamientos.component.html',
    styleUrls: ['../../theme.scss']
})
export class NumeroTratamientosComponent implements OnInit {
    public barChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            borderWidth: 1,
            titleFontColor: '#000000',
            bodyFontColor: '#000000',
            callbacks: {
                title: item => item[0].label.replace(/,/g, ' '),
                label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += showWithComma(Number(tooltipItem.yLabel).toFixed(0));
                    return label;
                }
            }
        },
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Número necesario a tratar para llevar un paciente al objetivo'
                },
                ticks: {
                    beginAtZero: true,
                }
            }]
        },

    };
    public effectiveness: IEffectiveness;

    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: ChartDataSets[] = [];

    public totals: any[] = [];

    // public divider: number;

    constructor(protected data: SemaDataService) {

        this.effectiveness = data.inputs.effectiveness;

        const effectivenessMap: Array<any> = data.getEffectivenessMap();

        const semaglutida05 = {
            data: [],
            label: 'Semaglutida 0.5 mg una vez a la semana',
            backgroundColor: '#39B0C1',
            borderColor: '#39B0C1',
            hoverBackgroundColor: '#1790A0',
            pointHoverBackgroundColor: '#FFFFFF'
        };
        const semaglutida1 = {
            data: [],
            label: 'Semaglutida 1 mg una vez a la semana',
            backgroundColor: '#b470c1',
            borderColor: '#b470c1',
            hoverBackgroundColor: '#a01e9b',
            pointHoverBackgroundColor: '#FFFFFF'
        };
        const dulaglutida15 = {
            data: [],
            label: 'Dulaglutida 1.5 mg',
            backgroundColor: '#c17a67',
            borderColor: '#c17a67',
            hoverBackgroundColor: '#a04328',
            pointHoverBackgroundColor: '#FFFFFF'
        };

        for (const item of effectivenessMap) {
            this.barChartLabels.push(item.label);
            semaglutida05.data.push(1 / item.data.semaglutida_0_5);
            semaglutida1.data.push(1 / item.data.semaglutida_1);
            dulaglutida15.data.push(1 / item.data.dulaglutida_1_5);
        }

        this.barChartData.push(semaglutida05);
        this.barChartData.push(semaglutida1);
        this.barChartData.push(dulaglutida15);
    }

    isArray(obj) {
        return (obj instanceof Array);
    }

    ngOnInit() {
    }

}
