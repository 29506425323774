import { Component, OnInit } from '@angular/core';
import {CalculatorStatus} from "../../../classes/status";

@Component({
  selector: 'app-dose-model-psa-results',
  templateUrl: './dose-model-psa-results.component.html',
  styleUrls: ['./dose-model-psa-results.component.css']
})
export class DoseModelPsaResultsComponent {

  windowRef = window as any;
  psaResume: any;
  private statusInstance = CalculatorStatus.getInstance();

  constructor() {
    if (this.statusInstance.modified) {
      this.windowRef.run_dose_model();
      this.statusInstance.modified = false;
    }
    this.psaResume = this.windowRef.array_psa_dose_model;
  }
}
