import {Component, OnInit} from '@angular/core';
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.css']
})
export class OtherComponent implements OnInit {

  other: any = {};
  keys = {
    base_year: 'base_year',
    years: 'years',
    discount_rate_costs: 'discount_rate_costs',
    VAT: 'VAT',
  };

  time_horizon = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
  ];

  messageMapping:
    {[k: string]: string} = {'=1': '# Año', 'other': '# Años'};

  updateValue(key, value) {
    let js_window = this.ws.nativeWindow;
    js_window[key] = value;
    this.other[key] = value;
  }

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;
    for (let key in this.keys){
      this.other[key] = js_window[key];
    }
  }

  ngOnInit() {
  }

}
