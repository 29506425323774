import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as Big from 'big.js';
import {CalculatorStatus} from "../../classes/status";

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.css']
})
export class NumericInputComponent implements OnInit, AfterViewInit {

  @Input() init_value: number;
  @Input() init_step: number;

  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  statusInstance: CalculatorStatus = CalculatorStatus.getInstance();

  step: any;

  constructor() {
  }

  ngOnInit() {
    if (typeof this.init_step !== 'undefined') {
      this.step = Big(this.init_step);
    } else {
      this.step = Big(1);
    }
  }

  ngAfterViewInit() {
  }

  changeValue(negative: boolean){
    let val_big = Big(this.init_value);
    if (negative) {
      val_big = val_big.minus(this.step);
    }
    else {
      val_big = val_big.plus(this.step);
    }
    this.emitChange(parseFloat(val_big.toString()))
  }

  emitChange(event) {
    this.statusInstance.modified = true;
    this.valueChanged.emit(event);
  }
}
