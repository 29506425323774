import {Component, OnInit} from '@angular/core';
import {SemaDataService} from '../../services/sema-data.service';
import {IConsumableCostsData, IEffectiveness, IMedCosts, IMedsData, IResources} from '../../models/scenario.class';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['../../theme.scss']
})
export class CostsComponent implements OnInit {

  medCostsMap: Array<ICostsMap>;
  consumableCostsMap: Array<ICostsMap>;
  medCosts: IMedCosts;
  consumableCosts: IConsumableCostsData;

  constructor(protected data: SemaDataService) {
    this.medCosts = data.inputs.medCosts;
    this.consumableCosts = data.inputs.consumableCosts;
    this.medCostsMap = [
      {
        label: 'Semaglutida 0.5 mg',
        key: 'semaglutida_0_5',
      },
      {
        label: 'Semaglutida 1 mg',
        key: 'semaglutida_1',
      },
      {
        label: 'Dulaglutida 1.5 mg',
        key: 'dulaglutida_1_5',
      },
    ];
    this.consumableCostsMap = [
      {
        label: 'Agujas para inyección de Semaglutida',
        key: 'needles_semaglutida',
      },
      {
        label: 'Agujas para inyección de Dulaglutida',
        key: 'needles_dulaglutida',
      },
      {
        label: 'Tirillas',
        key: 'bands',
      },
      {
        label: 'Lancetas',
        key: 'lancets',
      },
    ];
  }

  ngOnInit() {
  }
}

interface ICostsMap {
  label: string;
  key: string;
}
