import { Component, OnInit } from '@angular/core';

interface ResourceUse {
  row_name: string,
  titles: Array<string>,
  keys: Array<string>,
  subtitles: Array<string>,
  subkeys: Array<string>
  disabled?: any,
  step?: Array<number>
}

let subtitles_insulin = ['Insulina Degludec', 'Insulina Glargina'];
let subkeys_insulin = ['insulina_degludec', 'insulina_glargina'];

let subtitles_doses = ['Dosis', 'SE'];
let subkeys_doses = ['dosis', 'se'];

@Component({
  selector: 'app-dose-model-uses',
  templateUrl: './dose-model-uses.component.html',
  styleUrls: ['./dose-model-uses.component.css']
})
export class DoseModelUsesComponent implements OnInit {

  recurso_inicial_insulina = window['recurso_inicial_insulina'];
  recurso_dosis_insulina_bolo = window['recurso_dosis_insulina_bolo'];
  recurso_agujas = window['recurso_agujas'];
  recurso_pruebas_smbg_semana = window['recurso_pruebas_smbg_semana'];
  recurso_hipoglucemia_diurna = window['recurso_hipoglucemia_diurna'];
  recurso_hipoglucemia_nocturna_no_grave = window['recurso_hipoglucemia_nocturna_no_grave'];
  recurso_hipoglucemia_grave = window['recurso_hipoglucemia_grave'];

  recurso_inicial_insulina_map_1: ResourceUse = {
    row_name: 'Dosis base',
    titles: [
      'Dosis de insulina Glargina (UI al día)'
    ],
    keys: [
      'dosis_de_insulina'
    ],
    step: [
      1, 0.1
    ],
    subtitles: subtitles_doses,
    subkeys: [
      'dosis', 'insulina_glargina'
    ]
  };
  recurso_inicial_insulina_map_2: ResourceUse = {
    row_name: 'Tasas de la dosis',
    titles: [
      'Tasa de la dosis',
      'Error estándar',
      'Dosis calculada (UI al día)'
    ],
    keys: [
      'tasa_de_dosis',
      'error_estandar',
      'dosis_calculada'
    ],
    step: [
      0.1, 0.1
    ],
    disabled: { 2: true },
    subtitles: subtitles_insulin,
    subkeys: subkeys_insulin
  };

  recurso_dosis_insulina_bolo_map_1: ResourceUse = {
    row_name: 'Dosis base',
    titles: [
      'Dosis de insulina Glargina (UI al día)'
    ],
    keys: [
      'dosis_de_insulina',
    ],
    step: [
      1, 0.1
    ],
    subtitles: subtitles_doses,
    subkeys: subkeys_doses
  };

  recurso_dosis_insulina_bolo_map_2: ResourceUse = {
    row_name: 'Dosis base',
    titles: [
      'Tasa de la dosis',
      'Error estándar',
      'Dosis calculada (UI al día)'
    ],
    keys: [
      'tasa_de_dosis',
      'error_estandar',
      'dosis_calculada'
    ],
    step: [
      0.1, 0.1
    ],
    disabled: { 2: true },
    subtitles: subtitles_insulin,
    subkeys: subkeys_doses
  };

  recurso_agujas_map: ResourceUse = {
    row_name: '',
    titles: [
      'Agujas al día para la inyección inicial',
      'Agujas al día para la inyección en bolo'
    ],
    keys: [
      'inyeccion_inical',
      'inyeccion_bolo'
    ],
    subtitles: subtitles_insulin,
    subkeys: subkeys_insulin
  };

  recurso_pruebas_smbg_semana_map: ResourceUse = {
    row_name: '',
    titles: [
      'Pruebas de SMBG a la semana'
    ],
    keys: [
      'inyeccion_inical'
    ],
    subtitles: subtitles_insulin,
    subkeys: subkeys_insulin
  };

  recurso_hipoglucemia_diurna_map: ResourceUse = {
    row_name: 'Hipoglucemia diurna no grave',
    titles: [
      'Numero de pruebas',
      'SE'
    ],
    keys: [
      'numero_de_pruebas',
      'numero_de_pruebas_se'
    ],
    subtitles: subtitles_insulin,
    subkeys: subkeys_insulin
  };

  recurso_hipoglucemia_nocturna_no_grave_map: ResourceUse = {
    row_name: 'Hipoglucemia nocturna no grave',
    titles: [
      'Numero de pruebas',
      'SE'
    ],
    keys: [
      'numero_de_pruebas',
      'numero_de_pruebas_se'
    ],
    subtitles: subtitles_insulin,
    subkeys: subkeys_insulin
  };

  recurso_hipoglucemia_grave_map: ResourceUse = {
    row_name: 'Hipoglucemia grave',
    titles: [
      'Numero de pruebas',
      'SE'
    ],
    keys: [
      'numero_de_pruebas',
      'numero_de_pruebas_se'
    ],
    subtitles: subtitles_insulin,
    subkeys: subkeys_insulin
  };

  constructor() { }

  ngOnInit() {}

}
