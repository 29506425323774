import {AfterContentInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-db-pop-type]',
  templateUrl: './db-pop-type.component.html',
  styleUrls: ['./db-pop-type.component.css']
})
export class DbPopTypeComponent implements OnInit, AfterContentInit {

  @Input() main_object: any;
  @Input() show_brakdown: boolean;

  comparators: any = (window as any).getComparators();
  years: Array<number> = [];

  constructor() {
    for (let i = 0; i < window['time_horizon']; i++) {
      this.years.push(window['starting_year'] + i);
    }
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    console.log(this.main_object);
  }
}
