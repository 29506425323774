import {Injectable} from '@angular/core';
import {DATABASE, DEFAULT} from './database.const';
import {Factory, MODEL} from '../models/factory';
import {IScenario} from '../models/scenario.class';

import inputs from '../data/inputs.json';
import params from '../data/params.json';
import {DatabaseManager} from '../../calculator-globals/database/database-manager';

@Injectable()
export class DatabaseService extends DatabaseManager {
    constructor() {
        super();
    }

    protected dbData = DATABASE;
    protected dbDefaults = DEFAULT;
    protected factory = new Factory();
    protected scenarioDefaultParams: IScenario = {
        name: DEFAULT.name,
        inputs,
        parameters: params
    };
    protected scenarioDefaultModel = MODEL.Scenario;
}
