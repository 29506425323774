import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scenario-tree',
  templateUrl: './scenario-tree.component.html',
  styleUrls: ['./scenario-tree.component.css']
})
export class ScenarioTreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
