import {Component, OnInit} from '@angular/core';
import {AppSettings} from "../../../app.settings";

@Component({
  selector: 'app-victoza-results',
  templateUrl: './victoza-results.component.html',
  styleUrls: ['./victoza-results.component.css']
})
export class VictozaResultsComponent implements OnInit {

  window_ref = window as any;


  result_data_mapping: Array<{key: string, title: string}> = [
    {key: "a_iam", title: 'IAM'},
    {key: "b_acv", title: 'ACV'},
    {key: "c_evento_macrovascular", title: 'Evento macrovascular'},
    {key: "d_evento_microvascular", title: 'Evento microvascular'},
    {key: "e_muertes", title: 'Muertes'}
  ];

  technology_subkey_mapping: string[] = [
    'a_liraglutide', 'b_exanentide', 'c_glimepiride', 'd_sitagliptina'
  ];

  simulation_subkey_mapping: string[] = [
    "a_escenario_actual", "b_escenario_esperado", "c_diferencia_eventos"
  ];

  constructor() {
    this.window_ref.run_victoza();
  }

  ngOnInit() {
  }

}
