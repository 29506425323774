import {Component, Input, OnInit} from '@angular/core';
import {DatosService} from '../../services/datos.service';
import {ICostos, IEParametros, IRange} from '../../models/scenario.class';

@Component({
  selector: 'app-input-poblacion',
  templateUrl: './input-poblacion.component.html',
  styleUrls: ['./input-poblacion.component.css']
})
export class InputPoblacionComponent implements OnInit {

  @Input() codigo: string;

  // divider: number;
  dividerCa: number;
  prevalencia: number;
  active: string;

  constructor(public data: DatosService) {
    this.prevalencia = this.data.prevalenncia.prevalencia;
    this.active = 'eic';
    // this.divider = 1000000000; // Miles de Millones
    this.dividerCa = 1000000; // Millones
  }

  ngOnInit() {
  }


  public getTotal(poblacion: string, name: string): number {
    let counter = 0;
    this.data.enParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      item.ranges.forEach((range: IRange) => {
        counter += range.poblacion[poblacion] ? range.poblacion[poblacion] : 0;
      });
    });
    return counter;
  }

  public getTotalCA(poblacion: string, name: string): number {
    let counter = 0;
    this.data.caParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      item.ranges.forEach((range: IRange) => {
        counter += range.poblacion[poblacion] ? range.poblacion[poblacion] : 0;
      });
    });
    return counter;
  }

  public getTotalByMorbilidad(poblacion: string, name: string): number {
    let counter = 0;
    this.data.enParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      item.ranges.forEach((range: IRange) => {
        const tmp = range.poblacion[poblacion] ? range.poblacion[poblacion] : 0;
        counter += (tmp * this.prevalencia * (range.mean / 100));
      });
    });
    return counter;
  }

  public getTotalByMorbilidadCA(poblacion: string, name: string): number {
    let counter = 0;
    this.data.caParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      item.ranges.forEach((range: IRange) => {
        const tmp = range.poblacion[poblacion] ? range.poblacion[poblacion] : 0;
        counter += (tmp * this.prevalencia * (range.mean / 100));
      });
    });
    return counter;
  }

  public getGrandTotal(name: string): number {
    let counter = 0;
    this.data.enParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      item.ranges.forEach((range: IRange) => {
        item.poblacion.forEach((value: string) => {
          const tmp: number = range.poblacion[value] ? range.poblacion[value] : 0;
          counter += (tmp * this.prevalencia * (range.mean / 100));
        });
      });
    });
    return counter;
  }


  public getCostDisease(name: string): number {
    let cost = this.data.costos.find((item: ICostos) => {
      return item.name.toLowerCase() === name.toLowerCase();
    });
    // console.log(cost, name);
    if (cost) {
      return cost.value;
    } else {
      return 0;
    }
  }

  public getCostDiseaseCA(name: string): number {
    let cost = this.data.costos.find((item: ICostos) => {
      switch (name) {
        case 'Seno ( <50 años )':
          return item.name.toLowerCase().includes('seno');
        case 'Seno ( >50 años )':
          return item.name.toLowerCase().includes('seno');
        case 'Colón y recto':
          return item.name.toLowerCase().includes('colon');
        case 'Vesícula y vias biliares':
          return item.name.toLowerCase().includes('vesícula');
        default:
          return item.name.toLowerCase().includes(name.toLowerCase());
      }

    });
    // console.log(cost, name);
    if (cost) {
      return cost.value / this.dividerCa;
    } else {
      return 0;
    }

  }


  public deleteValues(name) {
    this.data.enParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      for (let ranges of item.ranges){
        // console.log(ranges.poblacion);
        if (ranges.poblacion.mujeres){
          ranges.poblacion.mujeres = 0;
        }
        if (ranges.poblacion.hombres ){
          ranges.poblacion.hombres = 0;
        }
        if (ranges.poblacion.total ) {
          ranges.poblacion.total = 0;
        }
      }
    });
    // console.log('do you wanna delete all ?', name);
  }

  public deleteValuesCA(name) {
    this.data.caParams.filter((item: IEParametros) => {
      return item.name === name;
    }).forEach((item: IEParametros) => {
      for (let ranges of item.ranges){
        ranges.poblacion.total ? ranges.poblacion.total = 0 : 0;
      }
    });
    // console.log('do you wanna delete all ?', name);
  }

  update(e) {
    this.active = e;
  }

}
