import {Injectable} from '@angular/core';
import {DatabaseManager} from '../../calculator-globals/database/database-manager';
import {DATABASE, DEFAULT} from '../database/database.const';
import {MODEL, ODFactory} from '../database/factory.class';
import {IODScenario} from '../models/scenario.class';

import Params from '../data/params.json';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends DatabaseManager {
  protected dbData = DATABASE;
  protected dbDefaults = DEFAULT;
  protected factory = new ODFactory();
  protected scenarioDefaultModel = MODEL.Scenario;
  protected scenarioDefaultParams: IODScenario = {
    name: DEFAULT.name,
    data: {
      costo_por_paciente_mes: Params.costo_por_paciente_mes,
      metas: Params.metas,
      numero_pacientes: Params.numero_pacientes,
      annual_base: Params.annual_base,
    }
  };
}
