import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debie-resource',
  templateUrl: './debie-resource.component.html',
  styleUrls: ['./debie-resource.component.css']
})
export class DebieResourceComponent implements OnInit {

  resource_type_one=window['resource_type_one'];
  resource_type_two_oral=window['resource_type_two_oral'];
  resource_type_two_bolus=window['resource_type_two_bolus'];

  tabs = {
    type_1: ['block', true],
    type_2_bo: ['none', false],
    type_2_bb: ['none', false],
  };

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      }
      else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
