import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, IMetaResults } from '../../servicio/api.service';
import { Chart, ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { fmtCop } from '../../../calculator-globals/utils';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['../../styles/theme.scss']
})
export class MetaComponent implements OnInit {

  metaId: string;
  metaIndex: number;
  metaIdNum: number;
  chartDap: DAPChart;
  chartPacientes: DAPChart;

  constructor(private route: ActivatedRoute, private api: ApiService) {
    this.chartDap = new DAPChart();
    this.chartPacientes = new DAPChart();
  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.metaIndex = parseInt(routeParams.id, 10);
      this.loadChartDap();
      this.loadChartPacientes();
    });
  }

  scrollTo(id: string) {
    console.log(id);
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  results(): IMetaResults {
    return this.api.app.getMetaResults(this.metaIndex - 1);
  }

  metaTitle(): string {
    return this.api.content.goals[this.metaIndex - 1];
  }

  loadChartDap() {
    this.chartDap.barChartLabels = ['Dap'];
    this.chartDap.barChartData = [
      {
        data: [this.results().CostoControlRelativo.ccr.s05],
        label: 'Semaglutide 0,5 mg',
        backgroundColor: '#ED7900',
        borderColor: '#ED7900',
        hoverBackgroundColor: '#ED7900',
        pointHoverBackgroundColor: '#FFFFFF'
      },
      {
        data: [this.results().CostoControlRelativo.ccr.s1],
        label: 'Semaglutide 1 mg',
        backgroundColor: '#ED7900',
        borderColor: '#ED7900',
        hoverBackgroundColor: '#ED7900',
        pointHoverBackgroundColor: '#FFFFFF'
      }, {
        data: [this.results().CostoControlRelativo.ccr.l18],
        label: 'Liraglutide 1,8',
        backgroundColor: '#b470c1',
        borderColor: '#b470c1',
        hoverBackgroundColor: '#a01e9b',
        pointHoverBackgroundColor: '#FFFFFF'
      }];
  }

  loadChartPacientes() {
    this.chartPacientes.barChartOptions.scales.yAxes[0].stacked = true;
    this.chartPacientes.barChartOptions.scales.xAxes[0].stacked = true;
    this.chartPacientes.barChartLabels = ['Semaglutide 0,5 mg', 'Semaglutide 1 mg', 'Liraglutide 1,8'];
    this.chartPacientes.barChartData = [
      {
        data: [
          this.results().personasLograronMeta.s05,
          this.results().personasLograronMeta.s1,
          this.results().personasLograronMeta.l18
        ],
        label: 'Personas que lograron la meta',
        backgroundColor: ['#ED7900', '#ED7900', '#b470c1'],
        borderColor: ['#ED7900', '#ED7900', '#b470c1'],
        hoverBackgroundColor: ['#ED7900', '#ED7900', '#a01e9b'],
        pointHoverBackgroundColor: '#FFFFFF'
      },
      {
        data: [
          this.results().personasNoLograronMeta.s05,
          this.results().personasNoLograronMeta.s1,
          this.results().personasNoLograronMeta.l18
        ],
        label: 'Personas que no lograron la meta',
        backgroundColor: '#cccccc',
        borderColor: '#cbcaca',
        hoverBackgroundColor: '#aaaaaa',
        pointHoverBackgroundColor: '#FFFFFF'
      }
    ];
  }

}

class DAPChart {
  public barChartOptions: any = {
    responsive: true,
    showDatapoints: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += Math.round(+tooltipItem.yLabel);
          return label;
        }
      }
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          beginAtZero: true
        }
      }],
      xAxes: [{}]
    }, hover: {
      animationDuration: 0
    },
    animation: {
      duration: 1,
      onComplete() {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;
        ctx.font = Chart.helpers.fontString(
          24,
          Chart.defaults.global.defaultFontStyle,
          Chart.defaults.global.defaultFontFamily,
          '#ffffff'
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = 'black';

        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = Math.round(dataset.data[index]);
            ctx.fillText(data, bar._model.x, bar._model.y + 40);
          });
        });
      }
    },
  };
  public barChartOptionsCurrency: any = {
    responsive: true,
    showDatapointsCurrency: true,
    tooltips: {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
      borderWidth: 1,
      titleFontColor: '#000000',
      bodyFontColor: '#000000',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += fmtCop(tooltipItem.yLabel);
          return label;
        }
      }
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          beginAtZero: true,
          callback(value: any, index: any, values: any): string | number {
            return fmtCop(value);
          }
        }
      }],
      xAxes: [{}]
    },
    hover: {
      animationDuration: 0
    },
    animation: {
      duration: 1,
      onComplete() {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;
        ctx.font = Chart.helpers.fontString(
          24,
          Chart.defaults.global.defaultFontStyle,
          Chart.defaults.global.defaultFontFamily,
          '#ffffff'
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = 'black';

        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = fmtCop(dataset.data[index]);
            ctx.fillText(data, bar._model.x, bar._model.y + 40);
          });
        });
      }
    },
  };

  constructor() {
  }

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [];



}
