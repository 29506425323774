import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tab-content-dc',
  templateUrl: './tab-content-dc.component.html',
  styleUrls: ['./tab-content-dc.component.css']
})
export class TabContentDcComponent implements OnInit {

  comparators: Array<any> = (window as any).getComparators();

  @Input()
  main_object: any;

  subtitles: Array<any> = [
    {label: 'Eventos hipoglicémicos diurnos no severos', selector: 'daytime_hypoglycemia'},
    {label: 'Eventos hipoglicémicos nocturnos no severos', selector: 'nocturnal_hypoglycemia'},
    {label: 'Eventos hipoglicémicos severos', selector: 'severe_hypoglycemia'},
  ];

  constructor() { }

  ngOnInit() {
  }

}
