import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HomeComponent} from './views/home/home.component';
import {RouterModule, Routes} from '@angular/router';

import {ChartsModule} from 'ng2-charts';

import {SemaDataService} from './services/sema-data.service';
import {AsideComponent} from './components/aside/aside.component';
import {EfectividadClinicaComponent} from './views/efectividad-clinica/efectividad-clinica.component';

import {CalParamsComponent} from './views/cal-params/cal-params.component';
import {FooterComponent} from './components/footer/footer.component';

import {HeaderComponent} from './components/header/header.component';
import {HeaderRefsComponent} from './components/header-refs/header-refs.component';
import {DatabaseService} from './database/database.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalModule} from './modals/modal.module';
import {RoutingService} from './services/routing.service';

import {DividerP} from './pipes/dividerPipe.pipe';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {MaterialModule} from './material.module';
import {ResourcesComponent} from './views/resources/resources.component';
import {CostsComponent} from './views/costs/costs.component';
import {CostoTratamientoComponent} from './views/costo-tratamiento/costo-tratamiento.component';
import {NumeroTratamientosComponent} from './views/numero-tratamientos/numero-tratamientos.component';
import {CostoControlComponent} from './views/costo-control/costo-control.component';
import {DeterministicComponent} from './views/deterministic/deterministic.component';
import {ProbabilisticComponent} from './views/probabilistic/probabilistic.component';
import { BaseComponent } from './views/base/base.component';

export const semaRoutes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    component: HomeComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'efectividad-clinica', component: EfectividadClinicaComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'parametros', component: CalParamsComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'recursos', component: ResourcesComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'costos', component: CostsComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'control-costo', component: CostoControlComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'tratamiento', component: CostoTratamientoComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'numero-tratamientos', component: NumeroTratamientosComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'deterministico', component: DeterministicComponent,
    resolve: {
      data: SemaDataService
    }
  },
  {
    path: 'probabilistico', component: ProbabilisticComponent,
    resolve: {
      data: SemaDataService
    }
  },
];

export function loadDatabase(database: DatabaseService): () => Promise<any> {
  return () => {
    return new Promise((resolve, reject) => {
      database.init().then(() => {
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [
    HomeComponent,
    AsideComponent,
    EfectividadClinicaComponent,
    CalParamsComponent,
    ResourcesComponent,
    FooterComponent,
    HeaderComponent,
    HeaderRefsComponent,
    CostsComponent,
    CostoTratamientoComponent,
    NumeroTratamientosComponent,
    CostoControlComponent,
    DeterministicComponent,
    ProbabilisticComponent,
    DividerP,
    BaseComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ChartsModule,
    ModalModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    MaterialModule,
    RouterModule,
  ],
  providers: [
    DatabaseService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadDatabase,
      multi: true,
      deps: [DatabaseService]
    },
    SemaDataService,
    RoutingService
  ]
})
export class SemaglutidaModule {
}
