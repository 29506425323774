import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dm-result-psa',
  templateUrl: './dm-result-psa.component.html',
  styleUrls: ['./dm-result-psa.component.css']
})
export class DmResultPsaComponent implements OnInit {
  data: any[] = [
    {
      data: [
      ],
      label: 'Series A',
    }
  ];

  options: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  windowRef = (window as any);

  constructor() {
  }

  ngOnInit() {
    this.windowRef.run_dose_model();
    this.loadResults();
  }

  runPSA() {
    this.windowRef.psa_resultados_dose_model();
    this.loadResults();
  }

  private loadResults() {
    const results = this.windowRef.array_psa_dose_model;
    this.data[0].data = [];
    if (results) {
      for (const result of results) {
        this.data[0].data.push({x: result.b_qale_incremental, y: result.c_costo_incremental, r: 3});
      }
    }
  }
}
