import { Component, OnInit } from '@angular/core';
import { AppSettings } from "../../app.settings";

@Component({
  selector: 'app-profilaxis',
  templateUrl: './profilaxis.component.html',
  styleUrls: ['./profilaxis.component.css']
})
export class ProfilaxisComponent implements OnInit {

  calculator_id = AppSettings.PROFILAXIS_ID;

  constructor() { }

  ngOnInit() {
  }

}
