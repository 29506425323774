import { Component, OnInit } from '@angular/core';
import { Setting } from '../../classes/setting';
import { AppSettings } from '../../app.settings';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  scenarios: {[key: string]: Array<any>} = {};
  calcCategories = AppSettings.CALCULATOR_DATA;

  constructor(private router: Router, private dialog: MatDialog) {
    this.loadScenarios();
  }

  ngOnInit() {
  }

  loadSelectedSetting(calculatorId: string, settingKey: string) {
    const setting = new Setting(calculatorId, settingKey);
    setting.loadSetting();

    for (const cat of this.calcCategories) {
      for (const calculator of cat.calculators) {
        if (calculatorId === calculator.name) {
          this.router.navigate(['/' + calculator.route]);
          return;
        }
      }
    }
  }

  deleteSetting(calculatorId: string, settingKey: string) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: 'Eliminar escenario',
        message: `¿Desea eliminar el escenario "${settingKey}" de forma permanente?`
      }
    });

    dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((result: boolean) => {
          if (result) {
            const setting = new Setting(calculatorId, settingKey);
            const index = this.scenarios[calculatorId].findIndex(sc => sc.key === settingKey);
            this.scenarios[calculatorId].splice(index, 1);
            setting.deleteSetting();
          }
        });
  }

  loadScenarios() {
    for (const cat of this.calcCategories) {
      for (const calculator of cat.calculators) {
        if (calculator.noScenarios) {
          continue;
        }

        let scenario = Setting.getListSettings(calculator.name);
        if (Object.keys(scenario).length === 0) {
          const setting = new Setting(calculator.name, 'Escenario base');
          setting.saveSetting();
          scenario = Setting.getListSettings(calculator.name);
        }

        this.scenarios[calculator.name] = [];

        for (const key in scenario) {
          if (scenario.hasOwnProperty(key)) {
            this.scenarios[calculator.name].push({key, value: scenario[key]});
          }
        }
      }
    }
  }
}
