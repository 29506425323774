import {Component, OnInit} from '@angular/core';
import {SemaDataService} from '../../services/sema-data.service';
import {IEffectiveness} from '../../models/scenario.class';
import {IEffectivenessMap} from '../../global/globalFunctions';

@Component({
  selector: 'app-efectividad-clinica',
  templateUrl: './efectividad-clinica.component.html',
  styleUrls: ['../../theme.scss']
})
export class EfectividadClinicaComponent implements OnInit {

  effectivenessMap: Array<IEffectivenessMap>;
  effectiveness: IEffectiveness;

  constructor(protected data: SemaDataService) {
    this.effectiveness = data.inputs.effectiveness;
    this.effectivenessMap = data.getEffectivenessMap();
  }

  ngOnInit() {
  }

  currencyInputChanged(value) {
    const num = value.replace(/[$,]/g, '');
    return Number(num);
  }

  get costos(): Array<any> {
    return [];
  }

}

