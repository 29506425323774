import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  constructor(public dialogRef: MatDialogRef<ModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IModalData,
              private sanitizer: DomSanitizer) {}

  close() {
    this.dialogRef.close();
  }

  get sanitizedTitle() {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.title);
  }

  get sanitizedMessage() {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.message);
  }
}

export interface IModalData {
  title: string;
  message: string;
}
