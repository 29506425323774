import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-bi-per-bleed',
  templateUrl: './bi-per-bleed.component.html',
  styleUrls: ['./bi-per-bleed.component.css']
})
export class BiPerBleedComponent implements OnInit {

  public bi_per_bleed: any;

  public bpbChartLegend: boolean = true;s
  public bpbChartType: string = 'bar';
  public bpbDataSets: Array<any> = [
    {data: [], label: 'Escenario Actual'},
    {data: [], label: 'Escenario Alternativo'},
  ];

  public bpbChartLabels: Array<string> = ['Costo promedio por sangrado'];
  public bpbChartOptions:any = {
    showDatapoints: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
        }
      }]
    }
  };

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    js_window.runOnDemand();

    this.bi_per_bleed = js_window['bi_per_bleed']['bi_per_bleed'];

    this.bpbDataSets[0].data.push(this.bi_per_bleed['current_scenario']['Total_Cost']);
    this.bpbDataSets[1].data.push(this.bi_per_bleed['alternative_scenario']['Total_Cost']);
  }

  ngOnInit() {
  }

}
