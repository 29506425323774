import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debie-market-share',
  templateUrl: './debie-market-share.component.html',
  styleUrls: ['./debie-market-share.component.css']
})
export class DebieMarketShareComponent implements OnInit {

  map: Array<string> = [
    'type_one_name_scenario_one',
    'type_one_name_scenario_two',

    'type_one_scenario_one',
    'type_one_scenario_two',

    'type_two_basal_oral_scenario_one',
    'type_two_basal_oral_scenario_two',

    'type_two_bolus_oral_scenario_one',
    'type_two_bolus_oral_scenario_two'
  ];

  model: any = {};

  model_years: Array<number> = [];
  model_years_keys: Array<string> = [];

  comparators: Array<any> = (window as any).getComparators();

  tabs = {
    type_1: ['block', true],
    type_2_bo: ['none', false],
    type_2_bb: ['none', false],
  };

  constructor() {
    (window as any).run();

    for (let key of this.map) {
      this.model[key] = window[key];
    }

    let yr_string = "year_";
    for (let i = 0; i < window['time_horizon']; i++) {
      this.model_years.push(window['starting_year'] + i);
      if(i === 0) {
        this.model_years_keys.push(yr_string + "one")
      }
      if(i === 1) {
        this.model_years_keys.push(yr_string + "two")
      }
      if(i === 2) {
        this.model_years_keys.push(yr_string + "three")
      }
      if(i === 3) {
        this.model_years_keys.push(yr_string + "four")
      }
      if(i === 4) {
        this.model_years_keys.push(yr_string + "five")
      }

    }
  }

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      }
      else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

  updateValue(key, value) {
    (window as any).run();
    this.model[key] = value;
    window[key] = value;
  }

  windowRun() {
    (window as any).run();
  }

  ngOnInit() {
  }

}
