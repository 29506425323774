import {Component, OnInit} from '@angular/core';
import {DataArray} from "../../../classes/data-array";
import {AppSettings} from "../../../app.settings";
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-protocol',
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.css']
})

/* protocol tables and variables defined on _04protocol.js */
export class ProtocolComponent implements OnInit {
  rFVIIa_protocol_labels: DataArray = new DataArray('', [], AppSettings.LABEL, 'rFVIIa_protocol_labels');
  dosage_per_day: DataArray = new DataArray('Dosis diarias', [], AppSettings.NUMERIC, 'dosage_per_day');
  usage: DataArray = new DataArray('Cantidad', [], AppSettings.NUMERIC, 'usage');

  pd_aPCC_protocol_labels: DataArray = new DataArray('', [], AppSettings.LABEL, 'pd_aPCC_protocol_labels');
  pd_dosage_per_day: DataArray = new DataArray('Dosis diarias', [], AppSettings.NUMERIC, 'pd_dosage_per_dia');
  pd_usage: DataArray = new DataArray('Cantidad', [], AppSettings.NUMERIC, 'pd_usage');

  number_of_weeks: number = 0;
  after_dosage_day_rFVIIa: number = 0;
  after_usage_rFVIIa: number = 0;
  after_dosage_day_APCC: number = 0;
  after_usage_APCC: number = 0;

  total_rFVIIa: number = 9270;
  total_pdaPCC: number = 0;

  constructor(private windowService: WindowService) {
    let js_window = windowService.nativeWindow;

    for (let item of js_window.protocol_rFVIIa){
      this.rFVIIa_protocol_labels.appendData(item.day);
      this.dosage_per_day.appendData(item.dossage);
      this.usage.appendData(item.usage);
    }

    for (let item of js_window.protocol_APCC){
      this.pd_aPCC_protocol_labels.appendData(item.day);
      this.pd_dosage_per_day.appendData(item.dossage);
      this.pd_usage.appendData(item.usage);
    }

    this.number_of_weeks = js_window.protocol_after_month1_weeks;
    this.after_dosage_day_rFVIIa = js_window.after_month1_rFVIIa["dossage"];
    this.after_usage_rFVIIa = js_window.after_month1_rFVIIa["usage"];
  }

  ngOnInit() {
  }


  /* event_data = {data_array_id, row} */
  updateRow(event_data) {
    let js_window = this.windowService.nativeWindow;

    if (this.dosage_per_day.id == event_data.data_array_id){
      js_window.protocol_rFVIIa[event_data.row].dossage = this.dosage_per_day.data[event_data.row];
      this.calcTotalrFVIIa();
    }
    else if (this.usage.id == event_data.data_array_id){
      js_window.protocol_rFVIIa[event_data.row].usage = this.usage.data[event_data.row];
      this.calcTotalrFVIIa();
    }
    else if (this.pd_dosage_per_day.id == event_data.data_array_id){
      js_window.protocol_APCC[event_data.row].dossage = this.pd_dosage_per_day.data[event_data.row];
      this.calcTotalpdAPCC();
    }
    else if (this.pd_usage == event_data.data_array_id){
      js_window.protocol_APCC[event_data.row].usage = this.pd_usage[event_data.row]
      this.calcTotalpdAPCC();
    }
  }

  calcTotalpdAPCC() {
    this.total_pdaPCC = 0;

    for (let i = 0; i < this.dosage_per_day.data.length; i++) {
      this.total_pdaPCC += this.pd_dosage_per_day.data[i] * this.pd_usage.data[i];
    }
    this.total_rFVIIa += (this.after_dosage_day_rFVIIa * this.after_usage_rFVIIa) * (this.number_of_weeks * 7)
  }

  calcTotalrFVIIa() {
    this.total_rFVIIa = 0;

    for (let i = 0; i < this.dosage_per_day.data.length; i++) {
      this.total_rFVIIa += this.dosage_per_day.data[i] * this.usage.data[i];
    }
    this.total_rFVIIa += (this.after_dosage_day_rFVIIa * this.after_usage_rFVIIa) * (this.number_of_weeks * 7)
  }

  updateValue(keys, value) {
    let js_window = this.windowService.nativeWindow;
    if (keys.length == 1) {
      js_window[keys[0]] = value;
    }
    else {
      js_window[keys[0]][keys[1]] = value;
    }
  }
}
