import {IFactory} from './factory.interface';
import {IDatabase, IDatabaseDefaults} from './database.interface';
import {Serializable} from './serializable.interface';
import {isNullOrUndefined} from 'util';
import {DatabaseService} from '../../ozempic-dab/servicio/database.service';

export abstract class DatabaseManager {
  private db: IDBDatabase;
  private dbPromise: Promise<{}>;

  protected abstract dbData: IDatabase;
  protected abstract dbDefaults: IDatabaseDefaults;
  protected abstract factory: IFactory;
  protected abstract scenarioDefaultParams: any;
  protected abstract scenarioDefaultModel: number;

  protected constructor() {
  }

  public init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.initDatabase().then(() => {
        this.getObjectByIndexValue(
          'Scenario', this.scenarioDefaultModel, 'name', this.dbDefaults.name
        )
          .then((sc: Serializable) => {
            resolve();
          })
          .catch((e) => {
            console.error(e);
            const scenario: Serializable = this.factory.create(this.scenarioDefaultModel, this.scenarioDefaultParams);
            this.addObject('Scenario', scenario).then(() => {
              resolve();
            }).catch(() => {
              resolve();
            });
          });
      });
    });
  }

  private initDatabase(): Promise<any> {
    this.dbPromise = new Promise((resolve, reject) => {
      const request = window.indexedDB.open(this.dbData.name, this.dbData.currentVersion);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = request.result;
        for (const version in this.dbData.versions) {
          if (this.dbData.versions.hasOwnProperty(version)) {
            const v = Number(version);
            if (event.newVersion === v || event.oldVersion < v) {
              for (const istore of this.dbData.versions[version]) {
                const store = db.createObjectStore(istore.name, istore.parameters);
                for (const iindex of istore.indexes) {
                  if (isNullOrUndefined(iindex.parameters)) {
                    store.createIndex(iindex.name, iindex.key);
                  } else {
                    store.createIndex(iindex.name, iindex.key, iindex.parameters);
                  }
                }
              }
            }
          }
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        if (this.db) {
          this.db.onversionchange = (ev: any) => {
            if (ev.target) {
              ev.target.close();
            }
          };
        }
        resolve();
      };

      request.onerror = (e) => {
        console.error(e);
        (window.navigator as any).notification.alert('La aplicación no puede funcionar sin acceso a la base de datos.', () => {
          reject();
        });
      };
    });
    return this.dbPromise;
  }

  public addObject(storeName: string, value: Serializable): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const request = store.put(value.toJSON());
        request.onsuccess = (evt: any) => {
          const res: IDBRequest = evt.srcElement;
          resolve({
            key: res.result,
          });
        };
        request.onerror = (error: any) => {
          console.error(error);
          reject({
            error
          });
        };
      });
    });
  }

  public updateObject<T>(storeName: string, obj: Serializable): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const del = store.delete(obj.getKey());
        del.onsuccess = (evt: Event) => {
          const request = store.put(obj.toJSON());
          request.onsuccess = (evnt: Event) => {
            resolve(true);
          };
          request.onerror = (evnt: Event) => {
            console.error(evnt);
            reject(false);
          };
        };
        del.onerror = (evt: Event) => {
          console.error(evt);
          reject(false);
        };
      });
    });
  }

  public deleteObject<T>(storeName: string, obj: Serializable): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const del = store.delete(obj.getKey());
        del.onsuccess = (evt: Event) => {
          resolve(true);
        };
        del.onerror = (evt: Event) => {
          console.error(evt);
          reject(false);
        };
      });
    });
  }

  public getAllObjects<T>(storeName: string, className: any): Promise<Array<T>> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName);
        const store = tx.objectStore(storeName);
        const items: Array<T> = [];
        const request = store.openCursor();
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            items.push(this.factory.create(className, cursor.value));
            cursor.continue();
          } else {
            resolve(items);
          }
        };
      });
    });
  }

  public getObjectByKey(className: any, storeName: string, key: number | string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName);
        const store = tx.objectStore(storeName);
        const request = store.openCursor(key);
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            resolve(this.factory.create(className, cursor.value));
          } else {
            reject(undefined);
          }
        };
        request.onerror = (error) => {
          console.error(error);
          reject(undefined);
        };
      });
    });
  }

  public deleteObjectByKey<T>(storeName: string, key: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const del = store.delete(key);
        del.onsuccess = (evt: Event) => {
          resolve(true);
        };
        del.onerror = (evt: Event) => {
          console.error(evt);
          reject(false);
        };
      });
    });
  }

  public getObjectByIndexValue<T>(storeName: string, className: any, indexName: string, value: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const index = store.index(indexName);
        const request = index.openCursor(value);
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            resolve(this.factory.create(className, cursor.value));
          } else {
            reject(undefined);
          }
        };
        request.onerror = (error) => {
          console.error(error);
          reject(undefined);
        };
      });
    });
  }

  public updateObjectByKey(storeName: string, data: any, key: number | string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const request = store.delete(key);
        request.onsuccess = (event: any) => {
          const write = store.put(data);
          write.onsuccess = () => {
            resolve(true);
          };
          write.onerror = (reason) => {
            console.error(reason);
            reject(false);
          };
        };
        request.onerror = (error) => {
          console.error(error);
          reject(false);
        };
      });
    });
  }

  public clearStore(storeName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const request = store.clear();
        request.onsuccess = () => {
          resolve(true);
        };
        request.onerror = reason => {
          console.error(reason);
          reject(false);
        };
      });
    });
  }

  public clear(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const requests = [];
      for (const version of Object.keys(this.dbData.versions)) {
        if (this.dbData.versions[version]) {
          for (const store of this.dbData.versions[version]) {
            requests.push(this.clearStore(store.name));
          }
        }
      }
      Promise.all(requests).then(() => {
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  public get factoryInstance() {
    return this.factory;
  }
}
