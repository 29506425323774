import {Injectable} from '@angular/core';
import {ICostos, IEParametros, IMain, IPAE, IRef, Scenario} from '../models/scenario.class';
import {DatabaseService} from '../database/database.service';
import {Resolve} from '@angular/router';
import {Factory, MODEL} from '../models/factory';
import reference from '../../data/references.json';
import {DEFAULT} from '../database/database.const';

@Injectable({
  providedIn: 'root'
})

export class DatosService implements Resolve<any> {
  private scenario: Scenario;
  public ref: Array<IRef>;

  constructor(private database: DatabaseService) {
    this.ref = reference;
  }

  resolve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!!this.scenario) {
        resolve(this.scenario);
      } else {
        this.database.getObjectByIndexValue('Scenario', MODEL.Scenario, 'name', DEFAULT.name).then((sc: Scenario) => {
          this.scenario = sc;
          resolve(this.scenario);
        });
      }
    });
  }

  public setScenario(sc: Scenario) {
    this.scenario = sc;
  }

  get costos(): Array<ICostos> {
    return this.scenario ? this.scenario.costos : undefined;
  }

  get enParams(): Array<IEParametros> {
    return this.scenario ? this.scenario.enParams : undefined;
  }

  get caParams(): Array<IEParametros> {
    return this.scenario ? this.scenario.caParams : undefined;
  }

  get prevalenncia(): IMain {
    return this.scenario ? this.scenario.prevalencia : undefined;
  }

  get pae(): Array<IPAE> {
    return this.scenario ? this.scenario.pae : undefined;
  }

  public getCopyCurrent(name: string): Scenario {
    const scenario = this.scenario.toJSON();
    delete scenario.name;
    delete scenario.id;
    scenario.name = name;
    return Factory.create(MODEL.Scenario, JSON.parse(JSON.stringify(scenario)));
  }

  public getScenario(): Scenario {
    return this.scenario;
  }

  getEnParams() {
    return this.enParams;
  }

  getCaParams() {
    return this.caParams;
  }
}

