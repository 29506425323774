import { Component, OnInit } from '@angular/core';
import { WindowService } from "../../../services/window.service";

@Component({
  selector: 'app-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.css']
})
export class TreatmentsComponent implements OnInit {

  keys = {PSB_rFVIIa: 'PSB_rFVIIa', PSB_aPCC: 'PSB_aPCC', MMB_rFVIIa: 'MMB_rFVIIa', MMB_aPCC : 'MMB_aPCC',BR_Hosp: 'BR_Hosp'};
  treatments: any = {};

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    for (let key in this.keys) {
      this.treatments[key] = js_window[key];
    }
  }

  ngOnInit() {
  }

  updateValue(key, value){
    let js_window = this.ws.nativeWindow;
    this.treatments[key] = value;
    js_window[key] = this.treatments[key];
  }

}
