import {Injectable} from '@angular/core';
import {IEffectiveness, IInputs, IParams, IRef, Scenario} from '../models/scenario.class';
import {DatabaseService} from '../database/database.service';
import {ActivatedRoute, Resolve, Router} from '@angular/router';
import {MODEL} from '../models/factory';
import {DEFAULT} from '../database/database.const';
import {IEffectivenessMap} from '../global/globalFunctions';

@Injectable({
  providedIn: 'root'
})
export class SemaDataService implements Resolve<any> {
  private scenario: Scenario;
  public ref: Array<IRef>;

  constructor(private database: DatabaseService, private route: ActivatedRoute, private router: Router) {
  }

  resolve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!!this.scenario) {
        resolve(this.scenario);
      } else {
        this.database
            .getObjectByIndexValue('Scenario', MODEL.Scenario, 'name', DEFAULT.name)
            .then((sc: Scenario) => {
          this.scenario = sc;
          resolve(this.scenario);
        });
      }
    });
  }

  public setScenario(sc: Scenario) {
    this.scenario = sc;
    this.refreshComponent();
  }

  get inputs(): IInputs {
    return this.scenario ? this.scenario.inputs : null;
  }

  get params(): IParams {
    return this.scenario ? this.scenario.parameters : null;
  }

  public getCopyCurrent(name: string): Scenario {
    const scenario = this.scenario.toJSON();
    delete scenario.name;
    delete scenario.id;
    scenario.name = name;
    return this.database.factoryInstance.create(MODEL.Scenario, JSON.parse(JSON.stringify(scenario)));
  }

  public getScenario(): Scenario {
    return this.scenario;
  }

  public getOWSAMultipliers(): IEffectiveness {
    const owsa: IEffectiveness = {
      HbA1c_lte_6_5: {
        semaglutida_0_5: '',
        semaglutida_1: '',
        dulaglutida_1_5: ''
      },
      HbA1c_lte_7: {
        semaglutida_0_5: '',
        semaglutida_1: '',
        dulaglutida_1_5: ''
      },
      HbA1c_lte_7_no_hipoglicemia: {
        semaglutida_0_5: '',
        semaglutida_1: '',
        dulaglutida_1_5: ''
      },
      weight_loss_gte_5: {
        semaglutida_0_5: '',
        semaglutida_1: '',
        dulaglutida_1_5: ''
      },
      weight_loss_gte_10: {
        semaglutida_0_5: '',
        semaglutida_1: '',
        dulaglutida_1_5: ''
      },
      HbA1c_reduction: {
        semaglutida_0_5: '',
        semaglutida_1: '',
        dulaglutida_1_5: ''
      },
    };

    if (this.params.aproxProportionVariable === 'multiple of standard error') {
      for (const key of Object.keys(owsa)) {
        const data = owsa[key];
        for (const sub of Object.keys(data)) {
          const percent = this.inputs.effectiveness[key][sub];
          data[sub] = Math.sqrt(
              (1 / this.inputs.n[sub]) * (percent) * (1 - percent)
          ) * this.params.multipleStdError;
        }
      }
    } else {
      for (const key of Object.keys(owsa)) {
        const data = owsa[key];
        for (const sub of Object.keys(data)) {
          const percent = this.inputs.effectiveness[key][sub];
          data[sub] = percent * this.params.percentageBaselineValue;
        }
      }
    }

    return owsa;
  }

  public refreshComponent(): void {
    let path = location.pathname;

    if (path === '/') {
      path = location.hash.replace('#', '');
    }

    this.router.navigate(['semaglutida']).then(() => {
      this.router.navigate([path]);
    });
  }

  public getEffectivenessMap(): Array<IEffectivenessMap> {
    const effectiveness = this.inputs.effectiveness;
    const map = [];

    if (this.params.HbA1c_lte_6_5 && false) {
      map.push({
        label: 'HbA1c ≤6.5%',
        data: effectiveness.HbA1c_lte_6_5,
        key: 'HbA1c_lte_6_5'
      });
    }
    if (this.params.HbA1c_lt_7) {
      map.push({
        label: 'HbA1c <7.0%',
        data: effectiveness.HbA1c_lte_7,
        key: 'HbA1c_lte_7'
      });
    }
    if (this.params.HbA1c_lt_7_NoHipoglucemia) {
      map.push({
        label: ['HbA1c <7.0%', 'sin hipoglucemia', 'y sin ganancia de peso'],
        data: effectiveness.HbA1c_lte_7_no_hipoglicemia,
        key: 'HbA1c_lte_7_no_hipoglicemia'
      });
    }
    if (this.params.weightLoss_5 && false) {
      map.push({
        label: 'Pérdida de peso ≥5%',
        data: effectiveness.weight_loss_gte_5,
        key: 'weight_loss_gte_5'
      });
    }
    if (this.params.weightLoss_7) {
      map.push({
        label: 'Pérdida de peso ≥10%',
        data: effectiveness.weight_loss_gte_10,
        key: 'weight_loss_gte_10'
      });
    }
    if (this.params.HbA1c_reduction) {
      map.push({
        label: ['≥1.0% reducción de HbA1c', 'y ≥3.0% pérdida de peso'],
        data: effectiveness.HbA1c_reduction,
        key: 'HbA1c_reduction'
      });
    }

    return map;
  }

}

