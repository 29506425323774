import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-xultophy-params1',
  templateUrl: './xultophy-params1.component.html',
  styleUrls: ['../../../theme.scss']
})
export class XultophyParams1Component implements OnInit {
  constructor(public api: ApiService) {
  }

  ngOnInit() {
  }

}
