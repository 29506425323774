import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppSettings} from "../../app.settings";
import {CalculatorStatus} from "../../classes/status";
import {ModalComponent} from "../../components/modal/modal.component";

@Component({
  selector: 'app-dose-model',
  templateUrl: './dose-model.component.html',
  styleUrls: ['./dose-model.component.css']
})
export class DoseModelComponent implements OnInit {

  private statusInstance: CalculatorStatus = CalculatorStatus.getInstance();
  calculator_id = AppSettings.DOSE_MODEL_ID;

  constructor() {
    this.statusInstance.calculator = this.calculator_id;
  }

  ngOnInit() {
  }
}
