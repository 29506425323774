import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ModalComponent } from '../../../../components/modal/modal.component';
import {MatDialog} from '@angular/material';

interface InsulinRate {
  insulina_degludec: number;
  insulina_glargina: number;
  modal?: any;
}

@Component({
  selector: '[app-dm-rates]',
  templateUrl: './dm-rates.component.html',
  styleUrls: ['./dm-rates.component.css']
})
export class DmRatesComponent implements OnInit {

  mensaje: string;

  @Input() main_object:
    {
      tasa_base: {tasa: number, se: number, modal?: any},
      proporcion_de_la_tasa: InsulinRate,
      error_estandar: InsulinRate,
      tasa_calculada: InsulinRate
    };
  private mensajes = [
    { title: 'Non severe Day time Hypoglucemia base rate', contenido: 'The base rate of non-severe daytime hypoglycemia (with insulin glargine), expressed in events per 100 patient years. Default Reference: Grupo de estudio de hipoglucemia en Reino Unido (2007) y proporción de uso diurno: Documentación en el archivo'},
    { title: 'Non severe Day time Hypoglucemia rate ratios', contenido: 'Rate ratios for non-severe daytime hypoglycemia, relative to the base rate (insulin glargine) Degludec Reference: Documentación en el archivo (No significativo) Glargine Reference: Documentación irrelevante'},
    { title: 'Non severe Nocturnal Hypoglucemia base rate', contenido: 'The base rate of non-severe nocturnal hypoglycemia (with insulin glargine), expressed in events per 100 patient years. Default Reference: Grupo de estudio de hipoglucemia en Reino Unido (2007) y proporción de uso nocturno: Documentación en el archivo'},
    { title: 'Non severe Nocturnal Hypoglucemia rate ratios', contenido: 'Rate ratios for non-severe nocturnal hypoglycemia, relative to the base rate (insulin glargine) Degludec Reference: Documentación en el archivo Glargine Reference: Documentación irrelevante'},
    { title: 'Severe Hypoglucemia base rate', contenido: 'The base rate of severe hypoglycemia (with insulin glargine), expressed in events per 100 patient years Default Reference: Grupo de estudio de hipoglucemia en Reino Unido (2007)'},
    { title: 'Deglude open termino rate ratios', contenido: 'Rate ratios for severe hypoglycemia, relative to the base rate (insulin glargine) Degludec Reference: Documentación en el archivo Glargine Reference: Documentación irrelevante'},
    { title: 'Deglude open termino 6', contenido: 'prueba de contenido 6'},
  ];
  constructor(private dialog: MatDialog) {}

  ngOnInit() { }

  refresh() {
    (window as any).run_dose_model();
  }

  showmodal(index: number) {
    const mensaje = this.mensajes[index];
    this.dialog.open(ModalComponent, {
      width: '450px',
      data: {title: mensaje.title, message: mensaje.contenido}
    });
  }
}
