import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {LoadScenarioModal} from './load-scenario/load-scenario.component';
import {first} from 'rxjs/operators';
import {ISaveScenario, SaveScenarioModal} from './save-scenario/save-scenario.component';
import {DatabaseManager} from '../../calculator-globals/database/database-manager';

@Injectable()
export class ModalService {
  constructor(private dialog: MatDialog) {}

  public loadScenario<T>(scenarios: Array<T>, db: DatabaseManager): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      let dialogRef: MatDialogRef<LoadScenarioModal>;
      const config = new MatDialogConfig();
      config.disableClose = false;
      dialogRef = this.dialog.open(LoadScenarioModal, config);
      dialogRef.componentInstance.scenarios = scenarios;
      dialogRef.componentInstance.setDbInstance(db);
      dialogRef.afterClosed().pipe(first()).subscribe((sc: T) => {
        resolve(sc);
      });
    });
  }

  public saveScenario<T>(scenarios: Array<T>): Promise<ISaveScenario> {
    return new Promise((resolve, reject) => {
      let dialogRef: MatDialogRef<SaveScenarioModal>;
      const config = new MatDialogConfig();
      config.disableClose = false;
      dialogRef = this.dialog.open(SaveScenarioModal, config);
      dialogRef.componentInstance.scenarios = scenarios.filter(s => (s as any).editable);
      dialogRef.afterClosed().pipe(first()).subscribe((sc: ISaveScenario) => {
        resolve(sc);
      });
    });
  }
}
