import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vials-comparison',
  templateUrl: './vials-comparison.component.html',
  styleUrls: ['./vials-comparison.component.css']
})
export class VialsComparisonComponent implements OnInit {

  outputs: any = {
    Peso_paciente_promedio: 0,
    dosis_completa_de_rVIIa: 0,
    dosis_completa_de_pdAPCC: 0,
    Numero_de_viales_por_dosis_rFVIIa: 0,
    Numero_de_viales_por_dosis_pd_APCC: 0,
  };

  public ChartType: string = 'bar';
  public DataSets: Array<any> = [
    {data: [], label: 'rFVIIa'},
    {data: [], label: 'pd-aPCC'}
  ];

  public Labels: Array<any> = ['Número de viales por dosis'];
  public Legend: boolean = true;
  public chartOptions: any = {
    showDatapoints: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  public color:Array<any> = [
    {
      backgroundColor: '#001965'
    },
    {
      backgroundColor: '#e1e1da'
    }
  ];

  constructor() {
    (window as any).calc_costydoses();

    for (let i in this.outputs) {
      if (this.outputs.hasOwnProperty(i)) {
        this.outputs[i] = window[i];
      }
    }

    this.DataSets[0].data.push(this.getCeil(this.outputs.Numero_de_viales_por_dosis_rFVIIa));
    this.DataSets[1].data.push(this.getCeil(this.outputs.Numero_de_viales_por_dosis_pd_APCC));
  }

  getCeil(number: number) {
    return Math.ceil(number);
  }

  ngOnInit() {
  }

}
