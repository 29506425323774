import { Component, OnInit } from '@angular/core';
import {DatosService} from '../../services/datos.service';
import {RoutingService} from '../../services/routing.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {

  constructor(public data: DatosService, public routes: RoutingService) { }

  ngOnInit() {
  }

}
