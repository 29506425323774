import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-bi',
  templateUrl: './other-bi.component.html',
  styleUrls: ['./other-bi.component.css']
})
export class OtherBiComponent implements OnInit {

  switchPop: number = 0;

  keys = [
    'Averageweight',
    'Averagenumberofmild',
    'TimeHorizon',
    'BleedsPerPatientPerYear',
    'TotalBleedsPerYear',

    'numberofpatients',
    'yearsofnalysis',
    'sizeofthetargetpopulation',
    'VAT_bi',
    'NumberCitizens',

    'MalePopulation',

    'PrevalenceA',
    'InhibitorsA',
    'PrevalenceB',
    'InhibitorsB',

    'targetpopulation',
  ];

  inputs: any = {};

  constructor() {
    (window as any).runOnDemand();

    console.log(window[this.keys[9]], this.keys[9]);

    for (let key of this.keys) {
      this.inputs[key] = window[key];
    }
    console.log(this.inputs[this.keys[9]]);
  }

  switchPopCalculation(value) {
    this.switchPop = value;
  }

  ngOnInit() {
  }

  updateValue(key, value) {
    this.inputs[key] = value;
    window[key] = value;

    (window as any).treatment_cost();

    this.inputs['targetpopulation'] = window['targetpopulation'];

    if(key == 'TimeHorizon') {
      console.log(window[key]);
    }
  }

}
