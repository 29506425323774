import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cost-per-dosis',
  templateUrl: './cost-per-dosis.component.html',
  styleUrls: ['./cost-per-dosis.component.css']
})
export class CostPerDosisComponent implements OnInit {

  outputs: any = {
    Costo_por_dosis_de_rFVIIa: 0,
    Costo_por_dosis_de_pdAPCC: 0,
    Costo_ahorrado_por_usar_rFVIIa: 0,
    Porcentaje_de_ahorro_de_costo: 0,
  };

  public ChartType: string = 'bar';
  public DataSets: Array<any> = [
    {data: [], label: 'rFVIIa'},
    {data: [], label: 'pd-aPCC'}
  ];

  public Labels: Array<any> = ['Costo por dosis'];
  public Legend: boolean = true;
  public chartOptions: any = {
    showDatapointsCurrency: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  public color:Array<any> = [
    {
      backgroundColor: '#001965'
    },
    {
      backgroundColor: '#e1e1da'
    }
  ];

  constructor() {
    (window as any).calc_costydoses();

    for (let i in this.outputs) {
      if (this.outputs.hasOwnProperty(i)) {
        this.outputs[i] = window[i];
      }
    }

    this.DataSets[0].data.push(this.outputs.Costo_por_dosis_de_rFVIIa);
    this.DataSets[1].data.push(this.outputs.Costo_por_dosis_de_pdAPCC);
  }

  ngOnInit() {
  }

}
