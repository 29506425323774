import { Component, OnInit } from '@angular/core';
import {DatosService} from '../../services/datos.service';
import {IEParametros} from '../../models/scenario.class';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent implements OnInit {

  enParams: Array<IEParametros>;
  caParams: Array<IEParametros>;

  constructor(protected data: DatosService) {
    this.enParams = data.getEnParams();
    this.caParams = data.getCaParams();
  }

  ngOnInit() {
  }

}
