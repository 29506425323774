import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as Big from 'big.js'

@Component({
  selector: 'app-decimal-input',
  templateUrl: './decimal-input.component.html',
  styleUrls: ['./decimal-input.component.css']
})
export class DecimalInputComponent implements OnInit, AfterViewInit {

  @Input() init_value: number;
  @Input() init_step: number;

  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  step: any;

  constructor() {
  }

  ngOnInit() {
    if (typeof this.init_step !== 'undefined'){
      this.step = Big(this.init_step);
    } else {
      this.step = Big(1);
    }
  }

  ngAfterViewInit(){
  }

  removeDecimalChars(val) {
    let parsed = parseFloat(val.toString().replace(/[^0-9-]/g, ''));

    if (!isNaN(parsed)) {
      this.valueChanged.emit(parsed);
    }
    else {
      this.valueChanged.emit(0);
    }

    console.log('ival: ', this.init_value);
  }

  changeValue(negative: boolean){
    this.removeDecimalChars(this.init_value);
    let val_big = Big(this.init_value);
    if (negative) {
      val_big = val_big.minus(this.step);
    }
    else {
      val_big = val_big.plus(this.step);
    }
    this.valueChanged.emit(parseFloat(val_big.toString()))
  }
}
