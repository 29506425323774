import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculations-costs',
  templateUrl: './calculations-costs.component.html',
  styleUrls: ['./calculations-costs.component.css']
})
export class CalculationsCostsComponent implements OnInit {

  window_ref = window as any;

  distribution_map = {
    1: "Determinista", 2: "Normal", 3: "Lognormal", 4: "Gamma", 5: "Beta"
  };

  constructor() {
  }

  ngOnInit() {
  }

}
