import {Component, OnInit} from '@angular/core';
import {CalculatorStatus} from '../../../classes/status';

declare var window: any;

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.css']
})
export class InstallComponent implements OnInit {

  private statusInstance: CalculatorStatus = CalculatorStatus.getInstance();

  model: any = {
    a_plazo_previsto: window.a_plazo_previsto,
    b_mortalidad_hipoglucemia_grave: window.b_mortalidad_hipoglucemia_grave,
    d_tasa_de_descuento: window.d_tasa_de_descuento,
    e_tasa_de_clinica: window.e_tasa_de_clinica,
    h_incluye_costos_directos: window.h_incluye_costos_directos
  };

  apr_unit_calc = window.f_aproximacion_calculo_utilidad ? 'f_aproximacion_calculo_utilidad' : 'g_asignar_utilidad_negativa';

  map: Array<string> = [
    'a_plazo_previsto',
    'b_mortalidad_hipoglucemia_grave',
    'd_tasa_de_descuento',
    'e_tasa_de_clinica',
    'f_aproximacion_calculo_utilidad',
    'g_asignar_utilidad_negativa',
    'h_incluye_costos_directos'
  ];

  // tslint:disable-next-line:variable-name
  mhg_options: Array<string> = [
    'Al comenzar', 'Correccion de mitad', 'Al finalizar'
  ];

  constructor() {
  }

  ngOnInit() {
  }

  updateValue(key, value) {
    this.statusInstance.modified = true;
    this.model[key] = value;
    window[key] = value;
  }

  /* Para f_aproximacion_calculo_utilidad - g_asignar_utilidad_negativa */


  radioSwitch(key) {
    window.f_aproximacion_calculo_utilidad = false;
    window.g_asignar_utilidad_negativa = false;

    this.apr_unit_calc = key;
    window[key] = true;
  }

}
