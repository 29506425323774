import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { PatientComponent } from './calculators/dec-tool/patient/patient.component';
import { ProtocolComponent } from './calculators/dec-tool/protocol/protocol.component';
import { TreatmentsComponent } from './calculators/dec-tool/treatments/treatments.component';
import { ClinicalInputsComponent } from './calculators/dec-tool/clinical-inputs/clinical-inputs.component';
import { CostsComponent } from './calculators/dec-tool/costs/costs.component';
import { OtherComponent } from './calculators/dec-tool/other/other.component';
import { CumulativeCostsComponent } from './calculators/dec-tool/cumulative-costs/cumulative-costs.component';
import { TotalCostsComponent } from './calculators/dec-tool/total-costs/total-costs.component';
import { BleedsComponent } from './calculators/dec-tool/bleeds/bleeds.component';
import { DsaComponent } from './calculators/dec-tool/dsa/dsa.component';
import { ReferencesComponentdt } from './calculators/dec-tool/references/references.component';
import { BackgroundComponentdt } from './calculators/dec-tool/background/background.component';
import { CurrentComponent } from './calculators/on-demand/current/current.component';
import { AlternativeComponent } from './calculators/on-demand/alternative/alternative.component';
import { TotalBiComponent } from './calculators/on-demand/total-bi/total-bi.component';
import { BiPerCitizenComponent } from './calculators/on-demand/bi-per-citizen/bi-per-citizen.component';
import { BiPerPatientComponent } from './calculators/on-demand/bi-per-patient/bi-per-patient.component';
import { BiPerBleedComponent } from './calculators/on-demand/bi-per-bleed/bi-per-bleed.component';
import { TornadoComponent } from './calculators/on-demand/tornado/tornado.component';

import { BackgroundComponent } from './calculators/on-demand/background/background.component';
import { CostsBIComponent } from './calculators/on-demand/costs/costs.component';
import { OtherBiComponent } from './calculators/on-demand/other-bi/other-bi.component';
import { InputsComponent } from './calculators/profilaxis/inputs/inputs.component';
import { OutputsProfilaxisComponent } from './calculators/profilaxis/outputs-profilaxis/outputs-profilaxis.component';
import { InputsDosesCostsComponent } from './calculators/doses-costs/inputs-doses-costs/inputs-doses-costs.component';
import { VialsComparisonComponent } from './calculators/doses-costs/vials-comparison/vials-comparison.component';
import { CostPerDosisComponent } from './calculators/doses-costs/cost-per-dosis/cost-per-dosis.component';
import { CompareComponent } from './calculators/doses-costs/compare/compare.component';
import { DebieSetupComponent } from './calculators/debie-budget/debie-setup/debie-setup.component';
import { DebiePopulationComponent } from './calculators/debie-budget/debie-population/debie-population.component';
import { DebieMarketShareComponent } from './calculators/debie-budget/debie-market-share/debie-market-share.component';
import { DebieUnitcostsComponent } from './calculators/debie-budget/debie-unitcosts/debie-unitcosts.component';
import { DebieResourceComponent } from './calculators/debie-budget/debie-resource/debie-resource.component';
import { DebieClinicalComponent } from './calculators/debie-budget/debie-clinical/debie-clinical.component';
import { DebieOutPopulationComponent } from './calculators/debie-budget/debie-out-population/debie-out-population.component';
import { DebieOutCostsComponent } from './calculators/debie-budget/debie-out-costs/debie-out-costs.component';
import { DebieOutClinicalComponent } from './calculators/debie-budget/debie-out-clinical/debie-out-clinical.component';
import { InstallComponent } from './calculators/dose-model/install/install.component';
import { DoseModelClinicalComponent } from './calculators/dose-model/dose-model-clinical/dose-model-clinical.component';
import { DoseModelCostsComponent } from './calculators/dose-model/dose-model-costs/dose-model-costs.component';
import { DoseModelUsesComponent } from './calculators/dose-model/dose-model-uses/dose-model-uses.component';
import { DoseModelQolComponent } from './calculators/dose-model/dose-model-qol/dose-model-qol.component';
import { DoseModelPsaResultsComponent } from './calculators/dose-model/dose-model-psa-results/dose-model-psa-results.component';

import { DoseModelResultsComponent } from './calculators/dose-model/dose-model-results/dose-model-results.component';
import { VictozaInputsComponent } from './calculators/victoza/victoza-inputs/victoza-inputs.component';
import { VictozaResultsComponent } from './calculators/victoza/victoza-results/victoza-results.component';
import { CalculationsComponent } from './calculators/dose-model/calculations/calculations.component';
import { HomeComponent } from '../obesidad/views/home/home.component';
import { DatosService } from '../obesidad/services/datos.service';
import { InputCostosComponent } from '../obesidad/views/input-costos/input-costos.component';
import { ParametrosComponent } from '../obesidad/components/parametros/parametros.component';
import { InputPoblacionComponent } from '../obesidad/views/input-poblacion/input-poblacion.component';
import { CancerCostComponent } from '../obesidad/views/cancer-cost/cancer-cost.component';
import { OverweightLoadComponent } from '../obesidad/views/overweight-load/overweight-load.component';
import { TornadoCostComponent } from '../obesidad/views/tornado-cost/tornado-cost.component';
import { CalParamsComponent } from '../obesidad/views/cal-params/cal-params.component';
import { LegalComponent } from '../obesidad/views/legal/legal.component';
import { ReferencesComponent } from '../obesidad/views/references/references.component';
import { OdReferencesComponent } from './calculators/on-demand/od-references/od-references.component';
import { PaeComponent } from '../obesidad/views/pae/pae.component';
import { semaRoutes } from '../semaglutida/semaglutida.module';
import { ozempicDabRoutes } from '../ozempic-dab/ozempic-dab.module';
import { xultophyRoutes } from '../xultophy/xultophy.module';
import { ozempicCcrRoutes } from 'src/ozempic-ccr/ozempic-ccr.module';

export const appRoutes = [
  {
    path: 'obesidad', children: [
      {
        path: '',
        component: HomeComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'costos', component: InputCostosComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'parametros', component: ParametrosComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'poblacion', component: InputPoblacionComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'cancer-cost', component: CancerCostComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'overweight-load', component: OverweightLoadComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'tornado-cost', component: TornadoCostComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'pae', component: PaeComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'parametros-de-calculo', component: CalParamsComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'referencias', component: ReferencesComponent,
        resolve: {
          data: DatosService
        }
      },
      {
        path: 'legales', component: LegalComponent,
        resolve: {
          data: DatosService
        }
      }
    ]
  },
  { path: 'semaglutida', children: semaRoutes },
  { path: 'ozempic-dab', children: ozempicDabRoutes },
  { path: 'ozempic-ccr', children: ozempicCcrRoutes },
  { path: 'xultophy', children: xultophyRoutes },

  { path: 'decision-tool', redirectTo: 'decision-tool/patient', patchMatch: 'full' },
  { path: 'decision-tool/patient', component: PatientComponent },
  { path: 'decision-tool/protocol', component: ProtocolComponent },
  { path: 'decision-tool/treatments', component: TreatmentsComponent },
  { path: 'decision-tool/clinical-inputs', component: ClinicalInputsComponent },
  { path: 'decision-tool/costs', component: CostsComponent },

  { path: 'decision-tool/other', component: OtherComponent },
  { path: 'decision-tool/cumulative-costs', component: CumulativeCostsComponent },
  { path: 'decision-tool/total-costs', component: TotalCostsComponent },
  { path: 'decision-tool/bleeds', component: BleedsComponent },
  { path: 'decision-tool/dsa', component: DsaComponent },

  { path: 'decision-tool/references', component: ReferencesComponentdt },
  { path: 'decision-tool/background', component: BackgroundComponentdt },

  { path: 'on-demand', redirectTo: 'on-demand/current', patchMatch: 'full' },
  { path: 'on-demand/current', component: CurrentComponent },
  { path: 'on-demand/alternative', component: AlternativeComponent },
  { path: 'on-demand/costs', component: CostsBIComponent },
  { path: 'on-demand/other-bi', component: OtherBiComponent },

  { path: 'on-demand/total-bi', component: TotalBiComponent },
  { path: 'on-demand/bi-per-citizen', component: BiPerCitizenComponent },
  { path: 'on-demand/bi-per-patient', component: BiPerPatientComponent },
  { path: 'on-demand/bi-per-bleed', component: BiPerBleedComponent },
  { path: 'on-demand/tornado', component: TornadoComponent },

  { path: 'on-demand/references', component: OdReferencesComponent },
  { path: 'on-demand/background', component: BackgroundComponent },

  { path: 'profilaxis', redirectTo: 'profilaxis/inputs', patchMatch: 'full' },
  { path: 'profilaxis/inputs', component: InputsComponent },
  { path: 'profilaxis/outputs', component: OutputsProfilaxisComponent },

  { path: 'doses-costs', redirectTo: 'doses-costs/inputs', patchMatch: 'full' },
  { path: 'doses-costs/inputs', component: InputsDosesCostsComponent },
  { path: 'doses-costs/vials', component: VialsComparisonComponent },
  { path: 'doses-costs/cost-per-dose', component: CostPerDosisComponent },
  { path: 'doses-costs/compare', component: CompareComponent },

  { path: 'debie-budget', redirectTo: 'debie-budget/setup', patchMatch: 'full' },
  { path: 'debie-budget/setup', component: DebieSetupComponent },
  { path: 'debie-budget/population', component: DebiePopulationComponent },
  { path: 'debie-budget/market-share', component: DebieMarketShareComponent },
  { path: 'debie-budget/unit-costs', component: DebieUnitcostsComponent },
  { path: 'debie-budget/resource-use', component: DebieResourceComponent },
  { path: 'debie-budget/clinical', component: DebieClinicalComponent },
  { path: 'debie-budget/out-population', component: DebieOutPopulationComponent },
  { path: 'debie-budget/costs', component: DebieOutCostsComponent },
  { path: 'debie-budget/out-clinical', component: DebieOutClinicalComponent },

  { path: 'dose-model', redirectTo: 'dose-model/install', patchMatch: 'full' },
  { path: 'dose-model/install', component: InstallComponent },
  { path: 'dose-model/clinical', component: DoseModelClinicalComponent },
  { path: 'dose-model/costs', component: DoseModelCostsComponent },
  { path: 'dose-model/uses', component: DoseModelUsesComponent },
  { path: 'dose-model/qol', component: DoseModelQolComponent },
  { path: 'dose-model/results', component: DoseModelResultsComponent },
  { path: 'dose-model/calculations', component: CalculationsComponent },
  { path: 'dose-model/psa', component: DoseModelPsaResultsComponent },

  { path: 'victoza', redirectTo: 'victoza/inputs', patchMatch: 'full' },
  { path: 'victoza/inputs', component: VictozaInputsComponent },
  { path: 'victoza/results', component: VictozaResultsComponent },

  { path: '', component: MainMenuComponent },

  { path: '**', redirectTo: '', patchMatch: 'full' }
];
