export class CalculatorStatus {
  private static _instance: CalculatorStatus = new CalculatorStatus();

  constructor() {
    if (CalculatorStatus._instance) {
      throw new Error('Do not use "new" with a Singleton Class');
    }
    CalculatorStatus._instance = this;
  }

  private _modified: boolean = false;
  private _calculator: string;

  set modified(value: boolean) {
    this._modified = value;
  }
  get modified(): boolean {
    return this._modified;
  }
  set calculator(value: string) {
    if(value !== this._calculator) {
      this.modified = true;
    }
    this._calculator = value;
  }
  get calculator(): string {
    return this._calculator;
  }

  public static getInstance() {return this._instance};
}