import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-header-refs',
    templateUrl: './header-refs.component.html',
    styleUrls: ['./header-refs.component.css']
})
export class HeaderRefsComponent implements OnInit {

    @Input() title: string;
    @Input() back: string;

    constructor() {
    }

    ngOnInit() {
    }

}
