import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DatosService} from './services/datos.service';
import {ChartsModule} from 'ng2-charts';
import {DatabaseService} from './database/database.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalModule} from './modals/modal.module';
import {RoutingService} from './services/routing.service';
import {CurrencyMaskModule} from 'ng2-currency-mask';

export function loadDatabase(database: DatabaseService): () => Promise<any> {
  return () => {
    return new Promise((resolve, reject) => {
      database.init().then(() => {
        resolve();
      });
    });
  };
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    FormsModule,
    ChartsModule,
    ModalModule,
    BrowserAnimationsModule,
    CurrencyMaskModule
  ],
  providers: [
    DatabaseService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadDatabase,
      multi: true,
      deps: [DatabaseService]
    },
    DatosService,
    RoutingService
  ],
  exports: [
    BrowserModule,
    FormsModule,
    ChartsModule,
    ModalModule,
    BrowserAnimationsModule,
    CurrencyMaskModule
  ]
})
export class CalcModule {
}
