import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";
import {AppSettings} from "../../../app.settings";

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponentdt {

}
