import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {SemaDataService} from '../../services/sema-data.service';
import {getYearlyCosts, showWithComma} from '../../global/globalFunctions';

@Component({
    selector: 'app-costo-tratamiento',
    templateUrl: './costo-tratamiento.component.html',
    styleUrls: ['../../theme.scss']
})
export class CostoTratamientoComponent implements OnInit {
    // ########## Casos diagnosticados ##########
    // ##########################################

    public barChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            borderWidth: 1,
            titleFontColor: '#000000',
            bodyFontColor: '#000000',
            callbacks: {
                title: item => item[0].label.replace(/,/g, ' '),
                label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += showWithComma(Number(tooltipItem.yLabel).toFixed(0));
                    return label;
                }
            }
        },
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Costo anual en millones (COP)'
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value, index, values) => {
                        const ret = (value / 1000000).toFixed(0);
                        return `$${ret} mill.`;
                    }
                },
                stacked: true
            }],
            xAxes: [{
                stacked: true,
                barPercentage: 0.4
            }]
        },

    };
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: ChartDataSets[] = [];

    public totals: any[] = [];

    // public divider: number;

    constructor(protected data: SemaDataService) {

        const semaglutidaDataset = {
            data: [],
            label: 'Costo anual Semaglutida (COP)',
            backgroundColor: '#39B0C1',
            borderColor: '#39B0C1',
            hoverBackgroundColor: '#1790A0',
            pointHoverBackgroundColor: '#FFFFFF'
        };
        const dulaglutidaDataset = {
            data: [],
            label: 'Costo anual Dulaglutida (COP)',
            backgroundColor: '#b470c1',
            borderColor: '#b470c1',
            hoverBackgroundColor: '#a01e9b',
            pointHoverBackgroundColor: '#FFFFFF'
        };

        const yearlyCosts = getYearlyCosts(data.inputs, data.params);

        semaglutidaDataset.data = yearlyCosts.semaglutida;
        dulaglutidaDataset.data = yearlyCosts.dulaglutida;
        this.totals = yearlyCosts.totals;
        this.barChartLabels = yearlyCosts.labels;
        this.barChartData.push(semaglutidaDataset);
        this.barChartData.push(dulaglutidaDataset);

        if (data.params.includeCostConsumable) {
            const needlesDataset = {
                data: yearlyCosts.needles,
                label: 'Costo anual agujas (COP)',
                backgroundColor: '#b0bfbf',
                borderColor: '#b0bfbf',
                hoverBackgroundColor: '#8b9c8f',
                pointHoverBackgroundColor: '#FFFFFF'
            };
            const smbgDataset = {
                data: yearlyCosts.smbg,
                label: 'Costo anual SMBG (COP)',
                backgroundColor: '#70c184',
                borderColor: '#70c184',
                hoverBackgroundColor: '#1ea03a',
                pointHoverBackgroundColor: '#FFFFFF'
            };
            this.barChartData.push(needlesDataset, smbgDataset);
        }
        if (data.params.includeCostAdditional) {
            const additionalDataset = {
                data: yearlyCosts.additional,
                label: 'Costos adicionales anuales (COP)',
                backgroundColor: '#c1b970',
                borderColor: '#c1b970',
                hoverBackgroundColor: '#97a01e',
                pointHoverBackgroundColor: '#FFFFFF'
            };
            this.barChartData.push(additionalDataset);
        }
    }

    ngOnInit() {
    }

}
