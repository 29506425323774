import { Component, OnInit } from '@angular/core';
import { ModalComponent} from '../../../components/modal/modal.component';

@Component({
  selector: 'app-dose-model-costs',
  templateUrl: './dose-model-costs.component.html',
  styleUrls: ['./dose-model-costs.component.css']
})
export class DoseModelCostsComponent implements OnInit {
  costos_de_la_insulina = window['costos_de_la_insulina'];
  costos_de_la_insulina_map = [
    {key: 'insulina_inicial_precio_paquete', title: 'Precio del paquete (COP)', modal: 1},
    {key: 'insulina_inicial_unidades_por_paquete', title: 'Unidades por paquete (Unidad Internacional, UI)', modal: 2},
    {key: 'insulina_inicial_precio_por_ui', title: 'Precio por UI (COP)', disabled: 'both'},
    {key: 'insulina_en_bolo_precio_paquete', title: 'Precio del paquete (COP)', modal: 3},
    {key: 'insulina_en_bolo_unidades_por_paquete', title: 'Unidades por paquete (Unidad Internacional, UI)', modal: 4},
    {key: 'insulina_en_bolo_precio_por_ui', title: 'Precio por UI (COP)', disabled: 'both'}
  ];
  costos_de_la_insulina_titles = {
    0: 'Insulina inicial',
    3: 'Insulina en bolo'
  };

  costos_de_las_agujas = window['costos_de_las_agujas'];
  costos_de_las_agujas_map = [
    {key: 'agujas_inicial_precio_paquete', title: 'Precio del paquete (COP)', modal: 5},
    {key: 'agujas_inicial_agujas_por_paquete', title: 'Agujas por paquete', modal: 6},
    {key: 'agujas_inicial_precio_por_aguja', title: 'Precio por aguja (COP)', disabled: 'both'},
    {key: 'agujas_en_bolo_precio_paquete', title: 'Precio del paquete (COP)', disabled: 'single', modal: 7},
    {key: 'agujas_en_bolo_agujas_por_paquete', title: 'Agujas por paquete', disabled: 'single', modal: 8},
    {key: 'agujas_en_bolo_precio_por_aguja', title: 'Precio por aguja (COP)', disabled: 'both'},
  ];
  costos_de_las_agujas_titles = {
    0: 'Agujas para la inyección de insulina inicial',
    3: 'Agujas para la inyección de insulina en bolo'
  };

  costos_de_las_pruebas_smbg = window['costos_de_las_pruebas_smbg'];
  costos_de_las_pruebas_smbg_map = [
    {key: 'smbg_precio_paquete_tiras_de_prueba', title: 'Precio del paquete de tiras de prueba (COP)', disabled: 'single', modal: 9},
    {key: 'smbg_tiras_de_prueba', title: 'Tiras de prueba por paquete', disabled: 'single', modal: 10},
    {key: 'smbg_precio_por_tiras_de_prueba', title: 'Precio por tira de prueba (COP)', disabled: 'both'},
    {key: 'smbg_precio_paquete_lancetas', title: 'Precio del paquete de lancetas (COP)', disabled: 'single', modal: 11},
    {key: 'smbg_lancetas_por_paquete', title: 'Lancetas por paquete', disabled: 'single', modal: 12},
    {key: 'smbg_precio_por_lanceta', title: 'Precio por lanceta (COP)', disabled: 'both'},
    {key: 'smbg_costo_total_prueba', title: 'Costo total por prueba (COP)', disabled: 'both'}
  ];
  costos_de_las_pruebas_smbg_titles = {
    0: 'Costos de las tiras de prueba de SMBG',
    3: 'Costos de las lancetas'
  };

  costos_episodios_hipoglucemia = window['costos_episodios_hipoglucemia'];
  costos_episodios_hipoglucemia_map = [
    {key: 'costos_diurna', title: 'Costo de asistencia sanitaria de un episodio de hipoglucemia diurna no grave (COP)', modal: 13},
    {key: 'costos_diurna_se', title: 'SE'},
    {key: 'costos_nocturna', title: 'Costo de asistencia sanitaria de episodios de hipoglucemia nocturna no grave (COP)', modal: 14},
    {key: 'costos_nocturna_se', title: 'SE'},
    {key: 'costos_hipoglucemia_grave', title: 'Costo de la asistencia sanitaria de un episodio de hipoglucemia grave (GBP)', modal: 15},
    {key: 'costos_hipoglucemia_grave_se', title: 'SE'}
  ];

  costos_tratamiento_adicional = window['costos_tratamiento_adicional'];
  costos_tratamiento_adicional_map = [
    {key: 'costos_iniciales', title: 'Costos iniciales adicionales asociados con el tratamiento (COP)'},
    {key: 'costos_anules', title: 'Costos anuales adicionales asociados con el tratamiento (COP)'}
  ];

  ngOnInit() {
  }

}
