import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debie-out-population',
  templateUrl: './debie-out-population.component.html',
  styleUrls: ['./debie-out-population.component.css']
})
export class DebieOutPopulationComponent implements OnInit {

  population_type_one_debie_model: Array<any>;
  population_type_two_debie_model_basal_oral: Array<any>;
  population_type_two_debie_model_basal_bolus: Array<any>;

  show_breakdown: boolean = true;

  constructor() {
    (window as any).run();
    this.population_type_one_debie_model = window['population_type_one_debie_model'];
    this.population_type_two_debie_model_basal_oral = window['population_type_two_debie_model_basal_oral'];
    this.population_type_two_debie_model_basal_bolus = window['population_type_two_debie_model_basal_bolus'];
  }

  ngOnInit() {
    console.log(this.population_type_one_debie_model);
  }

}
