import { Component, OnInit } from '@angular/core';
import {AppSettings} from "../../app.settings";

@Component({
  selector: 'app-victoza',
  templateUrl: './victoza.component.html',
  styleUrls: ['./victoza.component.css']
})
export class VictozaComponent implements OnInit {

  calculator_id = AppSettings.VICTOZA_ID;

  constructor() { }

  ngOnInit() {
  }

}
