import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-alternative',
  templateUrl: './alternative.component.html',
  styleUrls: ['./alternative.component.css']
})
export class AlternativeComponent implements OnInit {

  input_keys_current: string[] = [
    'TxLine1Current',
    'TxRebleed1Current',
    'TxLine2Current',
    'TxRebleed2Current',
    'TxLine3Current'
  ];

  input_keys_alternative: string[] = [
    'TxLine1Alternative',
    'TxRebleed1Alternative',
    'TxLine2Alternative',
    'TxRebleed2Alternative',
    'TxLine3Alternative'
  ];

  options: string[] = [
    "NovoSeven_home",
    "NovoSeven_hospital",
    "pd_aPCC_home",
    "pd_aPCC_hospital"
  ];

  inputs: any = {};

  inpatient: string[] = [
    'DaysHospitalLine1Current',
    'DaysHospitalLine2Current',
    'DaysHospitalLine3Current',

    'DaysHospitalLine1Alternative',
    'DaysHospitalLine2Alternative',
    'DaysHospitalLine3Alternative',
  ];

  outpatient: string[] = [
    'NumberVisitsLine1Current',
    'NumberVisitsLine2Current',
    'NumberVisitsLine3Current',

    'NumberVisitsLine1Alternative',
    'NumberVisitsLine2Alternative',
    'NumberVisitsLine3Alternative'
  ];

  constructor(private ws: WindowService) {
    let js_window =  this.ws.nativeWindow;

    for (let key of this.input_keys_current) {
      this.inputs[key] = js_window[key];
    }
    for (let key of this.input_keys_alternative) {
      this.inputs[key] = js_window[key];
    }
    for (let key of this.inpatient) {
      this.inputs[key] = js_window[key];
    }
    for (let key of this.outpatient) {
      this.inputs[key] = js_window[key];
    }
    console.log(this.inputs.TxLine1Alternative.opcion);
  }

  updateValue(key1, key2, value) {
    let js_window =  this.ws.nativeWindow;

    js_window[key1][key2] = value;
    this.inputs[key1][key2] = value;
  }

  run(){
    let js_window =  this.ws.nativeWindow;

    js_window.runOnDemand();                       // inicializa parametros.
  }

  ngOnInit() {
  }

}
