import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {ChartsModule} from 'ng2-charts';

import {AppComponent} from './app.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ContentComponent} from './components/content/content.component';
import {MainMenuComponent} from './components/main-menu/main-menu.component';

import {appRoutes} from './app.routes';
import {NumericInputComponent} from './components/numeric-input/numeric-input.component';
import {DecimalInputComponent} from './components/decimal-input/decimal-input.component';
import {TableInputComponent} from './components/table-input/table-input.component';
import {GetByIndexPipe} from './pipes/get-by-index.pipe';
import {DecToolComponent} from './calculators/dec-tool/dec-tool.component';
import {PercentInputComponent} from './components/percent-input/percent-input.component';
import {PatientComponent} from './calculators/dec-tool/patient/patient.component';
import {ProtocolComponent} from './calculators/dec-tool/protocol/protocol.component';

import {WindowService} from './services/window.service';
import {TreatmentsComponent} from './calculators/dec-tool/treatments/treatments.component';
import {ClinicalInputsComponent} from './calculators/dec-tool/clinical-inputs/clinical-inputs.component';
import {CostsComponent} from './calculators/dec-tool/costs/costs.component';
import {OtherComponent} from './calculators/dec-tool/other/other.component';
import {CumulativeCostsComponent} from './calculators/dec-tool/cumulative-costs/cumulative-costs.component';
import {TotalCostsComponent} from './calculators/dec-tool/total-costs/total-costs.component';
import {BleedsComponent} from './calculators/dec-tool/bleeds/bleeds.component';
import {DsaComponent} from './calculators/dec-tool/dsa/dsa.component';
import {ReferencesComponentdt} from './calculators/dec-tool/references/references.component';
import {BackgroundComponentdt} from './calculators/dec-tool/background/background.component';

import {OnDemandComponent} from './calculators/on-demand/on-demand.component';
import {CurrentComponent} from './calculators/on-demand/current/current.component';
import {AlternativeComponent} from './calculators/on-demand/alternative/alternative.component';
import {TotalBiComponent} from './calculators/on-demand/total-bi/total-bi.component';
import {BiPerCitizenComponent} from './calculators/on-demand/bi-per-citizen/bi-per-citizen.component';
import {BiPerPatientComponent} from './calculators/on-demand/bi-per-patient/bi-per-patient.component';
import {BiPerBleedComponent} from './calculators/on-demand/bi-per-bleed/bi-per-bleed.component';
import {TornadoComponent} from './calculators/on-demand/tornado/tornado.component';

import {BackgroundComponent} from './calculators/on-demand/background/background.component';
import {ScenarioTreeComponent} from './calculators/on-demand/scenario-tree/scenario-tree.component';
import {CostsBIComponent} from './calculators/on-demand/costs/costs.component';
import {OtherBiComponent} from './calculators/on-demand/other-bi/other-bi.component';
import {ProfilaxisComponent} from './calculators/profilaxis/profilaxis.component';
import {InputsComponent} from './calculators/profilaxis/inputs/inputs.component';
import {OutputsProfilaxisComponent} from './calculators/profilaxis/outputs-profilaxis/outputs-profilaxis.component';
import {DosesCostsComponent} from './calculators/doses-costs/doses-costs.component';
import {InputsDosesCostsComponent} from './calculators/doses-costs/inputs-doses-costs/inputs-doses-costs.component';
import {VialsComparisonComponent} from './calculators/doses-costs/vials-comparison/vials-comparison.component';
import {CostPerDosisComponent} from './calculators/doses-costs/cost-per-dosis/cost-per-dosis.component';
import {CompareComponent} from './calculators/doses-costs/compare/compare.component';
import {DebieBudgetComponent} from './calculators/debie-budget/debie-budget.component';
import {DebieSetupComponent} from './calculators/debie-budget/debie-setup/debie-setup.component';
import {DebiePopulationComponent} from './calculators/debie-budget/debie-population/debie-population.component';
import {DebieMarketShareComponent} from './calculators/debie-budget/debie-market-share/debie-market-share.component';
import {DebieUnitcostsComponent} from './calculators/debie-budget/debie-unitcosts/debie-unitcosts.component';
import {CostsTableComponent} from './calculators/debie-budget/debie-unitcosts/costs-table/costs-table.component';
import {IterateObjectPipe} from './pipes/iterate-object.pipe';
import {DebieResourceComponent} from './calculators/debie-budget/debie-resource/debie-resource.component';
import {TabContentComponent} from './calculators/debie-budget/debie-resource/tab-content/tab-content.component';
import {DebieClinicalComponent} from './calculators/debie-budget/debie-clinical/debie-clinical.component';
import {TabContentDcComponent} from './calculators/debie-budget/debie-clinical/tab-content-dc/tab-content-dc.component';
import {MsScenarioComponent} from './calculators/debie-budget/debie-market-share/ms-scenario/ms-scenario.component';
import {DebieOutPopulationComponent} from './calculators/debie-budget/debie-out-population/debie-out-population.component';
import {DbPopTypeComponent} from './calculators/debie-budget/debie-out-population/db-pop-type/db-pop-type.component';
import {DebieOutCostsComponent} from './calculators/debie-budget/debie-out-costs/debie-out-costs.component';
import {DbCostsBreakdownComponent} from './calculators/debie-budget/debie-out-costs/db-costs-breakdown/db-costs-breakdown.component';
import {DebieCostsGraphicsComponent} from './calculators/debie-budget/debie-out-costs/debie-costs-graphics/debie-costs-graphics.component';
import {DebieOutClinicalComponent} from './calculators/debie-budget/debie-out-clinical/debie-out-clinical.component';
import {DbClinicalOutcomeComponent} from './calculators/debie-budget/debie-out-clinical/db-clinical-outcome/db-clinical-outcome.component';
import {DoseModelComponent} from './calculators/dose-model/dose-model.component';
import {InstallComponent} from './calculators/dose-model/install/install.component';
import {DoseModelClinicalComponent} from './calculators/dose-model/dose-model-clinical/dose-model-clinical.component';
import {DmRatesComponent} from './calculators/dose-model/dose-model-clinical/dm-rates/dm-rates.component';
import {DoseModelCostsComponent} from './calculators/dose-model/dose-model-costs/dose-model-costs.component';
import {DmInsulinCostsComponent} from './calculators/dose-model/dose-model-costs/dm-insulin-costs/dm-insulin-costs.component';
import {DoseModelUsesComponent} from './calculators/dose-model/dose-model-uses/dose-model-uses.component';
import {DmUsesInputComponent} from './calculators/dose-model/dose-model-uses/dm-uses-input/dm-uses-input.component';
import {DoseModelQolComponent} from './calculators/dose-model/dose-model-qol/dose-model-qol.component';
import {DmUtilityTreatmentComponent} from './calculators/dose-model/dose-model-qol/dm-utility-treatment/dm-utility-treatment.component';
import {
  DmEventNegativeUtilityComponent
} from './calculators/dose-model/dose-model-qol/dm-event-negative-utility/dm-event-negative-utility.component';
import {DoseModelResultsComponent} from './calculators/dose-model/dose-model-results/dose-model-results.component';
import {DmResultResumeComponent} from './calculators/dose-model/dose-model-results/dm-result-resume/dm-result-resume.component';
import {DmResultDiagramComponent} from './calculators/dose-model/dose-model-results/dm-result-diagram/dm-result-diagram.component';
import {VictozaComponent} from './calculators/victoza/victoza.component';
import {VictozaInputsComponent} from './calculators/victoza/victoza-inputs/victoza-inputs.component';
import {VictozaResultsComponent} from './calculators/victoza/victoza-results/victoza-results.component';
import {CalculationsComponent} from './calculators/dose-model/calculations/calculations.component';
import {CalculationsCostsComponent} from './calculators/dose-model/calculations/calculations-costs/calculations-costs.component';
import {CalculationsClinicalComponent} from './calculators/dose-model/calculations/calculations-clinical/calculations-clinical.component';
import {
  CalculationsQualityOfLifeComponent
} from './calculators/dose-model/calculations/calculations-quality-of-life/calculations-quality-of-life.component';
import {
  DoseModelPsaResultsComponent
} from './calculators/dose-model/dose-model-psa-results/dose-model-psa-results.component';
import {DmResultPsaComponent} from './calculators/dose-model/dose-model-results/dm-result-psa/dm-result-psa.component';
import {ModalComponent} from './components/modal/modal.component';
import {HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from '../obesidad/material.module';
import {CalcModule} from '../obesidad/calc.module';
import {HomeComponent} from '../obesidad/views/home/home.component';
import {AsideComponent} from '../obesidad/components/aside/aside.component';
import {InputCostosComponent} from '../obesidad/views/input-costos/input-costos.component';
import {ParametrosComponent} from '../obesidad/components/parametros/parametros.component';
import {InputPoblacionComponent} from '../obesidad/views/input-poblacion/input-poblacion.component';
import {CancerCostComponent} from '../obesidad/views/cancer-cost/cancer-cost.component';
import {OverweightLoadComponent} from '../obesidad/views/overweight-load/overweight-load.component';
import {TornadoCostComponent} from '../obesidad/views/tornado-cost/tornado-cost.component';
import {PaeComponent} from '../obesidad/views/pae/pae.component';
import {CalParamsComponent} from '../obesidad/views/cal-params/cal-params.component';
import {FooterComponent} from '../obesidad/components/footer/footer.component';
import {LegalComponent} from '../obesidad/views/legal/legal.component';
import {HeaderComponent} from '../obesidad/components/header/header.component';
import {HeaderRefsComponent} from '../obesidad/components/header-refs/header-refs.component';
import {DividerP} from '../obesidad/pipes/dividerPipe.pipe';
import {ReferencesComponent} from '../obesidad/views/references/references.component';
import {OdReferencesComponent} from './calculators/on-demand/od-references/od-references.component';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {SemaglutidaModule} from '../semaglutida/semaglutida.module';
import {OzempicDabModule} from '../ozempic-dab/ozempic-dab.module';
import {XultophyModule} from '../xultophy/xultophy.module';
import { OzempicCcrModule } from 'src/ozempic-ccr/ozempic-ccr.module';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ContentComponent,
    MainMenuComponent,
    NumericInputComponent,
    DecimalInputComponent,
    TableInputComponent,
    GetByIndexPipe,
    DecToolComponent,
    PercentInputComponent,
    PatientComponent,
    ProtocolComponent,
    TreatmentsComponent,
    ClinicalInputsComponent,
    CostsComponent,
    OtherComponent,
    CumulativeCostsComponent,
    TotalCostsComponent,
    BleedsComponent,
    DsaComponent,
    ReferencesComponentdt,
    BackgroundComponentdt,
    OnDemandComponent,
    CurrentComponent,
    AlternativeComponent,
    TotalBiComponent,
    BiPerCitizenComponent,
    BiPerPatientComponent,
    BiPerBleedComponent,
    TornadoComponent,
    OdReferencesComponent,
    BackgroundComponent,
    ScenarioTreeComponent,
    CostsBIComponent,
    OtherBiComponent,
    ProfilaxisComponent,
    InputsComponent,
    OutputsProfilaxisComponent,
    DosesCostsComponent,
    InputsDosesCostsComponent,
    VialsComparisonComponent,
    CostPerDosisComponent,
    CompareComponent,
    DebieBudgetComponent,
    DebieSetupComponent,
    DebiePopulationComponent,
    DebieMarketShareComponent,
    DebieUnitcostsComponent,
    CostsTableComponent,
    IterateObjectPipe,
    DebieResourceComponent,
    TabContentComponent,
    DebieClinicalComponent,
    TabContentDcComponent,
    MsScenarioComponent,
    DebieOutPopulationComponent,
    DbPopTypeComponent,
    DebieOutCostsComponent,
    DbCostsBreakdownComponent,
    DebieCostsGraphicsComponent,
    DebieOutClinicalComponent,
    DbClinicalOutcomeComponent,
    DoseModelComponent,
    InstallComponent,
    DoseModelClinicalComponent,
    DmRatesComponent,
    DoseModelCostsComponent,
    DmInsulinCostsComponent,
    DoseModelUsesComponent,
    DmUsesInputComponent,
    DoseModelQolComponent,
    DmUtilityTreatmentComponent,
    DmEventNegativeUtilityComponent,
    DoseModelResultsComponent,
    DmResultResumeComponent,
    DmResultDiagramComponent,
    VictozaComponent,
    VictozaInputsComponent,
    VictozaResultsComponent,
    CalculationsComponent,
    CalculationsCostsComponent,
    CalculationsClinicalComponent,
    CalculationsQualityOfLifeComponent,
    DoseModelPsaResultsComponent,
    DmResultPsaComponent,
    ModalComponent,
    ConfirmModalComponent,
    // OBESIDAD
    HomeComponent,
    AsideComponent,
    InputCostosComponent,
    ParametrosComponent,
    InputPoblacionComponent,
    CancerCostComponent,
    OverweightLoadComponent,
    TornadoCostComponent,
    PaeComponent,
    CalParamsComponent,
    FooterComponent,
    ReferencesComponent,
    LegalComponent,
    HeaderComponent,
    HeaderRefsComponent,
    DividerP
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    MaterialModule,
    CalcModule,
    SemaglutidaModule,
    OzempicDabModule,
    OzempicCcrModule,
    XultophyModule,
    RouterModule.forRoot(appRoutes, {useHash: true}),
    BrowserAnimationsModule
  ],
  entryComponents: [
    ModalComponent,
    ConfirmModalComponent
  ],
  providers: [WindowService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
