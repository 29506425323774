import {Component, Input} from '@angular/core';
import {BarChart} from '../../models/charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: 'bar-chart.component.html'
})
export class BarChartComponent {
  @Input() public width = '500px';
  @Input() public height = '600px';
  @Input() public barChart: BarChart;
}
