import {Component, OnInit} from '@angular/core';
import { ModalComponent} from '../../../components/modal/modal.component';
import {MatDialog} from '@angular/material';

interface NegativeUtilityEventMapping {
  title: string;
  modal: number;
  subsections: Array<{subtitle: string, key: string, subkeys: Array<string>}>;
}

@Component({
  selector: 'app-dose-model-qol',
  templateUrl: './dose-model-qol.component.html',
  styleUrls: ['./dose-model-qol.component.css']
})
export class DoseModelQolComponent {

  private mensajes = [
    { title: 'uno', contenido: 'contenido uno'},
    { title: 'dos', contenido: 'contenido dos'},
    { title: 'tres', contenido: 'contenido tres'},
    { title: 'cuatro', contenido: 'contenido cuatro'},
  ];

  tabs = {
    treatment: ['block', true],
    event: ['none', false]
  };

  model = {calidad_de_vida_al_inicio: window['calidad_de_vida_al_inicio']};

  /* Utilidad relacionada con el tratamiento */
  ganancia_anual_utilidades_tratamiento = window['ganancia_anual_utilidades_tratamiento'];
  ganancia_anual_utilidades_flexibilidad_tratamiento = window['ganancia_anual_utilidades_flexibilidad_tratamiento'];
  ganancia_otras_caracteristicas_tratamiento = window['ganancia_otras_caracteristicas_tratamiento'];

  /* Asignar utilidad negativa a eventos */
  episodio_hipoglucemia_no_grave = window['episodio_hipoglucemia_no_grave'];
  evento_hipoglucemia_grave = window['evento_hipoglucemia_grave'];
  pruebas_automonitoreo_glucemia = window['pruebas_automonitoreo_glucemia'];
  flexibilidad_de_la_administracion = window['flexibilidad_de_la_administracion'];

  subkeys_negative_utility_event = ['utilidad', 'se'];

  episodio_hipoglucemia_no_grave_map: NegativeUtilityEventMapping = {
    title: 'Episodio de hipoglucemia no graves',
    modal: 0,
    subsections: [
      {
        subtitle: 'Utilidad negativa diurna',
        key: 'utilidad_negativa_diurna',
        subkeys: this.subkeys_negative_utility_event
      },
      {
        subtitle: 'Utilidad negativa nocturna',
        key: 'utilidad_negativa_nocturna',
        subkeys: this.subkeys_negative_utility_event
      },
    ]
  };
  evento_hipoglucemia_grave_map: NegativeUtilityEventMapping = {
    title: 'Episodio de hipoglucemia grave',
    modal: 1,
    subsections: [
      {
        subtitle: 'Utilidad negativa',
        key: 'utilidad_negativa',
        subkeys: this.subkeys_negative_utility_event
      }
    ]
  };
  pruebas_automonitoreo_glucemia_map: NegativeUtilityEventMapping = {
    title: 'Pruebas de automonitoreo de glucemia',
    modal: 2,
    subsections: [
      {
        subtitle: 'Utilidad negativa por prueba',
        key: 'utilidad_negativa',
        subkeys: this.subkeys_negative_utility_event
      }
    ]
  };
  flexibilidad_de_la_administracion_map: NegativeUtilityEventMapping = {
    title: 'Flexibilidad de la administración',
    modal: 3,
    subsections: [
      {
        subtitle: 'Utilidad negativa por tratamiento inflexible',
        key: 'utilidad_negativa',
        subkeys: this.subkeys_negative_utility_event
      }
    ]
  };

  constructor(private dialog: MatDialog) {}

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      }
      else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

  updateValue(key, value) {
    this.model[key] = value;
    window[key] = value;
  }

  showmodal(index: number) {
    const mensaje = this.mensajes[index];
    this.dialog.open(ModalComponent, {
      width: '450px',
      data: {title: mensaje.title, message: mensaje.contenido}
    });
  }
}
