import { Component, OnInit } from '@angular/core';
import {DatosService} from '../../services/datos.service';
import {RoutingService} from '../../services/routing.service';

@Component({
  selector: 'app-cal-params',
  templateUrl: './cal-params.component.html',
  styleUrls: ['./cal-params.component.css']
})
export class CalParamsComponent implements OnInit {

  active: string;

  constructor(public data: DatosService, public routes: RoutingService) {
    this.active = 'eic';
  }

  ngOnInit() {
  }

}
