import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-xultophy-params3',
  templateUrl: './xultophy-params3.component.html',
  styleUrls: ['../../../theme.scss']
})
export class XultophyParams3Component implements OnInit {

  constructor(public api: ApiService) { }

  ngOnInit() {
  }

}
