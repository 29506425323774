import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {Scenario} from '../../models/scenario.class';
import {DatabaseManager} from '../../../calculator-globals/database/database-manager';

@Component({
  selector: 'app-modal-load-scenario',
  templateUrl: './load-scenario.component.html'
})
// tslint:disable-next-line:component-class-suffix
export class LoadScenarioModal {
  public scenarios: Array<any>;
  public loading: boolean;
  private db: DatabaseManager;

  constructor(public dialog: MatDialogRef<LoadScenarioModal>) {
    this.scenarios = [];
    this.loading = false;
  }

  public setDbInstance(db: DatabaseManager) {
    this.db = db;
  }

  public setSelected(sc: Scenario) {
    if (sc) {
      this.dialog.close(sc);
    }
  }

  public deleteItem(key) {
    const scenario = this.scenarios.find(item => item.getKey() === key);
    // console.log(scenario);
    this.scenarios.splice(this.scenarios.indexOf(scenario), 1);
    this.loading = true;
    this.db.deleteObjectByKey('Scenario', key).then(() => {
      setTimeout(() => {
        this.loading = false;
      }, 200);

    }).catch(() => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
    });
  }

}
