import {IDatabase} from '../../calculator-globals/database/database.interface';

export enum STORES {
  Scenario = 1
}

// @ts-ignore
export const DEFAULT = {
  name: 'Valores por defecto'
};

export const DATABASE: IDatabase = {
  name: 'XultophyDB',
  currentVersion: 1,
  versions: {
    1: [{
      id: STORES.Scenario,
      name: 'Scenario',
      parameters: {
        keyPath: 'id',
        autoIncrement: true
      },
      indexes: [{
        name: 'name',
        key: 'name'
      }]
    }]
  }
};
