import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dm-result-resume',
  templateUrl: './dm-result-resume.component.html',
  styleUrls: ['./dm-result-resume.component.css']
})
export class DmResultResumeComponent {

  @Input() cost_effect: any;
  @Input() results: any;
  @Input() nnt: any;

  constructor() { }

  ngOnInit() {
  }

}
