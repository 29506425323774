import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {WindowService} from "../../../services/window.service";
import { AppSettings } from "../../../app.settings";

declare const Chart: any;

@Component({
  selector: 'app-total-costs',
  templateUrl: './total-costs.component.html',
  styleUrls: ['./total-costs.component.css']
})
export class TotalCostsComponent implements OnInit {

  public tcChartType: string = 'bar';
  public tcDataSets: Array<any> = [
    {data: [], label: 'Costos administrativos'},
    {data: [], label: 'Costos de cirugía'}
  ];

  public tcLabels: Array<any> = ['Con cirugía', 'Sin cirugía'];
  public tcLegend: boolean = true;
  public tcOptions: any = {
    showDatapointsCurrency: true,
    responsive: false,
    scales: {
      yAxes: [{
        ticks: {
          callback: function(label, index, labels) {
            return '$ ' + Number(label).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          beginAtZero: true
        },
        stacked: true
      }]
    }
  };

  public savings: number;
  public years: number = 0;

  messageMapping:
    {[k: string]: string} = {'=1': '# Año', 'other': '# Años'};

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    this.years = js_window['years'];

    js_window.runSurgery();

    this.savings = js_window["costSummary"][2]["total"] * -1;

    this.tcDataSets[0].data.push(js_window["costSummary"][0]["management"]);
    this.tcDataSets[1].data.push(js_window["costSummary"][0]["surgery"]);

    this.tcDataSets[0].data.push(js_window["costSummary"][1]["management"]);
    this.tcDataSets[1].data.push(js_window["costSummary"][1]["surgery"]);
  }

  ngOnInit() {
  }

}
