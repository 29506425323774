import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.css']
})
export class InputsComponent implements OnInit {

  inputs: any = {
    patientweight_profilaxis: 0,
    presentation_profilaxis: 0,
    price_profilaxis : 0,
    dose_profilaxis: 0,
    schema_treatment_profilaxis: 0,
    eptacog_descuento : 0,
    pd_aPCC_presentation_profilaxis: 0,
    pd_aPCC_price_profilaxis: 0,
    pd_aPCC_dose_profilaxis: 0,
    pd_aPCC_schema_treatment_profilaxis: 0,
    pd_aPCC_descuento : 0
  };

  messageMapping:
    {[k: string]: string} = {'=1': 'vez', 'other': 'veces'};

  constructor() {
    for (let i in this.inputs) {
      if (this.inputs.hasOwnProperty(i))
        this.inputs[i] = window[i];
    }
  }

  updateValue(key, value) {
    this.inputs[key] = value;
    window[key] = value;
  }

  ngOnInit() {
  }
}
