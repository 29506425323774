import {IEffectiveness, IInputs, IMedsData, IParams} from '../models/scenario.class';


export function showWithComma(value: any ): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getYearlyCosts(inputs: IInputs, params: IParams) {

  const includeConsumables = params.includeCostConsumable;
  const includeAdditional = params.includeCostAdditional;

  const costsMap = [
    {
      label: 'Semaglutida 0.5 mg',
      key: 'semaglutida_0_5',
    },
    {
      label: 'Semaglutida 1 mg',
      key: 'semaglutida_1',
    },
    {
      label: 'Dulaglutida 1.5 mg',
      key: 'dulaglutida_1_5',
    },
  ];

  const totals = [];

  const semaglutida = [];
  const dulaglutida = [];
  const needles = [];
  const smbg = [];
  const additional = [];

  const labels = [];

  const weeks = (365.25 / 7);
  const medCosts = inputs.medCosts;
  const doses = inputs.resources;
  const consumables = inputs.consumableCosts;

  const needlesTotalSem = consumables.price.needles_semaglutida / consumables.presentation.needles_semaglutida;
  const needlesTotalDul = consumables.price.needles_dulaglutida / consumables.presentation.needles_dulaglutida;

  const smbgTotal = (consumables.price.bands / consumables.presentation.bands) +
      (consumables.price.lancets / consumables.presentation.lancets);

  for (const item of costsMap) {
    const dosesSem = doses.semaglutidaDoses[item.key] * medCosts.price[item.key] / medCosts.presentation[item.key];
    const dosesDul = doses.dulaglutidaDoses[item.key] * medCosts.price[item.key] / medCosts.presentation[item.key];

    const needlesSem = includeConsumables && dosesSem ? doses.needles[item.key] * needlesTotalSem * weeks : 0;
    const needlesDul = includeConsumables && dosesDul ? doses.needles[item.key] * needlesTotalDul * weeks : 0;

    // automonitorización de la glucemia
    const smbgItem = includeConsumables ? doses.smbg[item.key] * smbgTotal * weeks : 0;
    const additionalItem = includeAdditional ? doses.additionalCosts[item.key] : 0;

    const totalSem = weeks * dosesSem;
    const totalDul = weeks * dosesDul;

    semaglutida.push(totalSem);
    dulaglutida.push(totalDul);
    smbg.push(smbgItem);
    needles.push(needlesSem + needlesDul);
    additional.push(additionalItem);

    totals.push(totalSem + totalDul + needlesSem + needlesDul + smbgItem + additionalItem);
    labels.push(item.label);
  }

  return { semaglutida, dulaglutida, totals, labels, smbg, needles, additional };
}


export function getEffectivenessMap(effectiveness: IEffectiveness): Array<IEffectivenessMap> {
  return [
    // {
    //   label: 'HbA1c ≤6.5%',
    //   data: effectiveness.HbA1c_lte_6_5,
    //   key: 'HbA1c_lte_6_5'
    // },
    {
      label: 'HbA1c <7.0%',
      data: effectiveness.HbA1c_lte_7,
      key: 'HbA1c_lte_7'
    },
    {
      label: ['HbA1c <7.0%', 'sin hipoglucemia', 'y sin ganancia de peso'],
      data: effectiveness.HbA1c_lte_7_no_hipoglicemia,
      key: 'HbA1c_lte_7_no_hipoglicemia'
    },
    // {
    //   label: 'Pérdida de peso ≥5%',
    //   data: effectiveness.weight_loss_gte_5,
    //   key: 'weight_loss_gte_5'
    // },
    {
      label: 'Pérdida de peso ≥10%',
      data: effectiveness.weight_loss_gte_10,
      key: 'weight_loss_gte_10'
    },
    {
      label: ['≥1.0% reducción de HbA1c', 'y ≥3.0% pérdida de peso'],
      data: effectiveness.HbA1c_reduction,
      key: 'HbA1c_reduction'
    },
  ];
}

export interface IEffectivenessMap {
  label: string | Array<string>;
  data: IMedsData;
  key: string;
}

/**
 * Función para encontrar la inversa de una distribución normal.
 */
function getNormalInv() {
  function erfcinv(p) {
    let j = 0;
    let x;
    let err;
    let t;
    let pp;
    if (p >= 2) {
      return -100;
    }
    if (p <= 0) {
      return 100;
    }
    pp = (p < 1) ? p : 2 - p;
    t = Math.sqrt(-2 * Math.log(pp / 2));
    x = -0.70711 * ((2.30753 + t * 0.27061) /
        (1 + t * (0.99229 + t * 0.04481)) - t);
    for (; j < 2; j++) {
      err = erfc(x) - pp;
      x += err / (1.12837916709551257 * Math.exp(-x * x) - x * err);
    }
    return (p < 1) ? x : -x;
  }

  function erfc(x) {
    return 1 - erf(x);
  }

  function erf(x) {
    const cof = [-1.3026537197817094, 6.4196979235649026e-1, 1.9476473204185836e-2,
      -9.561514786808631e-3, -9.46595344482036e-4, 3.66839497852761e-4,
      4.2523324806907e-5, -2.0278578112534e-5, -1.624290004647e-6,
      1.303655835580e-6, 1.5626441722e-8, -8.5238095915e-8,
      6.529054439e-9, 5.059343495e-9, -9.91364156e-10,
      -2.27365122e-10, 9.6467911e-11, 2.394038e-12,
      -6.886027e-12, 8.94487e-13, 3.13092e-13,
      -1.12708e-13, 3.81e-16, 7.106e-15,
      -1.523e-15, -9.4e-17, 1.21e-16,
      -2.8e-17
    ];
    let j = cof.length - 1;
    let isneg = false;
    let d = 0;
    let dd = 0;
    let t;
    let ty;
    let tmp;
    let res;

    if (x < 0) {
      x = -x;
      isneg = true;
    }

    t = 2 / (2 + x);
    ty = 4 * t - 2;

    for (; j > 0; j--) {
      tmp = d;
      d = ty * d - dd + cof[j];
      dd = tmp;
    }

    res = t * Math.exp(-x * x + 0.5 * (cof[0] + ty * d) - dd);
    return isneg ? res - 1 : 1 - res;
  }

  return function inv(p, mean, std) {
    return -1.41421356237309505 * std * erfcinv(2 * p) + mean;
  };
}

export const normalinv = getNormalInv();

export function variableValuePSA(prcnt, n) {
  const rnd = Math.random();
  const sqrt = Math.sqrt((1 / n) * prcnt * (1 - prcnt));

  const inv = normalinv(rnd, prcnt, sqrt);

  return inv > 0 ? inv : 0;
}
