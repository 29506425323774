import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-dm-event-negative-utility]',
  templateUrl: './dm-event-negative-utility.component.html',
  styleUrls: ['./dm-event-negative-utility.component.css']
})
export class DmEventNegativeUtilityComponent implements OnInit {

  @Input() main_object: any;
  @Input() section: {
    title: string,
    modal: number,
    subsections: Array<{subtitle: string, key: string, subkeys: Array<string>}>
  };

  constructor() { }

  ngOnInit() {
  }

  showmodal(data: any) {
    // TODO implementar
  }

}
