import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { ModalComponent} from '../../../../components/modal/modal.component';
import {MatDialog} from '@angular/material';

interface ResourceUse {
  row_name: string,
  titles: Array<string>,
  keys: Array<string>,
  subtitles: Array<string>,
  subkeys: Array<string>
  disabled?: any,
  step?: Array<number>
}

@Component({
  selector: '[app-dm-uses-input]',
  templateUrl: './dm-uses-input.component.html',
  styleUrls: ['./dm-uses-input.component.css']
})

export class DmUsesInputComponent implements OnInit {

  private mensajes = [
    { title: '', contenido: ''},
    { title: 'Basal Insulin Dose Model', contenido: 'The base dose of basal insulin (insulin glargine), expressed in IUs per day. All other basal insulin doses are expressed relative to this dose Default Reference: Documentación en el archivo'},
    { title: 'Basal Insulin Dose Ratios', contenido: 'Dose ratios for basal insulin, relative to the base dose (insulin glargine) Degludec Reference: Documentaciónn en el archivo Glargine Reference: Documentación irrelevante'},
    { title: 'Bolus Insulin Dose Model', contenido: 'The base dose of bolus insulin (the mean dose of bolus insulin taken by patients using insulin glargine as their basal insulin), expressed in IUs per day. All other bolus insulin doses are expressed relative to this dose. Default Reference: Solo tratamiento inicial, no se aplicá ninguna dosis en bolo'},
    { title: 'Bolus Insulin Dose Ratios', contenido: 'Dose ratios for bolus insulin, relative to the base dose (bolus insulin taken by patients using insulin glargine as their basal insulin) Degludec Reference: Documentación en el archivo Glargine Reference: Documentación irrelevante'},
    { title: 'Basal Needle use', contenido: 'Daily needle use associated with the administration of basal insulin. Degludec Reference: Explicación en el archivo Glargine Reference: Explicación en el archivo'},
    { title: 'Bolus Needle use', contenido: 'Daily needle use associated with the administration of bolus insulin Degludec Reference: Irrelevante Glargine Reference: Irrelevante'},
    { title: 'Frequency of Routine SMBG testing', contenido: 'The weekly frequecy of self-monitoring of blood glucose tests. Degludec Reference: Explicación en el archivo Glargine Reference: Explicación en el archivo'},
    { title: 'Aditional SMBG Test After a Non-Severe daytime Hypoglicemic event', contenido: 'The mean number of additional self-monitoring of blood glucose tests that are performed after a non-severe daytime hypoglycemic event Degludec Reference: Documentación en el archivo Glargine Reference: Documentación en el archivo'},
    { title: 'Aditional SMBG Test After a Non-Severe nocturnal Hypoglicemic', contenido: 'The mean number of additional self-monitoring of blood glucose tests that are performed after a non-severe nocturnal hypoglycemic event Degludec Reference: Documentación en el archivo Glargine Reference: Documentación en el archivo'},
    { title: 'Aditional SMBG Test After a Severe Hypoglicemic Event', contenido: 'The mean number of additional self-monitoring of blood glucose tests that are performed after a severe hypoglycemic event. Degludec Reference: Documentación en el archivo Glargine Reference: Documentación en el archivo'},
  ];

  @Input() main_object: any;
  @Input() mappings: ResourceUse;
  @Input() main_title: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
  }

  calc(){
    if(typeof this.main_object.calc === 'function'){
      this.main_object.calc()
    }
  }

  showmodal(index: number) {
    const mensaje = this.mensajes[index];
    this.dialog.open(ModalComponent, {
      width: '450px',
      data: {title: mensaje.title, message: mensaje.contenido}
    });
  }

}
