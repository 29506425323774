import { Component, OnInit } from '@angular/core';
import {CalculatorStatus} from "../../../classes/status";

@Component({
  selector: 'app-dose-model-results',
  templateUrl: './dose-model-results.component.html',
  styleUrls: ['./dose-model-results.component.css']
})
export class DoseModelResultsComponent implements OnInit {

  resume_costs_effect: any;
  resume_results: any;
  resume_nnt: any;

  private statusInstance = CalculatorStatus.getInstance();

  tabs = {
    resume: ['block', true],
    diagram: ['none', false],
    psa: ['none', false]
  };

  constructor() { }

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      }
      else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

  ngOnInit() {
    if (this.statusInstance.modified) {
      (window as any).run_dose_model();
      this.statusInstance.modified = false;
    }
    let resume = window['array_resumen_dose_model'];
    this.resume_costs_effect = resume[0];
    this.resume_results = resume[1];
    this.resume_nnt = resume[2];
  }
}
