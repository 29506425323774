import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {DatosService} from '../../services/datos.service';
import {showWithComma} from '../../global/globalFunctions';

@Component({
    selector: 'app-overweight-load',
    templateUrl: './overweight-load.component.html',
    styleUrls: ['./overweight-load.component.css']
})
export class OverweightLoadComponent implements OnInit {

    tabActive: number;

    // ########## Casos diagnosticados ##########
    // ##########################################

    public barChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
          backgroundColor: '#FFFFFF',
          borderColor: '#000000',
          borderWidth: 1,
          titleFontColor: '#000000',
          bodyFontColor: '#000000',
          callbacks: {
            title: item => item[0].label.replace(/,/g, ' '),
            label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += showWithComma(tooltipItem.yLabel);
              return label;
            }
          }
        },
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Tipos de enfermedad'
                }
            }], yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: '# de casos'
                },
                ticks: {
                  beginAtZero: true
                }
            }]
        },

    };
    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: ChartDataSets[] = [];

    // ########## Costos ##########
    // ############################
    public barChartOptions2: ChartOptions = {
        responsive: true,
        tooltips: {
          backgroundColor: '#FFFFFF',
          borderColor: '#000000',
          borderWidth: 1,
          titleFontColor: '#000000',
          bodyFontColor: '#000000',
          callbacks: {
            title: item => item[0].label.replace(/,/g, ' '),
            label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += showWithComma(tooltipItem.yLabel) + ' COP';
              return label;
            }
          }
        },
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Tipos de enfermedad'
                }
            }], yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Miles de Millones'
                },
                ticks: {
                  beginAtZero: true,
                  callback(value: any, index: any, values: any): string | number {
                    if (value > 0 && value <= 1) {
                      return ' ';
                    } else {
                      return '$ ' + (value / 1000000000);
                    }
                  }
                }
            }]
        },

    };

    public barChartLabels2: Label[] = [];
    public barChartType2: ChartType = 'bar';
    public barChartLegend2 = true;
    public barChartData2: ChartDataSets[] = [];

    public pobTotal: number;
    public costTotal: number;
    // public divider: number;

    constructor(protected data: DatosService) {
        this.pobTotal = 0;
        this.costTotal = 0;
        this.tabActive = 0;
        // this.divider = 1000000000; // Miles de Millones
        const pobArray = [];
        const caArray = [];
        let cancerCost = 0;
        const upper = {data: [], label: 'Incidencias',
                       backgroundColor: '#39B0C1',
                       borderColor: '#39B0C1',
                       hoverBackgroundColor: '#1790A0',
                       pointHoverBackgroundColor: '#FFFFFF'};

        for (const item of data.enParams) {
            this.barChartLabels.push(item.name.split(' '));
            let totalPob = 0;

            if (item.slug === 'erc') {
                for (const pob of item.ranges) {
                    // console.log(item.name);
                    // @ts-ignore
                    totalPob += pob.poblacion.total * (pob.mean / 100) * data.prevalenncia.prevalencia;
                    // console.log(totalPob);
                }
                this.pobTotal += totalPob;
                // console.log(this.pobTotal);
                upper.data.push(totalPob.toFixed());
                pobArray.push([item.name, totalPob]);
                totalPob = 0;
            } else {
                for (const pob of item.ranges) {
                    // console.log(item.name);
                    // @ts-ignore
                    totalPob += (pob.poblacion.hombres + pob.poblacion.mujeres) * (pob.mean / 100) * data.prevalenncia.prevalencia;
                    // console.log(totalPob);
                }
                this.pobTotal += totalPob;
                upper.data.push(totalPob.toFixed());
                pobArray.push([item.name, totalPob]);
                totalPob = 0;
            }
        }

        let totalCaM = 0;
        let totalCaH = 0;
        for (const item of data.caParams) {

            if (item.name.toLowerCase().includes('mujer')) {
                for (const range of item.ranges) {
                    // @ts-ignore
                    caArray.push([range.segment, range.poblacion.total * (range.mean / 100) * data.prevalenncia.prevalencia]);
                    // @ts-ignore
                    totalCaM += range.poblacion.total * (range.mean / 100) * data.prevalenncia.prevalencia;
                }
            }

            if (item.name.toLowerCase().includes('hombre')) {
                for (const range of item.ranges) {
                    // @ts-ignore
                    caArray.push([range.segment, range.poblacion.total * (range.mean / 100) * data.prevalenncia.prevalencia]);
                    // @ts-ignore
                    totalCaH += range.poblacion.total * (range.mean / 100) * data.prevalenncia.prevalencia;
                }
            }
        }

        this.barChartLabels.push('Cancer');
        upper.data.push((totalCaM + totalCaH).toFixed());
        this.pobTotal += totalCaM + totalCaH;

        // pobArray.push(totalCaM + totalCaH);
        // console.log(totalCaM + totalCaH);
        this.barChartData.push(upper);

        // #########################################
        // console.log(pobArray);
        // console.log(caArray);
        const costDisease = {data: [], label: 'Costos', backgroundColor: '#39B0C1', hoverBackgroundColor: '#1790A0'};
        let totalCost = 0;

        for (const name of data.costos) {
            for (const aName of pobArray) {
                if (name.name === aName[0]) {
                    this.barChartLabels2.push(aName[0].split(' '));
                    totalCost = name.value * aName[1];
                    this.costTotal += totalCost;
                    costDisease.data.push(totalCost.toFixed());
                    // this.costTotal *= totalCost;
                    totalCost = 0;
                }
            }
        }

        this.barChartLabels2.push('Cancer');

        for (const name of data.costos) {
            for (const cancer of caArray) {
                let caName = cancer[0].toString();
                if (caName.includes('años')) {
                    caName = 'seno';
                }
                if (name.name.toLowerCase().includes(caName.toLowerCase())) {
                    totalCost = name.value * cancer[1];
                    this.costTotal += totalCost;
                    cancerCost += totalCost;
                    // console.log(name.name, name.value, cancer[0], cancer[1], cancerCost);
                    // this.costTotal += totalCost;
                    totalCost = 0;
                    // console.log(name.name);
                }
            }
        }
        costDisease.data.push(cancerCost.toFixed());
        // console.log(costDisease);
        this.barChartData2.push(costDisease);


    }

    ngOnInit() {
    }
}
