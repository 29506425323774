import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {SemaDataService} from '../../services/sema-data.service';
import {getYearlyCosts, showWithComma} from '../../global/globalFunctions';
import {IEffectiveness} from '../../models/scenario.class';

@Component({
    selector: 'app-costo-tratamiento',
    templateUrl: './costo-control.component.html',
    styleUrls: ['../../theme.scss']
})
export class CostoControlComponent implements OnInit {
    public barChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            borderWidth: 1,
            titleFontColor: '#000000',
            bodyFontColor: '#000000',
            callbacks: {
                title: item => item[0].label.replace(/,/g, ' '),
                label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += showWithComma(Number(tooltipItem.yLabel).toFixed(0));
                    return label;
                }
            }
        },
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Número necesario a tratar para llevar un paciente al objetivo'
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value: any, index: any, values: any) => {
                        if (this.tabActive === 1) {
                            return `${value}%`;
                        } else {
                            return `$${(value / 1000000).toFixed(0)} mill.`;
                        }
                    }
                }
            }]
        },
    };
    public effectiveness: IEffectiveness;

    public barChartLabels: Label[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: ChartDataSets[] = [];

    public barChartData2: ChartDataSets[] = [];

    public totals: any[] = [];
    private relativeValue: number;
    tabActive = 0;

    // public divider: number;

    constructor(protected data: SemaDataService) {

        this.effectiveness = data.inputs.effectiveness;

        const effectivenessMap = data.getEffectivenessMap();

        const semaglutida05 = this.getSemaglutida05();
        const semaglutida1 = this.getSemaglutida1();
        const dulaglutida15 = this.getDulaglutida15();

        const relativeSemaglutida05 = this.getSemaglutida05();
        const relativeSemaglutida1 = this.getSemaglutida1();
        const relativeDulaglutida15 = this.getDulaglutida15();

        const yearlyCosts = getYearlyCosts(data.inputs, data.params);

        for (const item of effectivenessMap) {
            this.barChartLabels.push(item.label);
            const sem05 = yearlyCosts.totals[0] / item.data.semaglutida_0_5;
            const sem1 = yearlyCosts.totals[1] / item.data.semaglutida_1;
            const dul15 = yearlyCosts.totals[2] / item.data.dulaglutida_1_5;

            const base: number = data.params.comparativeBase === 0.5 ? sem05 : sem1;

            semaglutida05.data.push(sem05);
            semaglutida1.data.push(sem1);
            dulaglutida15.data.push(dul15);

            relativeSemaglutida05.data.push((sem05 / base) * 100);
            relativeSemaglutida1.data.push((sem1 / base) * 100);
            relativeDulaglutida15.data.push((dul15 / base) * 100);
        }

        this.barChartData.push(semaglutida05);
        this.barChartData.push(semaglutida1);
        this.barChartData.push(dulaglutida15);

        this.barChartData2.push(relativeSemaglutida05);
        this.barChartData2.push(relativeSemaglutida1);
        this.barChartData2.push(relativeDulaglutida15);
    }

    isArray(obj) {
        return (obj instanceof Array);
    }

    ngOnInit() {
    }

    private getSemaglutida05() {
        return {
            data: [],
            label: 'Semaglutida 0.5 mg una vez a la semana',
            backgroundColor: '#39B0C1',
            borderColor: '#39B0C1',
            hoverBackgroundColor: '#1790A0',
            pointHoverBackgroundColor: '#FFFFFF'
        };
    }

    private getSemaglutida1() {
        return {
            data: [],
            label: 'Semaglutida 1 mg una vez a la semana',
            backgroundColor: '#b470c1',
            borderColor: '#b470c1',
            hoverBackgroundColor: '#a01e9b',
            pointHoverBackgroundColor: '#FFFFFF'
        };
    }

    private getDulaglutida15() {
        return {
            data: [],
            label: 'Dulaglutida 1.5 mg',
            backgroundColor: '#c17a67',
            borderColor: '#c17a67',
            hoverBackgroundColor: '#a04328',
            pointHoverBackgroundColor: '#FFFFFF'
        };
    }
}
