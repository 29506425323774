import {Component, Input, OnInit} from '@angular/core';
import {Setting} from '../../classes/setting';
import {AppSettings} from '../../app.settings';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

    @Input() calculatorId: string;

    settingName: string;
    bottomBarActive = false;

    constructor() {
    }

    ngOnInit() {
    }

    saveCurrentSetting() {
        if (this.settingName === '' || typeof this.settingName === 'undefined') {
            alert('Nombre de escenario no válido.');
        } else {
            alert('Correct');
            const setting = new Setting(this.calculatorId, this.settingName);
            setting.saveSetting();
        }
    }

    get hasBackground(): boolean {
        return this.calculatorId === AppSettings.DECTOOL_ID || this.calculatorId === AppSettings.ON_DEMAND_ID;

    }

    get hasReferences(): boolean {
        return this.calculatorId === AppSettings.DECTOOL_ID || this.calculatorId === AppSettings.ON_DEMAND_ID;
    }

    toggleBottomBar() {
        this.bottomBarActive = !this.bottomBarActive;
    }

}
