import {IDatabase, IStore} from './database.interface';

export enum STORES {
  Scenario = 1,
  Reference = 2
}

// @ts-ignore
export const DEFAULT = {
  name: 'Valores por defecto'
};

export const DATABASE: IDatabase = {
  name: 'NovoObesidad',
  currentVersion: 1,
  versions: {
    1: [{
      id: STORES.Scenario,
      name: 'Scenario',
      parameters: {
        keyPath: 'id',
        autoIncrement: true
      },
      indexes: [{
        name: 'name',
        key: 'name'
      }]
    }, {
      id: STORES.Reference,
      name: 'Reference',
      parameters: {
        keyPath: 'id'
      },
      indexes: []
    }]
  }
};


export function getDBStore(store: STORES): string {
  let current: IStore;
  for (const version in DATABASE.versions) {
    if (DATABASE.versions.hasOwnProperty(version)) {
      DATABASE.versions[version].forEach((s) => {
        if (s.id === store) {
          current = s;
        }
      });
    }
  }
  return current ? current.name : undefined;
}
