import {Component, OnInit} from '@angular/core';
import {Scenario3} from '../../models/scenario3';
import {ApiService} from '../../service/api.service';
import {BarChart} from '../../../calculator-globals/charts/models/charts';
import {fmtCop} from '../../../calculator-globals/utils';

@Component({
  selector: 'app-scenario3',
  templateUrl: './scenario3.component.html',
  styleUrls: ['../../theme.scss']
})
export class Scenario3Component implements OnInit {

  title = 'Escenario 3: Comparación directa de IDegLira Vs Basal bolo';
  xScenario: Scenario3;

  chartDap: BarChart;
  chartIdegLira: BarChart;
  chartIglarLixi: BarChart;

  constructor(public api: ApiService) {
  }

  ngOnInit() {
    this.loadCharts();
  }

  loadCharts() {
    const metaSimple = this.api.scenario3.getMetaSimple();
    const metaDoble = this.api.scenario3.getMetaDoble();
    const metaTriple = this.api.scenario3.getMetaTriple();

    const metaLabels = [
      metaSimple.title,
      metaDoble.title,
      metaTriple.title
    ];

    const metaLabelsShort = [
      ['Meta simple', '(<7% HbA1c)'],
      ['Meta doble (<7% Hb1Ac', '+ sin hipoglicemia)'],
      ['Meta triple (<7% Hb1Ac', '+ sin hipoglicemia', '+ sin ganancia de peso)']
    ];

    this.chartDap = new BarChart({
      horizontal: true,
      labels: metaLabels,
      datasets: [
        {
          data: [metaSimple.deltaDap, metaDoble.deltaDap, metaTriple.deltaDap],
          label: 'Δ DAP - Ahorro',
          backgroundColor: '#007DF7',
          hoverBackgroundColor: '#005BD5'
        }
      ],
      formatter(value): string {
        return fmtCop(value);
      }
    });

    this.chartIdegLira = new BarChart({
      labels: metaLabelsShort,
      datasets: [
        {
          data: [
            metaSimple.personasNoLograronMeta.iDegLira,
            metaDoble.personasNoLograronMeta.iDegLira,
            metaTriple.personasNoLograronMeta.iDegLira,
          ],
          label: 'IdegLira: Personas que no lograron la meta',
          backgroundColor: '#39B0C1',
          hoverBackgroundColor: '#1790A1'
        },
        {
          data: [
            metaSimple.personasLograronMeta.iDegLira,
            metaDoble.personasLograronMeta.iDegLira,
            metaTriple.personasLograronMeta.iDegLira,
          ],
          label: 'IdegLira: Personas que lograron la meta',
          backgroundColor: '#03DBB1',
          hoverBackgroundColor: '#01B991'
        },
      ],
    });

    this.chartIglarLixi = new BarChart({
      labels: metaLabelsShort,
      datasets: [
        {
          data: [
            metaSimple.personasNoLograronMeta.iglarAspart,
            metaDoble.personasNoLograronMeta.iglarAspart,
            metaTriple.personasNoLograronMeta.iglarAspart,
          ],
          label: 'IglarLixi: Personas que no lograron la meta',
          backgroundColor: '#39B0C1',
          hoverBackgroundColor: '#1790A1'
        },
        {
          data: [
            metaSimple.personasLograronMeta.iglarAspart,
            metaDoble.personasLograronMeta.iglarAspart,
            metaTriple.personasLograronMeta.iglarAspart,
          ],
          label: 'IglarLixi: Personas que lograron la meta',
          backgroundColor: '#03DBB1',
          hoverBackgroundColor: '#01B991'
        },
      ],
    });
  }
}
