import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-bi-per-citizen',
  templateUrl: './bi-per-citizen.component.html',
  styleUrls: ['./bi-per-citizen.component.css']
})
export class BiPerCitizenComponent implements OnInit {

  public bi_per_citizen: any;

  public bpcChartLegend: boolean = true;
  public bpcChartType: string = 'bar';
  public bpcDataSets: Array<any> = [
    {data: [], label: 'Escenario Actual'},
    {data: [], label: 'Escenario Alternativo'},
  ];

  public bpcChartLabels: Array<string> = ['Costo total por persona por mes'];
  public bpcChartOptions:any = {
    showDatapoints: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
        }
      }]
    }
  };

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    js_window.runOnDemand();

    this.bi_per_citizen = js_window['budget_impact_per_citizen_month']['budget_impact_per_citizen_month'];

    this.bpcDataSets[0].data.push(this.bi_per_citizen['per_year_current_scenario']['Total_Cost']);
    this.bpcDataSets[1].data.push(this.bi_per_citizen['per_year_alternative_scenario']['Total_Cost']);
  }

  ngOnInit() {
  }

}
