import { Component } from '@angular/core';

@Component({
  selector: 'app-od-references',
  templateUrl: './od-references.component.html',
  styleUrls: ['./od-references.component.css']
})
export class OdReferencesComponent {

}
