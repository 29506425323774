import {Component} from '@angular/core';
import { ApiService } from '../../servicio/api.service';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['../../theme.scss']
})
export class HomeComponent {
  constructor(public api: ApiService) {
  }
}
