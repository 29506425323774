import {Component, Input, OnInit} from '@angular/core';
import {ModalComponent} from '../../../../components/modal/modal.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: '[app-dm-utility-treatment]',
  templateUrl: './dm-utility-treatment.component.html',
  styleUrls: ['./dm-utility-treatment.component.css']
})
export class DmUtilityTreatmentComponent implements OnInit {

  private mensajes = [
    { title: 'Treatment related utility gain', contenido: 'The utility gain applied annually to each treatment Degludec Reference: Documentación en el archivo Glargine Reference: Sin referencia'},
    { title: 'Dosing flexibility utility gain', contenido: 'The utility gain applied annually to insulins which are not flexible with regard to dose timing. Degludec Reference: Boye et al. 2011 Glargine Reference: 0'},
    { title: 'Utility Gain from Other treatment features', contenido: 'A utility gain that can be used to capture changes in quality of life arising from treatment features such as reduced dosing frequency Degludec Reference: Glargine Reference: '}
  ];


  @Input() main_object: {
    numero_de_pruebas: {
      insulina_degludec: number, insulina_glargina: number
    },
    se: {
      insulina_degludec: number, insulina_glargina: number
    }
  };

  @Input() titles: {
    title: string,
    subtitle: string
    modal: number
  };

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
  }

  showmodal(index: number) {
    const mensaje = this.mensajes[index];
    this.dialog.open(ModalComponent, {
      width: '450px',
      data: {title: mensaje.title, message: mensaje.contenido}
    });
  }
}
