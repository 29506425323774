import {AfterContentInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-debie-costs-graphics]',
  templateUrl: './debie-costs-graphics.component.html',
  styleUrls: ['./debie-costs-graphics.component.css']
})
export class DebieCostsGraphicsComponent implements OnInit, AfterContentInit {

  @Input() main_object: any;

  public chartType: string = 'pie';

  charts: any = {
    d_scenario_without_degludec_percent: {
      cost: {
        data: [],
        labels: []
      },
      payer: {
        data: [],
        labels: []
      }
    },
    e_scenario_with_degludec_percent: {
      cost: {
        data: [],
        labels: []
      },
      payer: {
        data: [],
        labels: []
      }
    }
  };

  options: any = {
    responsive: false
  };

  scenarios_map: Array<string> = [
    'd_scenario_without_degludec_percent',
    'e_scenario_with_degludec_percent'
  ];

  items_map: Array<string> = [
    'a_non_severe_hypo_costs',
    'b_severe_hypo_costs',
    'c_insulin_costs',
    'd_needle_costs',
    'e_smbg_costs'
  ];

  payer_map: Array<string> = [
    'a_primary_care_cost',
    'b_secondary_care_cost',
    'c_patient_expenses',
    'd_other',
  ];

  item_titles: Array<string> = [
    'Non-severe hypo costs',
    'Severe hypo costs',
    'Insulin costs',
    'Needle costs',
    'SMBG costs'
  ];

  payer_titles: Array<string> = [
    'Primary care costs',
    'Secondary care costs',
    'Patient expenses',
    'Other'
  ];

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterContentInit() {

    for (let scn_key of this.scenarios_map) {
      let items_count = 0;
      let payer_count = 0;

      for (let key of this.items_map) {
        this.charts[scn_key].cost.data.push(this.main_object.f_total_cost_breakdown_by_cost_component[scn_key][key]);
        this.charts[scn_key].cost.labels.push(this.item_titles[items_count]);
        items_count++
      }

      for (let key of this.payer_map) {
        this.charts[scn_key].payer.data.push(this.main_object.g_total_cost_breakdown_by_payer[scn_key][key]);
        this.charts[scn_key].payer.labels.push(this.payer_titles[payer_count]);
        payer_count++;
      }
    }

    console.log(this.charts);
  }

}
