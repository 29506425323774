import {Component, OnInit} from '@angular/core';
import {DataArray} from "../../../classes/data-array";
import {AppSettings} from "../../../app.settings";
import {WindowService} from "../../../services/window.service";

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.css']
})
export class CostsComponent implements OnInit {

  display: any = {
    DP: ['block', true],
    SC: ['none', false],
    MC: ['none', false]
  };

  keys = {
    rFVIIa: 'rFVIIa',
    costs_price_rFVIIa: 'costs_price_rFVIIa',
    costs_price_pdAPCC: 'costs_price_pdAPCC',
    IA_DP: 'IA_DP',
    OA_DP: 'OA_DP',
    OA_DP2: 'OA_DP2',

    Hosp_Bleeds: 'Hosp_Bleeds',

    surgery_cost_replacement: 'surgery_cost_replacement',
    Hosp_Rehab: 'Hosp_Rehab',
    Hosp_Excess: 'Hosp_Excess',
    surgery_cost_debridement: 'surgery_cost_debridement',
    surgery_cost_tkr: 'surgery_cost_tkr',

    cost_excess_surgery_care: 'cost_excess_surgery_care',
    cost_other_surgery_costs: 'cost_other_surgery_costs',
    cost_additional_management: 'cost_additional_management',
  };

  costs: any = {};

  private esc_labels_array = [
    'Ortopedista',
    'Anestesiólogo',
    'Hematólogo',
    'Fisioterapeuta',
    'Enfermera',
    'Técnico de laboratorio'
  ];

  esc_RU_map = [
    'Orthopaedic_surgeon_RU',
    'Anaesthetist_RU',
    'Haematologist_RU',
    'Physiotherapist_RU',
    'Nurse_RU',
    'Laboratory_technician_RU'
  ];

  esc_CS_map = [
    'Orthopaedic_surgeon_CS',
    'Anaesthetist_CS',
    'Haematologist_CS',
    'Physiotherapist_CS',
    'Nurse_CS',
    'Laboratory_technician_CS'
  ];

  esc_labels: DataArray = new DataArray('', this.esc_labels_array, AppSettings.LABEL, 'esc_labels');
  esc_RU: DataArray = new DataArray('Número', [], AppSettings.NUMERIC, 'esc_RU');
  esc_CS: DataArray = new DataArray('Costo por unidad', [], AppSettings.NUMERIC, 'esc_CS');

  private osrc_labels_array = [
    'Resonancia magnética',
    'Ecografía',
    'Tomografía computarizada',
    'Otros exámenes'
  ];

  osrc_RU_map = [
    'MRI_RU',
    'Ultrasound_Scan_RU',
    'CT_Scan_RU',
    'Other_tests_RU'
  ];

  osrc_CS_map = [
    'MRI_CS',
    'Ultrasound_Scan_CS',
    'CT_Scan_CS',
    'Other_tests_CS'
  ];

  osrc_labels: DataArray = new DataArray('', this.osrc_labels_array, AppSettings.LABEL, 'osrc_labels');
  osrc_RU: DataArray = new DataArray('Number', [], AppSettings.NUMERIC, 'osrc_RU', 1);
  osrc_CS: DataArray = new DataArray('Cost', [], AppSettings.NUMERIC, 'osrc_CS');

  private amc_labels_array = [
    'Hematólogo',
    'Médico general',
    'Fisioterapeuta'
  ];

  amc_costs_map = [
    'costs_haematologist',
    'costs_GP',
    'costs_physiotherapist'
  ];

  amc_ruv_map = [
    'RUVisits_haematologist',
    'RUVisits_GP',
    'RUVisits_physiotherapist'
  ];

  amc_labels: DataArray = new DataArray('', this.amc_labels_array, AppSettings.LABEL, 'amc_labels');
  amc_costs: DataArray = new DataArray('Costo por visita', [], AppSettings.NUMERIC, 'amc_costs');
  amc_ruv: DataArray = new DataArray('Visita/Año', [], AppSettings.NUMERIC, 'amc_ruv', 1);

  activateTab(tab_key) {
    for (let key in this.display) {
      this.display[key][0] = 'none';
      this.display[key][1] = false
    }

    this.display[tab_key][0] = 'block';
    this.display[tab_key][1] = true;
  }

  updateValue(key, value) {
    const global = (window as any);
    this.costs[key] = value;
    global[key] = value;

    if (key === this.keys.rFVIIa) {
      global[this.keys.costs_price_rFVIIa] = value;
    }
  }

  updateRow(event_data) {
    let js_window = this.ws.nativeWindow;
    let row = event_data.row;
    let id = event_data.data_array_id;

    if(id == 'esc_RU') {
      js_window[this.esc_RU_map[row]] = this.esc_RU.data[row];
      this.calcCostExcesSurgeryCare()
    }
    else if(id == 'esc_CS'){
      js_window[this.esc_CS_map[row]] = this.esc_CS.data[row];
      this.calcCostExcesSurgeryCare()
    }
    else if(id == 'osrc_RU'){
      js_window[this.osrc_RU_map[row]] = this.osrc_RU.data[row];
      this.calcCostOtherSurgeryCosts()
    }
    else if(id == 'osrc_CS'){
      js_window[this.osrc_CS_map[row]] = this.osrc_CS.data[row];
      this.calcCostOtherSurgeryCosts()
    }
    else if(id == 'amc_costs'){
      js_window[this.amc_costs_map[row]] = this.amc_costs.data[row];
      this.calcCostAdditionalManagment();
    }
    else if(id == 'amc_ruv'){
      js_window[this.amc_ruv_map[row]] = this.amc_ruv.data[row];
      this.calcCostAdditionalManagment();
    }
  }

  private calcCostExcesSurgeryCare() {
    let cost_excess_surgery_care = 0;
    for (let i = 0; i < this.esc_RU_map.length; i++){
      cost_excess_surgery_care += this.esc_RU.data[i] * this.esc_CS.data[i];
    }

    let js_window = this.ws.nativeWindow;
    this.costs.cost_excess_surgery_care = cost_excess_surgery_care;
    js_window[this.keys.cost_excess_surgery_care] = cost_excess_surgery_care;
  }

  private calcCostOtherSurgeryCosts() {
    let cost_other_surgery_costs = 0;
    for (let i = 0; i < this.osrc_RU_map.length; i++){
      cost_other_surgery_costs += this.osrc_RU.data[i] * this.osrc_CS.data[i];
    }

    let js_window = this.ws.nativeWindow;
    this.costs.cost_other_surgery_costs = cost_other_surgery_costs;
    js_window['cost_other_surgery_costs'] = cost_other_surgery_costs;
  }

  private calcCostAdditionalManagment() {
    let costs_additional_management = 0;
    for (let i = 0; i < this.amc_costs_map.length; i++){
      costs_additional_management += this.amc_costs.data[i] * this.amc_ruv.data[i];
    }

    let js_window = this.ws.nativeWindow;
    this.costs.cost_additional_management = costs_additional_management;
    js_window[this.keys.cost_additional_management] = costs_additional_management;
    js_window['cost_additional_management_after_surgery'] = costs_additional_management;
  }

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    for (let key in this.keys){
      this.costs[key] = js_window[key];
    }

    for (let i = 0; i < this.esc_RU_map.length; i++) {
      this.esc_RU.data.push(js_window[this.esc_RU_map[i]]);
      this.esc_CS.data.push(js_window[this.esc_CS_map[i]]);
    }

    for (let i = 0; i < this.osrc_RU_map.length; i++) {
      this.osrc_RU.data.push(js_window[this.osrc_RU_map[i]]);
      this.osrc_CS.data.push(js_window[this.osrc_CS_map[i]]);
    }

    for (let i = 0; i < this.amc_costs_map.length; i++) {
      this.amc_costs.data.push(js_window[this.amc_costs_map[i]]);
      this.amc_ruv.data.push(js_window[this.amc_ruv_map[i]]);
    }
  }

  ngOnInit() {
  }
}
