import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debie-out-costs',
  templateUrl: './debie-out-costs.component.html',
  styleUrls: ['./debie-out-costs.component.css']
})
export class DebieOutCostsComponent implements OnInit {

  cost_type_one_debie_model;
  cost_type_two_debie_model_basal_oral;
  cost_type_two_debie_model_basal_bolus;
  cost_all_debie_model;

  show_breakdown: boolean = true;

  tabs = {
    all: ['block', true],
    type_1: ['none', false],
    type_2_bo: ['none', false],
    type_2_bb: ['none', false]
  };

  constructor() {
    (window as any).run();

    this.cost_type_one_debie_model = window['cost_type_one_debie_model'];
    this.cost_type_two_debie_model_basal_oral = window['cost_type_two_debie_model_basal_oral'];
    this.cost_type_two_debie_model_basal_bolus = window['cost_type_two_debie_model_basal_bolus'];
    this.cost_all_debie_model = window['cost_all_debie_model'];
  }

  ngOnInit() {
  }

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      }
      else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

}
