import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'DividerP'})

export class DividerP implements PipeTransform {
    transform(value: number): any {
        let newStr: string | number = '';

        if (value > 1000000000000) {
            const numb = (value / 1000000000000).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            newStr = numb + ' billones';
        } else if (value > 1000000000) {
            newStr = (value / 1000000000).toFixed().toString() + ' mil millones';
        } else if (value > 1000000) {
            newStr = (value / 1000000).toFixed().toString() + ' millones';
        } else if (value > 1000) {
            newStr = (value / 1000).toFixed().toString() + ' mil';
        } else {
            newStr = value.toFixed().toString();
        }
        return newStr.toString();
    }
}
