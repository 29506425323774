import {Injectable} from '@angular/core';
import {DATABASE, DEFAULT} from './database.const';
import {isNullOrUndefined} from 'util';
import {Serializable} from '../models/serializable.interface';
import {Factory, MODEL} from '../models/factory';
import {Scenario} from '../models/scenario.class';
import ecostos from '../../data/costos.json';
import eparams from '../../data/e-parametros.json';
import cparams from '../../data/c-parametros.json';
import maindata from '../../data/main.json';
import proAtrEx from '../../data/PAE.json';

@Injectable()
export class DatabaseService {
  private db: IDBDatabase;
  private dbPromise: Promise<{}>;

  constructor() {
  }

  public init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.initDatabase().then(() => {
        this.getObjectByIndexValue('Scenario', MODEL.Scenario, 'name', DEFAULT.name).then((sc: Scenario) => {
          resolve();
        }).catch(() => {
          const scenario: Scenario = Factory.create(MODEL.Scenario, {
            name: DEFAULT.name,
            costos: ecostos,
            enParams: eparams,
            caParams: cparams,
            prevalencia: maindata,
            pae: proAtrEx,
          });
          this.addObject('Scenario', scenario).then(() => {
            resolve();
          }).catch(() => {
            resolve();
          });
        });
      });
    });
  }

  private initDatabase(): Promise<any> {
    this.dbPromise = new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DATABASE.name, DATABASE.currentVersion);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = request.result;
        for (const version in DATABASE.versions) {
          if (DATABASE.versions.hasOwnProperty(version)) {
            const v = Number(version);
            if (event.newVersion === v || event.oldVersion < v) {
              for (const istore of DATABASE.versions[version]) {
                const store = db.createObjectStore(istore.name, istore.parameters);
                for (const iindex of istore.indexes) {
                  if (isNullOrUndefined(iindex.parameters)) {
                    store.createIndex(iindex.name, iindex.key);
                  } else {
                    store.createIndex(iindex.name, iindex.key, iindex.parameters);
                  }
                }
              }
            }
          }
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        if (this.db) {
          this.db.onversionchange = (ev: any) => {
            if (ev.target) {
              ev.target.close();
            }
          };
        }
        resolve();
      };

      request.onerror = (e) => {
        console.error(e);
        (window.navigator as any).notification.alert('La aplicación no puede funcionar sin acceso a la base de datos.', () => {
          reject();
        });
      };
    });
    return this.dbPromise;
  }

  public addObject(storeName: string, value: Serializable): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const request = store.put(value.toJSON());
        request.onsuccess = (evt: any) => {
          const res: IDBRequest = evt.srcElement;
          resolve({
            key: res.result,
          });
        };
        request.onerror = (error: any) => {
          console.error(error);
          reject({
            error
          });
        };
      });
    });
  }

  public updateObject<T>(storeName: string, obj: Serializable): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const del = store.delete(obj.getKey());
        del.onsuccess = (evt: Event) => {
          const request = store.put(obj.toJSON());
          request.onsuccess = (evnt: Event) => {
            resolve(true);
          };
          request.onerror = (evnt: Event) => {
            console.error(evnt);
            reject(false);
          };
        };
        del.onerror = (evt: Event) => {
          console.error(evt);
          reject(false);
        };
      });
    });
  }

  public deleteObject<T>(storeName: string, obj: Serializable): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const del = store.delete(obj.getKey());
        del.onsuccess = (evt: Event) => {
          resolve(true);
        };
        del.onerror = (evt: Event) => {
          console.error(evt);
          reject(false);
        };
      });
    });
  }

  public getAllObjects<T>(storeName: string, className: MODEL): Promise<Array<T>> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName);
        const store = tx.objectStore(storeName);
        const items: Array<T> = [];
        const request = store.openCursor();
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            items.push(Factory.create(className, cursor.value));
            cursor.continue();
          } else {
            resolve(items);
          }
        };
      });
    });
  }

  public getObjectByKey(className: MODEL, storeName: string, key: number | string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName);
        const store = tx.objectStore(storeName);
        const request = store.openCursor(key);
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            resolve(Factory.create(className, cursor.value));
          } else {
            reject(undefined);
          }
        };
        request.onerror = (error) => {
          console.error(error);
          reject(undefined);
        };
      });
    });
  }


  public deleteObjectByKey<T>(storeName: string, key: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const del = store.delete(key);
        del.onsuccess = (evt: Event) => {
          resolve(true);
        };
        del.onerror = (evt: Event) => {
          console.error(evt);
          reject(false);
        };
      });
    });
  }

  public getObjectByIndexValue<T>(storeName: string, className: MODEL, indexName: string, value: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const index = store.index(indexName);
        const request = index.openCursor(value);
        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            resolve(Factory.create(className, cursor.value));
          } else {
            reject(undefined);
          }
        };
        request.onerror = (error) => {
          console.error(error);
          reject(undefined);
        };
      });
    });
  }

  public updateObjectByKey(storeName: string, data: any, key: number | string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const request = store.delete(key);
        request.onsuccess = (event: any) => {
          const write = store.put(data);
          write.onsuccess = () => {
            resolve(true);
          };
          write.onerror = (reason) => {
            console.error(reason);
            reject(false);
          };
        };
        request.onerror = (error) => {
          console.error(error);
          reject(false);
        };
      });
    });
  }

  public clearStore(storeName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dbPromise.then(() => {
        const tx = this.db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        const request = store.clear();
        request.onsuccess = () => {
          resolve(true);
        };
        request.onerror = reason => {
          console.error(reason);
          reject(false);
        };
      });
    });
  }

  public clear(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const requests = [];
      for (const version in DATABASE.versions) {
        if (DATABASE.versions[version]) {
          for (const store of DATABASE.versions[version]) {
            requests.push(this.clearStore(store.name));
          }
        }
      }
      Promise.all(requests).then(() => {
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }
}
