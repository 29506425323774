import {IFactory} from '../../calculator-globals/database/factory.interface';
import {IODScenario, Scenario} from '../models/scenario.class';

export class ODFactory extends IFactory {
  create(type: any, params?: IODScenario): any {
    switch (type) {
      case MODEL.Scenario:
        return new Scenario(params);
      default:
        throw new Error('Model not found');
    }
  }
}

export enum MODEL {
  Scenario
}
