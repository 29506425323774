import { Component, OnInit } from '@angular/core';
import {WindowService} from "../../../services/window.service";
import {AppSettings} from "../../../app.settings";

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.css']
})
export class BackgroundComponentdt {
s
}
