import {Component, OnInit} from '@angular/core';

declare var window: any;

@Component({
  selector: 'app-debie-unitcosts',
  templateUrl: './debie-unitcosts.component.html',
  styleUrls: ['./debie-unitcosts.component.css']
})
export class DebieUnitcostsComponent implements OnInit {

  debie_unit_cost = window.debie_unit_cost;

  // tslint:disable-next-line:variable-name
  sections_insulin_costs = [
    {title: 'Cost per basal', key: 'cost_per_basal'},
    {title: 'Cost per bolus', key: 'cost_per_bolus'}
  ];

  // tslint:disable-next-line:variable-name
  sections_needle_costs = [
    {title: 'Cost per basal needle', key: 'cost_per_basal'},
    {title: 'Cost per bolus needle', key: 'cost_per_bolus'}
  ];

  constructor() {

  }

  ngOnInit() {
  }

  updateValue(object: any, key: string, value: number) {
    object[key] = value;
  }
}
