import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Setting } from '../../classes/setting';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-dec-tool',
  templateUrl: './dec-tool.component.html',
  styleUrls: ['./dec-tool.component.css']
})
export class DecToolComponent implements OnInit {

  calculator_id = AppSettings.DECTOOL_ID;

  constructor() {
  }

  ngOnInit() {
  }

}
