import {NgModule} from '@angular/core';
import {MaterialModule} from '../material.module';
import {ModalService} from './modal.service';
import {SaveScenarioModal} from './save-scenario/save-scenario.component';
import {LoadScenarioModal} from './load-scenario/load-scenario.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  declarations: [
    SaveScenarioModal,
    LoadScenarioModal
  ],
  entryComponents: [
    SaveScenarioModal,
    LoadScenarioModal
  ],
  providers: [
    ModalService
  ]
})
export class ModalModule {
}
