import { Component, OnInit } from '@angular/core';
import {DatosService} from '../../services/datos.service';
import {RoutingService} from '../../services/routing.service';

@Component({
  selector: 'app-pae',
  templateUrl: './pae.component.html',
  styleUrls: ['./pae.component.css']
})
export class PaeComponent implements OnInit {

  active: string;

  constructor(public data: DatosService, public routes: RoutingService) {
    this.active = 'eic';
  }

  ngOnInit() {
  }

}
