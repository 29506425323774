import {Serializable} from './serializable.interface';
import {DEFAULT} from '../database/database.const';

export class Scenario implements Serializable {
  private _id: number;

  private _inputs: IInputs;
  private _parameters: IParams;

  private _name: string;
  private _editable: boolean;

  constructor(params: IScenario) {
    this._id = params.id;
    this._name = !!params.name ? params.name : DEFAULT.name;
    this._parameters = params.parameters;
    this._inputs = params.inputs;
    this._editable = params.hasOwnProperty('editable') ? params.editable : false;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get inputs(): IInputs {
    return this._inputs;
  }

  set inputs(value: IInputs) {
    this._inputs = value;
  }

  get parameters(): IParams {
    return this._parameters;
  }

  set parameters(value: IParams) {
    this._parameters = value;
  }

  get id(): number {
    return this._id;
  }

  public setKey(id: number): void {
    this._id = id;
  }

  get editable(): boolean {
    return this._editable;
  }

  public setEditable(status: boolean): void {
    this._editable = status;
  }

  getKey(): number {
    return this._id;
  }

  toJSON(): IScenario {
    return {
      name: this._name,
      inputs: this._inputs,
      parameters: this._parameters,
      editable: this._editable
    };
  }
}

export interface IParams {
  includeCostConsumable: boolean;
  includeCostAdditional: boolean;

  HbA1c_lte_6_5: boolean;
  HbA1c_lt_7: boolean;
  HbA1c_lt_7_NoHipoglucemia: boolean;
  weightLoss_5: boolean;
  weightLoss_7: boolean;
  HbA1c_reduction: boolean;

  weeklySemaglutida_0_5: boolean;
  weeklySemaglutida_1: boolean;
  comparativeBase: 0.5 | 1.0;

  compareDulaglutida_0_75: boolean;
  compareDulaglutida_1_5: boolean;

  aproxProportionVariable: 'multiple of standard error' | 'percentage of baseline value';
  multipleStdError: number;
  percentageBaselineValue: number;
  costMultiplier: number;
  psa: number;
}

export interface IInputs {
  effectiveness: IEffectiveness;
  resources: IResources;
  medCosts: IMedCosts;
  consumableCosts: IConsumableCostsData;
  n: IMedsData;
}

export interface IMedsData {
  semaglutida_0_5: any;
  semaglutida_1: any;
  dulaglutida_1_5: any;
}

export interface IResources {
  semaglutidaDoses: IMedsData;
  dulaglutidaDoses: IMedsData;
  needles?: IMedsData;
  smbg?: IMedsData;
  additionalCosts?: IMedsData;
}

export interface IMedCosts {
  price: IMedsData;
  presentation: IMedsData;
}

export interface IConsumableCostsData {
  price: any;
  presentation: any;
}

export interface IEffectiveness {
  HbA1c_lte_6_5: IMedsData;
  HbA1c_lte_7: IMedsData;
  HbA1c_lte_7_no_hipoglicemia: IMedsData;
  weight_loss_gte_5: IMedsData;
  weight_loss_gte_10: IMedsData;
  HbA1c_reduction: IMedsData;
}

export interface IRef {
  id: number;
  reference: string;
}

export interface IScenario {
  id?: number;
  name?: string;
  inputs?: any;
  parameters?: any;
  editable?: boolean;
}
