import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {Scenario} from '../models/scenario.class';
import {LoadScenarioModal} from './load-scenario/load-scenario.component';
import {first} from 'rxjs/operators';
import {ISaveScenario, SaveScenarioModal} from './save-scenario/save-scenario.component';

@Injectable()
export class ModalService {
  constructor(private dialog: MatDialog) {
  }


  public loadScenario(scenarios: Array<Scenario>): Promise<Scenario> {
    return new Promise<Scenario>((resolve, reject) => {
      let dialogRef: MatDialogRef<LoadScenarioModal>;
      const config = new MatDialogConfig();
      config.disableClose = false;
      dialogRef = this.dialog.open(LoadScenarioModal, config);
      dialogRef.componentInstance.scenarios = scenarios;
      dialogRef.afterClosed().pipe(first()).subscribe((sc: Scenario) => {
        resolve(sc);
      });
    });
  }

  public saveScenario(scenarios: Array<Scenario>): Promise<ISaveScenario> {
    return new Promise((resolve, reject) => {
      let dialogRef: MatDialogRef<SaveScenarioModal>;
      const config = new MatDialogConfig();
      config.disableClose = false;
      dialogRef = this.dialog.open(SaveScenarioModal, config);
      dialogRef.componentInstance.scenarios = scenarios.filter(s => s.editable);
      dialogRef.afterClosed().pipe(first()).subscribe((sc: ISaveScenario) => {
        resolve(sc);
      });
    });
  }


}
