import {AppSettings} from '../app.settings';

export class Setting {

  constructor(calculatorId: string, settingName: string) {
    this.calculatorId = calculatorId;
    this.settingName = settingName;
  }
  calculatorId: string;
  settingName: string;

  public static getListSettings(calculatorId: string) {
    const data = localStorage.getItem(calculatorId);
    if (data == null) {
      return {};
    } else {
      return JSON.parse(data);
    }
  }

  saveSetting(): void {
    const tmp = this.getVarNames();
    const toSaveData = [];

    for (const t of tmp) {
      toSaveData.push(window[t]);
    }

    this.appendSetting(toSaveData);
  }

  deleteSetting(): void {
    const currentData = this.extractSettingList();
    delete currentData[this.settingName];
    const toSave = JSON.stringify(currentData);
    localStorage.setItem(this.calculatorId, toSave);
  }

  loadSetting() {
    const keys = this.getVarNames();
    const values = this.extractSetting();

    for (let i = 0; i < keys.length; i++) {
      window[keys[i]] = values[i];
    }
  }

  private appendSetting(data: any) {
    const currentData = this.extractSettingList();
    currentData[this.settingName] = data;
    const toSave = JSON.stringify(currentData);
    localStorage.setItem(this.calculatorId, toSave);
  }

  private extractSettingList() {
    const data = localStorage.getItem(this.calculatorId);
    if (data == null) {
      return {};
    } else {
      return JSON.parse(data);
    }
  }

  private extractSetting() {
    const settingsArray = this.extractSettingList();
    return settingsArray[this.settingName];
  }

  private getVarNames(): Array<string> {
    if (this.calculatorId === AppSettings.DECTOOL_ID) {
      return [
        'base_year',
        'years',

        'patient_age',
        'patient_weight',
        'patient_share_surgery_joint',
        'patient_avg_abr',
        'avg_abr_after_surgery',
        'life_table',


        'clinical_decrease_annual_bleeds',
        'clinical_decrease_joint_bleeds_surgery',
        'bleeds_per_surgery',

        'clinical_LOS_uncomplicated',
        'clinical_LOS_tkr',

        'clinical_LOS_debridement',
        'clinical_LOS_replacement',

        'PS_TKR',
        'LOS_TKR',

        'PS_RS',
        'LOS_RS',

        'PS_D',
        'LOS_D',

        'PS_TSRS',
        'LOS_TSRS',


        'IA_Dose',
        'OA_Dose',
        'OA_Dose2',

        'IA_Days',
        'OA_Days',
        'OA_Days2',

        'clinical_LOS_replacement2_1',
        'clinical_LOS_replacement2_2',


        'rFVIIa',
        'costs_price_rFVIIa',
        'costs_price_pdAPCC',

        'IA_DP',
        'OA_DP',
        'OA_DP2',


        'surgery_cost_replacement',
        'Hosp_Rehab',
        'Hosp_Excess',

        'surgery_cost_debridement',


        'costs_haemorrhage_treatment',
        'MMB_rFVIIa',
        'BR_Hosp',
        'PSB_rFVIIa',


        'discount_rate_costs',
        'VAT',


        'costs_haematologist',
        'costs_GP',
        'costs_physiotherapist',


        'RUVisits_haematologist',
        'RUVisits_GP',
        'RUVisits_physiotherapist',


        'cost_additional_management',
        'cost_additional_management_after_surgery',

        'Cost_of_PPx_treatment',


        'cost_additional_medication',

        'Cost_of_PPx_treatment_after_surgery',


        'Hosp_Bleeds',


        'MRI_RU',
        'Ultrasound_Scan_RU',
        'CT_Scan_RU',
        'Other_tests_RU',

        'MRI_CS',
        'Ultrasound_Scan_CS',
        'CT_Scan_CS',
        'Other_tests_CS',

        'cost_other_surgery_costs',


        'Orthopaedic_surgeon_RU',
        'Anaesthetist_RU',
        'Haematologist_RU',
        'Physiotherapist_RU',
        'Nurse_RU',
        'Laboratory_technician_RU',

        'Orthopaedic_surgeon_CS',
        'Anaesthetist_CS',
        'Haematologist_CS',
        'Physiotherapist_CS',
        'Nurse_CS',
        'Laboratory_technician_CS',

        'cost_excess_surgery_care',


        'surgery_cost_tkr',
        'tkr',
        'riskAS',
        'riskDI',

        'protocol_rFVIIa',
        'protocol_APCC',

        'protocol_after_month1_weeks',
        'after_month1_rFVIIa',
        'after_month1_APCC'
      ];
    } else if (this.calculatorId === AppSettings.ON_DEMAND_ID) {
      return [
        'TxLine1Current',
        'TxRebleed1Current',
        'TxLine2Current',
        'TxRebleed2Current',
        'TxLine3Current',

        'TxLine1Alternative',
        'TxRebleed1Alternative',
        'TxLine2Alternative',
        'TxRebleed2Alternative',
        'TxLine3Alternative',

        'ModelOption',
        'UnitCost_NovoSeven',
        'UnitCost_pdAPCC',
        'VAT_bi',
        'MalePopulation',

        'PrevalenceA',
        'InhibitorsA',
        'PrevalenceB',
        'InhibitorsB',

        'Rebleed_TxLine1Current',
        'Rebleed_TxLine2Current',
        'Rebleed_TxLine1Alternative',
        'Rebleed_TxLine2Alternative',

        'DaysHospitalLine1Current',
        'DaysHospitalLine2Current',
        'DaysHospitalLine3Current',

        'NumberVisitsLine1Current',
        'NumberVisitsLine2Current',
        'NumberVisitsLine3Current',

        'DaysHospitalLine1Alternative',
        'DaysHospitalLine2Alternative',
        'DaysHospitalLine3Alternative',

        'NumberVisitsLine1Alternative',
        'NumberVisitsLine2Alternative',
        'NumberVisitsLine3Alternative',

        'othercostcurrentline1',
        'othercostcurrentline2',
        'othercostcurrentline3',

        'othercostalternativeline1',
        'othercostalternativeline2',
        'othercostalternativeline3',

        'CostPerDayInHospital',
        'CostPerOutpatientVisit',
        'CostPerVial',
        'NumberOfUnits',
        'Averageweight',
        'Averagenumberofmild',
        'TimeHorizon',
        'BleedsPerPatientPerYear',
        'TotalBleedsPerYear',
        'numberofpatients',
        'yearsofnalysis',
        'sizeofthetargetpopulation',
        'VAT_birate',
        'NumberCitizens',
        'arrayTreatmentCost',
      ];
    } else if (this.calculatorId === AppSettings.PROFILAXIS_ID) {
      return [
        'patientweight_profilaxis',
        'presentation_profilaxis',
        'price_profilaxis',
        'dose_profilaxis',
        'schema_treatment_profilaxis',
        'pd_aPCC_presentation_profilaxis',
        'pd_aPCC_price_profilaxis',
        'pd_aPCC_dose_profilaxis',
        'pd_aPCC_schema_treatment_profilaxis'
      ];
    } else if (this.calculatorId === AppSettings.DOSESCOSTS_ID) {
      return [
        'Peso_paciente_promedio',
        'Dosis_rFVIIa',
        'Dosis_pdAPCC',
        'Presentacion_rFVIIa',
        'Presentacion_pdAPCC',
        'Costo_por_ampolla_rFVIIa',
        'Costo_por_ampolla_pdAPCC',
        'Dosis_requeridas_rFVIIa',
        'Dosis_requeridas_pd_APCC',
        'Eficacia_rFVIIa_cese_sangrado',
        'Eficacia_pdAPCC_cese_sangrado'
      ];
    } else if (this.calculatorId === AppSettings.DEBIE_BUDGET_ID) {
      return [
        'patient_receiving_scenario_one',
        'patient_receiving_basal_oral',
        'patient_receiving_basal_bolus',
        'anual_discount_debie',
        'total_population_size',
        'time_horizon',
        'currency',
        'annual_discount_rate',
        'starting_year',
        'primary_comparator',
        'comparator_one',
        'comparator_two',
        'comparator_three',
        'comparator_four',
        'comparator_five',
        'active_comparators',
        'type_one_diabetes_epidemiology',
        'type_two_diabetes_epidemiology',
        'type_one_name_scenario_one',
        'type_one_name_scenario_two',
        'type_one_scenario_one',
        'type_one_scenario_two',
        'type_two_basal_oral_scenario_one',
        'type_two_basal_oral_scenario_two',
        'type_two_bolus_oral_scenario_one',
        'type_two_bolus_oral_scenario_two',
        'debie_unit_cost',
        'resource_type_one',
        'resource_type_two_oral',
        'resource_type_two_bolus',
        'clinical_type_one',
        'clinical_type_two_basal_oral',
        'clinical_type_two_basal_bolus',
        'debie_days_per_year'
      ];
    } else if (this.calculatorId === AppSettings.DOSE_MODEL_ID) {
      return [];
    } else if (this.calculatorId === AppSettings.VICTOZA_ID) {
      return [
        'victoza_poblacion',
        'victoza_horizonte_temporal_de_analisis',
        'victoza_liraglutide',
        'victoza_exanentide',
        'victoza_sitagliptina',
        'victoza_glimepiride',

        'poblacion_con_exanentida',
        'poblacion_con_liraglutide',
        'poblacion_con_sitagliptina',
        'poblacion_con_glimepiride',
        'mortalidad_por_evento_macrovascular',
      ];
    }
    throw new Error('ATTEMPTED TO LOAD AN UNDEFINED CALCULATOR ID SETTING: ' + this.calculatorId);
  }

}
