import {IScenario, Scenario} from './scenario.class';

export class Factory {
  public create(type: MODEL, params?: any): any {
    switch (type) {
      case MODEL.Scenario:
        return this.createScenario(params);
      default:
        throw new Error('Model not defined');
    }
  }

  private createScenario(params: IScenario): Scenario {
    return new Scenario(params);
  }
}


export enum MODEL {
  Scenario
}
