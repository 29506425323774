import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { IFormulaciones } from '../../models/models';
import {BarChart} from '../../../calculator-globals/charts/models/charts';
import {fmtCop} from '../../../calculator-globals/utils';

@Component({
  selector: 'app-scenario1',
  templateUrl: './scenario1.component.html',
  styleUrls: ['../../theme.scss']
})
export class Scenario1Component implements OnInit {

  title = 'Escenario 1: Pacientes no controlados con Insulina basal que pasan a cooformulación (Comparación indirecta)';

  chartDap: BarChart;
  chartIdegLira: BarChart;
  chartIglarLixi: BarChart;

  constructor(public api: ApiService) {
  }

  ngOnInit() {
    this.loadCharts();
  }

  updateCosts(newValues: IFormulaciones) {
    this.api.scenario1.costs.valorLapicero = newValues;
  }

  loadCharts() {
    const metaSimple = this.api.scenario1.getMetaSimple();
    const metaDoble = this.api.scenario1.getMetaDoble();
    const metaTriple = this.api.scenario1.getMetaTriple();

    const metaLabels = [
      metaSimple.title,
      metaDoble.title,
      metaTriple.title
    ];

    const metaLabelsShort = [
      ['Meta simple', '(<7% HbA1c)'],
      ['Meta doble (<7% Hb1Ac', '+ sin hipoglicemia)'],
      ['Meta triple (<7% Hb1Ac', '+ sin hipoglicemia', '+ sin ganancia de peso)']
    ];

    this.chartDap = new BarChart({
      horizontal: true,
      labels: metaLabels,
      datasets: [
        {
          data: [metaSimple.deltaDap, metaDoble.deltaDap, metaTriple.deltaDap],
          label: 'Δ DAP - Ahorro',
          backgroundColor: '#007DF7',
          hoverBackgroundColor: '#005BD5'
        }
      ],
      formatter(value): string {
        return fmtCop(value);
      }
    });

    this.chartIdegLira = new BarChart({
      labels: metaLabelsShort,
      datasets: [
        {
          data: [
            metaSimple.personasNoLograronMeta.iDegLira,
            metaDoble.personasNoLograronMeta.iDegLira,
            metaTriple.personasNoLograronMeta.iDegLira,
          ],
          label: 'IdegLira: Personas que no lograron la meta',
          backgroundColor: '#39B0C1',
          hoverBackgroundColor: '#1790A1'
        },
        {
          data: [
            metaSimple.personasLograronMeta.iDegLira,
            metaDoble.personasLograronMeta.iDegLira,
            metaTriple.personasLograronMeta.iDegLira,
          ],
          label: 'IdegLira: Personas que lograron la meta',
          backgroundColor: '#03DBB1',
          hoverBackgroundColor: '#01B991'
        },
      ],
    });

    this.chartIglarLixi = new BarChart({
      labels: metaLabelsShort,
      datasets: [
        {
          data: [
            metaSimple.personasNoLograronMeta.iGlarLixi,
            metaDoble.personasNoLograronMeta.iGlarLixi,
            metaTriple.personasNoLograronMeta.iGlarLixi,
          ],
          label: 'IglarLixi: Personas que no lograron la meta',
          backgroundColor: '#39B0C1',
          hoverBackgroundColor: '#1790A1'
        },
        {
          data: [
            metaSimple.personasLograronMeta.iGlarLixi,
            metaDoble.personasLograronMeta.iGlarLixi,
            metaTriple.personasLograronMeta.iGlarLixi,
          ],
          label: 'IglarLixi: Personas que lograron la meta',
          backgroundColor: '#03DBB1',
          hoverBackgroundColor: '#01B991'
        },
      ],
    });
  }
}
