import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: '[app-ms-scenario]',
  templateUrl: './ms-scenario.component.html',
  styleUrls: ['./ms-scenario.component.css']
})
export class MsScenarioComponent implements OnInit {
  @Input() scenario: string;
  @Input() main_object: any;

  @Output() onScenarioChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChanged: EventEmitter<string> = new EventEmitter();

  model: any = {};

  comparators: Array<any> = (window as any).getComparators();

  model_years: Array<number> = [];
  model_years_keys: Array<string> = [];

  constructor() {

    let yr_string = "year_";
    for (let i = 0; i < window['time_horizon']; i++) {
      this.model_years.push(window['starting_year'] + i);
      if(i === 0) {
        this.model_years_keys.push(yr_string + "one")
      }
      if(i === 1) {
        this.model_years_keys.push(yr_string + "two")
      }
      if(i === 2) {
        this.model_years_keys.push(yr_string + "three")
      }
      if(i === 3) {
        this.model_years_keys.push(yr_string + "four")
      }
      if(i === 4) {
        this.model_years_keys.push(yr_string + "five")
      }

    }
  }

  ngOnInit() {
  }

  updateScenario(value) {
    this.onScenarioChange.emit(value);
  }

}
