import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {Scenario} from '../../models/scenario.class';
import {DatabaseService} from '../../database/database.service';

@Component({
  selector: 'modal-load-scenario',
  templateUrl: './load-scenario.component.html'
})
export class LoadScenarioModal {
  public scenarios: Array<Scenario>;
  public loading: boolean;

  constructor(public dialog: MatDialogRef<LoadScenarioModal>,
              protected db: DatabaseService) {
    this.scenarios = [];
    this.loading = false;
  }

  public setSelected(sc: Scenario) {
    if (sc) {
      this.dialog.close(sc);
    }
  }

  public deleteItem(key) {
    const scenario = this.scenarios.find(item => item.getKey() === key);
    // console.log(scenario);
    this.scenarios.splice(this.scenarios.indexOf(scenario), 1);
    this.loading = true;
    this.db.deleteObjectByKey('Scenario', key).then(() => {
      setTimeout(() => {
        this.loading = false;
      }, 200);

    }).catch(() => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
    });
  }

}
