import {AfterContentInit, Component, Input, OnInit} from '@angular/core';

interface ScenarioObject {
  a_non_severe_daytime: number;
  b_non_severe_nocturnal: number;
  severe_hypoglycemic: number;
}

@Component({
  selector: 'app-db-clinical-outcome',
  templateUrl: './db-clinical-outcome.component.html',
  styleUrls: ['./db-clinical-outcome.component.css']
})

export class DbClinicalOutcomeComponent implements OnInit, AfterContentInit {

  @Input() main_object: {
    a_scenario_without_degludec: ScenarioObject,
    b_scenario_with_degludec: ScenarioObject,
    c_difference: ScenarioObject
  };

  datasets: Array<{data: Array<number>, label: string}> = [
    {data: [], label: 'Escenario sin degludec'},
    {data: [], label: 'Escenario con degludec'},
  ];

  labels: Array<string> = [];
  chart_type: string = 'bar';
  options: any = {
    showDatapoints: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
        }
      }]
    }
  };

  scenarios_map: Array<string> = [
    'a_scenario_without_degludec',
    'b_scenario_with_degludec',
    'c_difference'
  ];

  scenarios_titles: Array<string> = [
    'Escenario sin degludec',
    'Escenario con degludec',
    'dferencia'
  ];

  items_map: Array<string> = [
    'a_non_severe_daytime',
    'b_non_severe_nocturnal',
    'severe_hypoglycemic'
  ];

  items_titles: Array<string> = [
    'Non-severe daytime hypoglycemic events',
    'Non-severe nocturnal hypoglycemic events',
    'Severe hypoglycemic events'
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    let i = 0;
    for (let scn_key of this.scenarios_map) {
      if (i > 1) {
        break;
      }
      for (let key of this.items_map) {
        this.datasets[i].data.push(this.main_object[scn_key][key]);
      }
      i++;
    }

    for (let title of this.items_titles) {
      this.labels.push(title);
    }
  }

}
