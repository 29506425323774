import { Component, OnInit } from '@angular/core';
import {SemaDataService} from '../../services/sema-data.service';
import {RoutingService} from '../../services/routing.service';
import {IParams} from '../../models/scenario.class';

@Component({
  selector: 'app-cal-params',
  templateUrl: './cal-params.component.html',
  styleUrls: ['../../theme.scss']
})
export class CalParamsComponent implements OnInit {

  params: IParams;
  active: string;
  map: Array<IParamsMap> = [
    {
      title: 'Costos',
      inputs: [
        {
          name: 'Incluir consumibles',
          key: 'includeCostConsumable'
        },
        {
          name: 'Incluir costos adicionales',
          key: 'includeCostAdditional'
        },
      ]
    },
    {
      title: 'Desenlaces',
      inputs: [
        // {
        //   name: 'Proporción de pacientes alcanzando HbA1c ≤6.5%',
        //   key: 'HbA1c_lte_6_5',
        // },
        {
          name: 'Proporción de pacientes alcanzando  HbA1c <7.0%',
          key: 'HbA1c_lt_7',
        },
        {
          name: 'Proporción de pacientes alcanzando  HbA1c <7.0% sin hipoglicemia y sin ganancia de peso',
          key: 'HbA1c_lt_7_NoHipoglucemia',
        },
        // {
        //   name: 'Proporción de pacientes alcanzando  pérdida de peso ≥5%',
        //   key: 'weightLoss_5',
        // },
        {
          name: 'Proporción de pacientes alcanzando pérdida de peso ≥10%',
          key: 'weightLoss_7',
        },
        {
          name: 'Proporción de pacientes alcanzando reducción ≥1.0% HbA1c y pérdida de peso (≥5.0% o ≥3.0%)',
          key: 'HbA1c_reduction',
        },
      ]
    },
    {
      title: 'Semaglutida una vez a la semana',
      inputs: [
        {
          name: 'Incluir Semaglutida una vez a la semana  0.5mg',
          key: 'weeklySemaglutida_0_5'
        },
        {
          name: 'Incluir Semaglutida una vez a la semana 1mg',
          key: 'weeklySemaglutida_1'
        },
        {
          name: 'Base para la comparación relativa de costo',
          key: 'comparativeBase',
          type: 'comparativeBase'
        },
      ]
    },
    // {
    //   title: 'Dulaglutida una vez a la semana',
    //   inputs: [
    //     {
    //       name: 'Incluir Dulaglutida 0.75mg',
    //       key: 'compareDulaglutida_0_75'
    //     },
    //     {
    //       name: 'Incluir Dulaglutida 1.5mg',
    //       key: 'compareDulaglutida_1_5'
    //     },
    //   ]
    // },
    {
      title: 'Análisis de sensibilidad de una vía',
      inputs: [
        {
          name: 'Aproximación a una proporción variable de pacientes que alcanzan el objetivo',
          key: 'aproxProportionVariable',
          type: 'aproxProportionVariable'
        },
        {
          name: 'Multiplicador del error estándar',
          key: 'multipleStdError',
          type: 'number'
        },
        {
          name: 'Porcentaje del valor base',
          key: 'percentageBaselineValue',
          type: 'percent'
        },
        {
          name: 'Multiplicador del costo',
          key: 'costMultiplier',
          type: 'percent'
        },
      ]
    },
    {
      title: 'Análisis probabilístico',
      inputs: [
        {
          name: 'Cantidad de pacientes simulados (PSA)',
          key: 'psa',
          type: 'number'
        },
      ]
    },
  ];

  constructor(public data: SemaDataService, public routes: RoutingService) {
    this.params = data.params;
  }

  ngOnInit() {
  }

}

interface IParamsMap {
  title: string;
  inputs: Array<IParamInputMap>;
}

interface IParamInputMap {
  name: string;
  key: string;
  type?: any;
}
