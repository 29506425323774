import { NgModule } from '@angular/core';
import { BarChartComponent } from './components/bar-chart.component/bar-chart.component';
import { ChartsModule } from 'ng2-charts';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [BarChartComponent],
  imports: [ChartsModule, CommonModule],
  exports: [BarChartComponent]
})
export class AppChartsModule {
}
