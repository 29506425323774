import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {BaseChartDirective} from 'ng2-charts';

@Component({
  selector: 'app-dm-result-diagram',
  templateUrl: './dm-result-diagram.component.html',
  styleUrls: ['./dm-result-diagram.component.css']
})
export class DmResultDiagramComponent implements AfterViewInit {

  @Input() results: any;

  @ViewChild(BaseChartDirective, {static: true}) chartComponent: BaseChartDirective;

  // tslint:disable-next-line:variable-name
  results_map = [
    'b_costo_inicial_de_la_insulina_gbp',
    'c_costo_de_la_insulina_en_bolo_gbp',
    'd_costos_de_las_agujas_gbp',
    'e_costo_de_la_prueba_smbg_de_rutina_gbp',
    'f_costo_de_un_episodio_de_hipoglucemia_diurna_no_grave_gbp',
    'g_costo_de_un_episodio_de_hipoglucemia_nocturna_no_grave_gbp',
    'h_costo_de_un_episodio_de_hipoglucemia_grave_gbp'
  ];

  dataSets: any[] = [
    {data: [], label: 'Costo inicial de la insulina (COP)'},
    {data: [], label: 'Costo de la insulina en bolo (COP)'},
    {data: [], label: 'Costos de las agujas (COP)'},
    {data: [], label: 'Costo de la prueba SMBG de rutina (COP)'},
    {data: [], label: 'Costo de un episodio de hipoglucemia diurna no grave (COP)'},
    {data: [], label: 'Costo de un episodio de hipoglucemia nocturna no grave (COP)'},
    {data: [], label: 'Costo de un episodio de hipoglucemia grave (COP)'}
  ];

  labels: string[] = [
    'Insulina Degludec',
    'Insulina Glargina'
  ];

  dataLoaded = false;
  legendData: any;

  options: any = {
    legendCallback: this.legendCallback(),
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  type: string = 'bar';

  constructor() {
  }

  ngAfterViewInit(): void {
    let index = 0;
    for (const key of this.results_map) {
      this.dataSets[index].data.push(this.results[key].insulina_degludec);
      this.dataSets[index].data.push(this.results[key].insulina_glargina);
      index++;
    }
    this.legendData = this.chartComponent.chart.generateLegend();

    setTimeout(() => {
      this.dataLoaded = true;
    }, 0);
  }

  private legendCallback() {
    return chart => chart.data.datasets;
  }

}
