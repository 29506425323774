import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-costs-table]',
  templateUrl: './costs-table.component.html',
  styleUrls: ['./costs-table.component.css']
})
export class CostsTableComponent implements OnInit {
  @Input() main_object: any;
  @Input() sections: Array<any>;

  comparators: Array<any> = (window as any).getComparators();

  constructor() { }

  ngOnInit() {
  }

}
