import { Component, OnInit } from '@angular/core';
import {DatabaseService} from '../../../servicio/database.service';
import {ApiService} from '../../../servicio/api.service';
import {Scenario} from '../../../models/scenario.class';
import {MODEL} from '../../../database/factory.class';
import {ISaveScenario} from '../../../../semaglutida/modals/save-scenario/save-scenario.component';
import {ModalService} from '../../../../semaglutida/modals/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  constructor(private database: DatabaseService,
              private modal: ModalService,
              private api: ApiService) {
  }

  ngOnInit() {
  }

  public loadScenario(): void {
    this._loadScenario().then((sc: any) => {
      if (sc) {
        this.api.loadScenario(sc);
      }
    });
  }

  public async saveScenario(): Promise<void> {
    await this._saveScenario();
  }

  private async _loadScenario(): Promise<Scenario> {
    const scenarios = await this.database.getAllObjects<Scenario>('Scenario', MODEL.Scenario);
    return await this.modal.loadScenario<Scenario>(scenarios, this.database);
  }

  private async _saveScenario(): Promise<Scenario> {
    const scenarios = await this.database.getAllObjects<Scenario>('Scenario', MODEL.Scenario);
    const data: ISaveScenario = await this.modal.saveScenario<Scenario>(scenarios);
    let scenario: Scenario;
    if (data) {
      scenario = this.api.getCopyCurrent(data.name);
      if (data.create) {
        scenario.editable = true;
        const response = await this.database.addObject('Scenario', scenario);
        scenario.id = response.key;
      } else {
        if (data.editable) {
          scenario.name = data.name;
          scenario.id = data.id;
          await this.database.updateObject('Scenario', scenario);
        }
      }
      return scenario;
    } else {
      return undefined;
    }
  }

}
