import {Component} from '@angular/core';
import {Scenario} from '../../models/scenario.class';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-save-scenario',
  templateUrl: './save-scenario.component.html',
})
export class SaveScenarioModal {

  public scenarios: Array<Scenario>;
  public selected: Scenario;
  public name: string;
  public status: OPTIONS;

  constructor(public dialog: MatDialogRef<SaveScenarioModal>) {
    this.scenarios = [];
    this.status = OPTIONS.SELECT;
  }

  public setSelected(sc: Scenario) {
    this.selected = sc;
  }

  public save(): void {
    if (this.valid) {
      if (this.create) {
        this.dialog.close({
          name: this.name,
          create: true
        });
      } else if (this.save_as) {
        this.dialog.close({
          create: false
        });
      }
    }
  }

  get create(): boolean {
    return this.status === OPTIONS.CREATE;
  }

  get select(): boolean {
    return this.status === OPTIONS.SELECT;
  }

  get save_as(): boolean {
    return this.status === OPTIONS.SAVE_AS;
  }

  public setCreate(): void {
    this.status = OPTIONS.CREATE;
  }

  public setSaveAs(): void {
    this.status = OPTIONS.SAVE_AS;
  }


  public cancel(): void {
    this.status = OPTIONS.SELECT;
    this.name = undefined;
  }

  get valid(): boolean {
    if (this.create || this.save_as) {
      return this.create ? !!this.name : !!this.selected;
    }
    return false;
  }
}

enum OPTIONS {
  SELECT,
  CREATE,
  SAVE_AS
}


export interface ISaveScenario {
  name?: string;
  id?: number;
  create: boolean;
}
