import { Component, OnInit } from '@angular/core';
import {AppSettings} from "../../app.settings";

@Component({
  selector: 'app-debie-budget',
  templateUrl: './debie-budget.component.html',
  styleUrls: ['./debie-budget.component.css']
})
export class DebieBudgetComponent implements OnInit {

  calculator_id = AppSettings.DEBIE_BUDGET_ID;
  constructor() { }

  ngOnInit() {
  }

}
