import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule, MatListModule, MatTabsModule} from '@angular/material';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule
  ],
  exports: [
    MatDialogModule,
    MatListModule,
    MatTabsModule
  ]
})
export class MaterialModule {
}
