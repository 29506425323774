import { Component, OnInit } from '@angular/core';
import {CalculatorStatus} from "../../../classes/status";

@Component({
  selector: 'app-calculations',
  templateUrl: './calculations.component.html',
  styleUrls: ['./calculations.component.css']
})
export class CalculationsComponent implements OnInit {

  private statusInstance: CalculatorStatus = CalculatorStatus.getInstance();

  tabs = {
    costs: ['block', true],
    clinical: ['none', false],
    quality: ['none', false]
  };

  activateTab(key: string) {
    for (let tab_key in this.tabs) {
      if (key === tab_key) {
        this.tabs[tab_key] = ['block', true];
      }
      else {
        this.tabs[tab_key] = ['none', false];
      }
    }
  }

  window_ref = window as any;

  constructor() { }

  ngOnInit() {
    if (this.statusInstance.modified) {
      (window as any).run_dose_model();
      this.statusInstance.modified = false;
    }
  }

}
