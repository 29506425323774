import {Component, Input, OnInit} from '@angular/core';
import { ModalComponent} from "../../../../components/modal/modal.component"
import {MatDialog} from '@angular/material';

@Component({
  selector: '[app-dm-insulin-costs]',
  templateUrl: './dm-insulin-costs.component.html',
  styleUrls: ['./dm-insulin-costs.component.css']
})
export class DmInsulinCostsComponent implements OnInit {


  private mensajes = [
    { title: '', contenido: ''},
    { title: 'Basal insulin Pack price', contenido: 'The cost of one pack of basal insulin in COL. Degludec Reference: Precio preliminar en Reino Unido Glargine Reference: MIMS febrero de 2012'},
    { title: 'Basal insulin units per pack', contenido: 'The number of units in a pack of basal insulin Degludec Reference: Precio preliminar en Reino Unido Glargine Reference: MIMS febrero de 2012'},
    { title: 'Bolus insulin pack price', contenido: 'The cost of one pack of bolus insulin in COL Degludec Reference: IAsp (Flexpen), MIMS febrero de 2012 Glargine Reference: IAsp (Flexpen), MIMS febrero de 2012'},
    { title: 'Bolus insulin units per pack', contenido: 'The number of units in a pack of bolus insulin Degludec Reference: IAsp (Flexpen), MIMS febrero de 2012 Glargine Reference: IAsp (Flexpen), MIMS febrero de 2012'},
    { title: 'Basal insulin Needle pack price', contenido: 'The cost of one pack of needles for basal insulin in COL. Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (PenFine 8mm)'},
    { title: 'Basal insulin Needle per pack', contenido: 'The number of basal insulin needles per pack. Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (PenFine 8mm)'},
    { title: 'Bolus insulin Needle pack price', contenido: 'The cost of one pack of needles for bolus insulin in COL Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G)'},
    { title: 'Bolus insulin Needle per pack', contenido: 'The number of bolus insulin needles per pack Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G)'},
    { title: 'Basal insulin Needle pack price', contenido: 'The cost of one pack of needles for basal insulin in COL. Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (PenFine 8mm)'},
    { title: 'Basal insulin Needle per pack', contenido: 'The number of basal insulin needles per pack. Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (PenFine 8mm)'},
    { title: 'Bolus insulin Needle pack price', contenido: 'The cost of one pack of needles for bolus insulin in COL Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (agujas NovoFine [8�mm] 31G)'},
    { title: 'Bolus insulin Needle per pack', contenido: 'The number of bolus insulin needles per pack Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G)'},
    { title: 'Basal insulin Needle per pack', contenido: 'The number of basal insulin needles per pack. Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (PenFine 8mm)'},
    { title: 'Bolus insulin Needle pack price', contenido: 'The cost of one pack of needles for bolus insulin in COL Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G)'},
    { title: 'Bolus insulin Needle per pack', contenido: 'The number of bolus insulin needles per pack Degludec Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G) Glargine Reference: MIMS febrero de 2012 (agujas NovoFine [8mm] 31G)'}
  ];

  constructor(public dialog: MatDialog) {}

  @Input() main_object;
  @Input() map;
  @Input() titles = {};
  @Input() includes = false;

  ngOnInit() {
  }

  calc() {
    if (typeof this.main_object.calc === 'function') {
      this.main_object.calc();
    }
  }

  showmodal(index: number) {
    const mensaje = this.mensajes[index];
    this.dialog.open(ModalComponent, {
      width: '450px',
      data: {title: mensaje.title, message: mensaje.contenido}
    });
  }

}
