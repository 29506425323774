import { Component, OnInit } from '@angular/core';
import { ModalComponent} from '../../../components/modal/modal.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-dose-model-clinical',
  templateUrl: './dose-model-clinical.component.html',
  styleUrls: ['./dose-model-clinical.component.css']
})
export class DoseModelClinicalComponent implements OnInit {

  tasa_hipoglucemia_diurna_no_grave = window['tasa_hipoglucemia_diurna_no_grave'];
  tasa_hipoglucemia_nocturna_no_grave = window['tasa_hipoglucemia_nocturna_no_grave'];
  tasa_hipoglucemia_grave = window['tasa_hipoglucemia_grave'];
  mortalidad_despues_hipoglucemia_grave = window['mortalidad_despues_hipoglucemia_grave'];

  private mensajes = [
    { title: 'Severe Hypoglucemia mortality rate', contenido: 'The proportion of patients, expressed as a percentage, who die following a severe hypoglycemic event. Default Reference: Documentación en el archivo'},
  ];

  window_ref = window as any;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  showmodal(index: number) {
    const mensaje = this.mensajes[index];
    this.dialog.open(ModalComponent, {
      width: '450px',
      data: {title: mensaje.title, message: mensaje.contenido}
    });
  }
}
