import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  private previousURL: string;
  private currentURL: string;

  constructor(router: Router) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.setURLs(event);
    });
  }

  private setURLs(event: NavigationEnd) {
    const tmpURL = this.currentURL ? this.currentURL : '/semaglutida';
    this.previousURL = tmpURL;
    this.currentURL = event.urlAfterRedirects;
  }

  get PreviousURL(): string {
    return this.previousURL;
  }

  get CurrentURL(): string {
    return this.currentURL;
  }

}
