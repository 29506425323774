import { Component, OnInit } from '@angular/core';
import { WindowService } from "../../../services/window.service";

@Component({
  selector: 'app-bi-per-patient',
  templateUrl: './bi-per-patient.component.html',
  styleUrls: ['./bi-per-patient.component.css']
})
export class BiPerPatientComponent implements OnInit {

  public bi_per_patient: any;
  public over_duration_bi: any;

  public bppChartLegend: boolean = true;
  public bppChartType: string = 'bar';
  public bppDataSets: Array<any> = [
    {data: [], label: 'Escenario Actual'},
    {data: [], label: 'Escenario Alternativo'},
  ];

  public bppChartLabels: Array<string> = ['Costo total por paciente en el tiempo'];
  public bppChartOptions:any = {
    showDatapoints: true,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero:true,
        }
      }]
    }
  };

  constructor(private ws: WindowService) {
    let js_window = ws.nativeWindow;

    js_window.runOnDemand();

    this.bi_per_patient = js_window['bi_per_patient']['bi_per_patient'];
    this.over_duration_bi = this.bi_per_patient['over_duration_budget_impact'];

    this.bppDataSets[0].data.push(this.bi_per_patient['per_year_current_scenario']['Total_Cost']);
    this.bppDataSets[1].data.push(this.bi_per_patient['per_year_alternative_scenario']['Total_Cost']);
  }

  ngOnInit() {
  }

}
