import { Component, OnInit } from '@angular/core';
import { ApiService, Calculadora } from '../../servicio/api.service';
import Content from './content.json';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['../../styles/theme.scss']
})
export class ParametrosComponent implements OnInit {

  app: Calculadora;
  metaIndex: any;
  content = Content;

  constructor(public api: ApiService) {
    this.app = api.app;
    this.metaIndex = api.metaIndex;
  }

  ngOnInit() {
  }

  isGoal(index) {
    if (this.metaIndex) {
      return index === this.metaIndex.meta_1 || index === this.metaIndex.meta_2 || index === this.metaIndex.meta_3;
    }
    return false;
  }

}
