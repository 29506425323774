import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tab-content-dru',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.css']
})
export class TabContentComponent implements OnInit {

  comparators: Array<any> = (window as any).getComparators();

  @Input()
  main_object: any;

  subtitles: Array<any> = [
    {label: 'Daily basal dose (IU)', selector: 'daily_basal'},
    {label: 'Daily bolus dose (IU)', selector: 'daily_bolus'},
    {label: 'Basal injection frequency (per week)', selector: 'basal_injection'},
    {label: 'Bolus injection frequency (per week)', selector: 'bolus_injection'},
    {label: 'SMBG frequency (per week)', selector: 'smbg_frequency'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
