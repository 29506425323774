import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debie-setup',
  templateUrl: './debie-setup.component.html',
  styleUrls: ['./debie-setup.component.css']
})
export class DebieSetupComponent implements OnInit {

  map: Array<string> = [
    'total_population_size',
    'time_horizon',
    'currency',
    'annual_discount_rate',
    'starting_year',

    'primary_comparator',
    'comparator_one',
    'comparator_two',
    'comparator_three',
    'comparator_four',
    'comparator_five'
  ];

  model: any = {};

  constructor() {
    for (let key of this.map) {
      this.model[key] = window[key];
    }
  }

  ngOnInit() {
  }

  updateValue(key, value) {
    console.log(key, value);

    this.model[key] = value;
    window[key] = value;
  }

}
