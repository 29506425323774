import {
  getMeta,
  ICostoTratamiento,
  IFormulaciones,
  IMeta,
  ISuministros,
  runCosts,
  XulthophyCosts,
  XulthophyParams,
  XulthophyScenario,
  XulthophySuministros
} from './models';

export interface ParamsScenario3 extends XulthophyParams {
  dosisMetmorfina: IFormulaciones;
  dosisInsulinaInicial: IFormulaciones;
  metaHb1ac7: IFormulaciones;
  metaHb1ac7SinHipoglucemiaSevera: IFormulaciones;
  metaHb1sc7SinHipoglucemiasSinGananciaPeso: IFormulaciones;
  dosisInsulinaFinalPromedio: IFormulaciones;
}

export class Scenario3 extends XulthophyScenario {
  params: ParamsScenario3;
  costoTratamiento: ICostoTratamiento;
  suministros: XulthophySuministros;

  constructor(params: ParamsScenario3, title: string, costs: XulthophyCosts, personas: number, suministros: XulthophySuministros) {
    super(title, costs, personas);
    this.params = params;
    this.costoTratamiento = {
      costoTratamientoAnual: {
        iDegLira: 0,
        iGlarLixi: 0
      },
      deltaCostoTratamientoAnual: 0
    };
    this.suministros = suministros;
  }

  getCosts(): XulthophyCosts {
    this.costs.dosisDiaria = {
      iDegLira: this.params.dosisInsulinaFinalPromedio.iDegLira,
      lantus: this.params.dosisInsulinaFinalPromedio.iglarAspart.basal,
      aspart: this.params.dosisInsulinaFinalPromedio.iglarAspart.bolo
    };
    this.costs = runCosts(this.costs);
    return this.costs;
  }

  getSuministrosCosts(): XulthophySuministros {
    const costoAnualLocal = {
      tirillas: {
        iDegLira: this.suministros.uso.tirillas.iDegLira * this.suministros.adicionales.tirillas.esquemaAjustado,
        esquemaConvencional: this.suministros.uso.tirillas.esquemaConvencional * this.suministros.adicionales.tirillas.esquemaAjustado,
        esquemaAjustado: this.suministros.uso.tirillas.esquemaAjustado * this.suministros.adicionales.tirillas.esquemaAjustado
      },
      lancetas: {
        iDegLira: this.suministros.uso.lancetas.iDegLira * this.suministros.adicionales.lancetas.esquemaAjustado,
        esquemaConvencional: this.suministros.uso.lancetas.esquemaConvencional * this.suministros.adicionales.lancetas.esquemaAjustado,
        esquemaAjustado: this.suministros.uso.lancetas.esquemaAjustado * this.suministros.adicionales.lancetas.esquemaAjustado
      },
      agujas: {
        iDegLira: this.suministros.uso.agujas.iDegLira * this.suministros.adicionales.agujas.esquemaAjustado,
        esquemaConvencional: this.suministros.uso.agujas.esquemaConvencional * this.suministros.adicionales.agujas.esquemaAjustado,
        esquemaAjustado: this.suministros.uso.agujas.esquemaAjustado * this.suministros.adicionales.agujas.esquemaAjustado
      }
    };

    return {
      uso: this.suministros.uso,
      adicionales: this.suministros.adicionales,
      costoAnual: costoAnualLocal
    };
  }

  getCostoTotalizado(): ISuministros {
    const anual = this.getSuministrosCosts().costoAnual;
    const tratamiento = this.getCosts().gastoAnual;
    const costoTotalLocal = {
      iDegLira: anual.tirillas.iDegLira + anual.lancetas.iDegLira + anual.agujas.iDegLira,
      esquemaConvencional: anual.tirillas.esquemaConvencional + anual.lancetas.esquemaConvencional + anual.agujas.esquemaConvencional,
      esquemaAjustado: anual.tirillas.esquemaAjustado + anual.lancetas.esquemaAjustado + anual.agujas.esquemaAjustado
    };
    return {
      iDegLira: costoTotalLocal.iDegLira + tratamiento.iDegLira,
      esquemaConvencional: costoTotalLocal.esquemaConvencional + tratamiento.lantus + tratamiento.aspart,
      esquemaAjustado: costoTotalLocal.esquemaAjustado + tratamiento.lantus + tratamiento.aspart
    };
  }

  getCostoTratamiento(): ICostoTratamiento {
    this.costoTratamiento.costoTratamientoAnual = {
      iDegLira: this.getCostoTotalizado().iDegLira * this.personas,
      iglarAspart: this.getCostoTotalizado().esquemaAjustado * this.personas
    };
    this.costoTratamiento.deltaCostoTratamientoAnual = this.costoTratamiento.costoTratamientoAnual.iDegLira - this.costoTratamiento.costoTratamientoAnual.iglarAspart;
    return this.costoTratamiento;
  }

  getMetaSimple(): IMeta {
    return getMeta(this.personas, this.params.metaHb1ac7, this.getCostoTratamiento().costoTratamientoAnual, 3, 'Meta simple <7% Hb1ac');
  }

  getMetaDoble(): IMeta {
    return getMeta(this.personas, this.params.metaHb1ac7SinHipoglucemiaSevera, this.getCostoTratamiento().costoTratamientoAnual, 3, 'Meta doble (<7% Hb1Ac + sin hipoglicemia severa o confirmada)');
  }

  getMetaTriple(): IMeta {
    return getMeta(this.personas, this.params.metaHb1sc7SinHipoglucemiasSinGananciaPeso, this.getCostoTratamiento().costoTratamientoAnual, 3, 'Meta triple (<7% Hb1Ac + sin hipoglicemia + sin ganancia de peso)');
  }

}
