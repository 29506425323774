import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iterateObject'
})
export class IterateObjectPipe implements PipeTransform {

  transform(value): any {
    if (!value) {
      return value;
    }

    const keys = [];
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        keys.push({key, value: value[key]});
      }
    }
    return keys;
  }

}
