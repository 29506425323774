import {Component} from '@angular/core';
import {SemaDataService} from '../../services/sema-data.service';
import {IEffectiveness} from '../../models/scenario.class';
import {getYearlyCosts} from '../../global/globalFunctions';
import {RunPsaService} from '../../services/run-psa.service';
import {ChartOptions, ChartType} from 'chart.js';
import * as ChartPluginErrorBars from 'chartjs-plugin-error-bars';

@Component({
    selector: 'app-deterministic',
    templateUrl: 'deterministic.component.html',
  styleUrls: ['../../theme.scss']
})
export class DeterministicComponent {
    effectiveness: IEffectiveness;
    labels = [];

    tableData = [
        {
            title: 'Semaglutida 0.5 mg una vez a la semana',
            data: []
        },
        {
            title: 'Semaglutida 1 mg una vez a la semana',
            data: []
        },
        {
            title: 'Dulaglutida 1.5mg',
            data: []
        },
    ];

    public chartDsData: Array<Array<number>> = [
        [], [], []
    ];
    public barChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            borderWidth: 1,
            titleFontColor: '#000000',
            bodyFontColor: '#000000'
        },
        // We use these empty structures as placeholders for dynamic theming.
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Costo anual en millones (COP)'
                },
                ticks: {
                    beginAtZero: true,
                    callback: (value: any, index: any, values: any) => {
                        return `$${(value / 1000000).toFixed(0)} mill.`;
                    }
                },
            }],
            xAxes: [{
                barPercentage: 0.8,
            }]
        },
        plugins: {
            chartJsPluginErrorBars: {width: '60%'}
        }
    };
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartData: any[] = [
        {
            label: 'Semaglutida 0.5mg una vez a la semana',
            backgroundColor: 'rgba(57,176,193,0.5)',
            borderColor: '#39B0C1',
            hoverBackgroundColor: '#1790A0',
            pointHoverBackgroundColor: '#FFFFFF',
            borderWidth: 1,
            data: [],
            errorBars: {}
        },
        {
            label: 'Semaglutida 1mg una vez a la semana',
            backgroundColor: 'rgba(193,57,68,0.5)',
            borderColor: '#c13944',
            hoverBackgroundColor: '#a0172c',
            pointHoverBackgroundColor: '#FFFFFF',
            data: [],
            errorBars: {}
        },
        {
            label: 'Dulaglutida 1.5mg',
            backgroundColor: 'rgba(62,193,57,0.5)',
            borderColor: '#3ec139',
            hoverBackgroundColor: '#17a019',
            pointHoverBackgroundColor: '#FFFFFF',
            data: [],
            errorBars: {}
        }
    ];
    public variationChartData: any[] = [
        {
            label: 'Semaglutida 0.5mg una vez a la semana',
            backgroundColor: 'rgba(57,176,193,0.5)',
            borderColor: '#39B0C1',
            hoverBackgroundColor: '#1790A0',
            pointHoverBackgroundColor: '#FFFFFF',
            borderWidth: 1,
            data: [],
            errorBars: {}
        },
        {
            label: 'Semaglutida 1mg una vez a la semana',
            backgroundColor: 'rgba(193,57,68,0.5)',
            borderColor: '#c13944',
            hoverBackgroundColor: '#a0172c',
            pointHoverBackgroundColor: '#FFFFFF',
            data: [],
            errorBars: {}
        },
        {
            label: 'Dulaglutida 1.5mg',
            backgroundColor: 'rgba(62,193,57,0.5)',
            borderColor: '#3ec139',
            hoverBackgroundColor: '#17a019',
            pointHoverBackgroundColor: '#FFFFFF',
            data: [],
            errorBars: {}
        }
    ];
    public plugins = [ChartPluginErrorBars];

    variation: number;
    proportionValue = '1 SE';
    tabActive = 0;

    constructor(private data: SemaDataService, private psaService: RunPsaService) {
        this.effectiveness = data.inputs.effectiveness;
        this.variation = data.params.costMultiplier;

        for (let i = 0; i < this.chartDsData.length; i++) {
            this.barChartData[i].data = this.chartDsData[i];
            this.variationChartData[i].data = this.chartDsData[i];
        }

        if (!this.psaService.calc) {
            this.psaService.runPSA();
        }

        const owsaMultipliers = this.data.getOWSAMultipliers();
        if (this.data.params.aproxProportionVariable === 'percentage of baseline value') {
            this.proportionValue = `${this.data.params.percentageBaselineValue * 100} %`;
        }

        const effectivenessMap = this.data.getEffectivenessMap();
        const yearlyCosts = getYearlyCosts(data.inputs, data.params);

        for (const item of effectivenessMap) {
            this.labels.push(item.label);

            const anualSem05 = yearlyCosts.totals[0] / item.data.semaglutida_0_5;
            const anualSem1 = yearlyCosts.totals[1] / item.data.semaglutida_1;
            const anualDul15 = yearlyCosts.totals[2] / item.data.dulaglutida_1_5;

            const sem05var = anualSem05 * this.variation;
            const sem1var = anualSem1 * this.variation;
            const dul15var = anualDul15 * this.variation;

            this.chartDsData[0].push(anualSem05);
            this.chartDsData[1].push(anualSem1);
            this.chartDsData[2].push(anualDul15);

            const sem05less45 = anualSem05 - sem05var;
            const sem1less45 = anualSem1 - sem1var;
            const dul15less45 = anualDul15 - dul15var;

            const sem05plus45 = anualSem05 + sem05var;
            const sem1plus45 = anualSem1 + sem1var;
            const dul15plus45 = anualDul15 + dul15var;

            const sem05lessSe = yearlyCosts.totals[0] / (item.data.semaglutida_0_5 - owsaMultipliers[item.key].semaglutida_0_5);
            const sem1lessSe = yearlyCosts.totals[1] / (item.data.semaglutida_1 - owsaMultipliers[item.key].semaglutida_1);
            const dul15lessSe = yearlyCosts.totals[2] / (item.data.dulaglutida_1_5 - owsaMultipliers[item.key].dulaglutida_1_5);

            const sem05plusSe = yearlyCosts.totals[0] / (item.data.semaglutida_0_5 + owsaMultipliers[item.key].semaglutida_0_5);
            const sem1plusSe = yearlyCosts.totals[1] / (item.data.semaglutida_1 + owsaMultipliers[item.key].semaglutida_1);
            const dul15plusSe = yearlyCosts.totals[2] / (item.data.dulaglutida_1_5 + owsaMultipliers[item.key].dulaglutida_1_5);

            // @ts-ignore
            this.barChartData[0].errorBars[item.label] = {plus: sem05var, minus: sem05var};
            // @ts-ignore
            this.barChartData[1].errorBars[item.label] = {plus: sem1var, minus: sem1var};
            // @ts-ignore
            this.barChartData[2].errorBars[item.label] = {plus: dul15var, minus: dul15var};
            // @ts-ignore
            this.variationChartData[0].errorBars[item.label] = {plus: sem05plusSe - anualSem05, minus: anualSem05 - sem05lessSe};
            // @ts-ignore
            this.variationChartData[1].errorBars[item.label] = {plus: sem1plusSe - anualSem1, minus: anualSem1 - sem1lessSe};
            // @ts-ignore
            this.variationChartData[2].errorBars[item.label] = {plus: dul15plusSe - anualDul15, minus: anualDul15 - dul15lessSe};

            this.tableData[0].data.push([anualSem05, sem05less45, sem05plus45, sem05lessSe, sem05plusSe]);
            this.tableData[1].data.push([anualSem1, sem1less45, sem1plus45, sem1lessSe, sem1plusSe]);
            this.tableData[2].data.push([anualDul15, dul15less45, dul15plus45, dul15lessSe, dul15plusSe]);
        }

        const max = this.getMax();

        this.barChartOptions.scales.yAxes[0].ticks.suggestedMax = max + (max / 2);
    }

    private getMax() {
        const maxValues = [];
        for (const d of this.barChartData) {
            maxValues.push(
                Math.max(...d.data)
            );
        }

        return Math.max(...maxValues);
    }
}
